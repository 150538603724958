import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AboutUsPage } from '../about-us/about-us.page';
import { ContactUsPage } from '../contact-us/contact-us.page';
import { CookiePolicyPage } from '../cookie-policy/cookie-policy.page';
import { FaqPage } from '../faq/faq.page';
import { PrivacyPolicyPage } from '../privacy-policy/privacy-policy.page';
import { ReturnPolicyPage } from '../return-policy/return-policy.page';
import { TermsOfUsePage } from '../terms-of-use/terms-of-use.page';
import { otherMenuComponent } from './other-menu.component';

const routes: Routes = [
    {
        path: '',
        component: otherMenuComponent
    },
    {
        path: 'about-us',
        component: AboutUsPage
    },
    {
        path: 'faq',
        component: FaqPage
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyPage
    },
    {
        path: 'return-policy',
        component: ReturnPolicyPage
    },
    {
        path: 'contact-us',
        component: ContactUsPage
    },
    {
        path: 'cookie-policy',
        component: CookiePolicyPage
    },
    {
        path: 'terms-of-use-mobile',
        component: TermsOfUsePage
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class otherMenuComponentRoutingModule { }
