import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponseBase } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AlertController, Platform, PopoverController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Observable, of, throwError } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../services/authentication.service";
import { CartService } from "../services/cart.service";
import { CommonService } from "../services/common.service";
import { ToastService } from "../services/toast.service";
import { TranslateConfigService } from "../services/translate-config.service";

declare var cordova: any;

const CODEMESSAGE = {
    200: "The server successfully returned the requested data.",
    201: "New or modified data is successful.",
    202: "A request has entered the background queue (asynchronous task).",
    204: "The data was deleted successfully.",
    400: "The request was made with an error and the server did not perform any operations to create or modify data.",
    401: "User does not have permission (token, username, password is incorrect).",
    403: "The user is authorized, but access is forbidden.",
    404: "The request is made for a record that does not exist and the server does not operate.",
    406: "The format of the request is not available.",
    410: "The requested resource is permanently deleted and will not be retrieved.",
    422: "A validation error occurred when creating an object.",
    500: "An error occurred on the server. Please check the server.",
    502: "Gateway error.",
    503: "The service is unavailable and the server is temporarily overloaded or maintained.",
    504: "The gateway timed out.",
};

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    currentRequestUrl: any;
    alertShow = false;
    private unauthorizedCount = 0;
    constructor(
        private authService: AuthenticationService,
        private router: Router,
        private popoverCtrl: PopoverController,
        private commonService: CommonService,
        public cartService: CartService,
        private translateConfigService: TranslateConfigService,
        public platform: Platform,
        public toastService: ToastService,
        public alertController: AlertController,
        public translate: TranslateService
    ) { }

    getToken() {
        return this.commonService.localStorageGet("accessToken");
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        this.currentRequestUrl = req.url;

        const token = this.commonService.localStorageGet("accessToken");
        const fromLogin = this.commonService.localStorageGet("from_login");
        const fromRegister = this.commonService.localStorageGet("from_Register");
        const fromForgotPassword = this.commonService.localStorageGet(
            "from_forgotPassword"
        );
        const notificationToken =
            this.commonService.localStorageGet("notificationToken");
        let language = this.commonService.localStorageGet("locale");

        const headers = {};
        // headers['Cache-Control'] = 'no-cache';
        headers["Access-Control-Allow-Origin"] = "*";
        // headers["Cache-Control"] = "max-age=31536000";
        headers["Cache-Control"] = "no-cache";
        // headers['Content-Encoding'] = 'gzip, compress, br';
        headers["language"] = language ? language : "en";
        headers['appversion'] = environment.appversion;

        if (notificationToken) {
            headers["token"] = notificationToken;
        }
        let ios = this.platform.is("ios");
        let android = this.platform.is("android");
        let desktop =
            !this.platform.is("iphone") &&
            !this.platform.is("ios") &&
            !this.platform.is("android") &&
            !this.platform.is("mobile") &&
            !this.platform.is("mobileweb") &&
            !this.platform.is("capacitor");
        if (ios) {
            headers["platform"] = "iOS";
        }
        if (android) {
            headers["platform"] = "Android";
        }
        if (desktop) {
            headers["platform"] = "Desktop";
        }
        if (!this.currentRequestUrl.includes("/master")) {
            headers["iscustomerrequest"] = "true";
        } else if (this.currentRequestUrl.includes("/master/payment/types")) {
            headers["iscustomerrequest"] = "true";
        } else {
            headers["issupplierrequest"] = "true";
        }

        // code required
        if (
            token &&
            !this.currentRequestUrl.includes("/customer/login") &&
            !this.currentRequestUrl.includes("/customer/registration") &&
            !this.currentRequestUrl.includes("/customer/reset/password") &&
            !this.currentRequestUrl.includes("/customer/generate/otp") &&
            !this.currentRequestUrl.includes("/customer/resend/otp") &&
            !this.currentRequestUrl.includes("/customer/verify/otp") &&
            !this.currentRequestUrl.includes("/master/countries/list") &&
            !this.currentRequestUrl.includes("/master/cities/list") &&
            !this.currentRequestUrl.includes("/master/regions/list")
        ) {
            headers["authorization"] = token;
        } else {
            headers["Authorization"] = "e49802a8-efcd-49ba-8460-882353e18703";
        }
        // Use the token in the request
        req = req.clone({
            setHeaders: headers,
        });
        // Handle the request
        return next.handle(req).pipe(
            mergeMap((event: any) => {
                if (event instanceof HttpResponseBase) return this.handleData(event);
                return of(event);
            }),
            catchError((err: HttpErrorResponse) => this.handleData(err))
        );
    }

    private checkStatus(ev: HttpResponseBase) {
        if (ev && ((ev.status >= 200 && ev.status < 300) || ev.status === 401)) {
            return;
        }
        if (ev.status == 0) {
            this.commonService.localStorageSet("isNetworkConnected", false);
        }
    }

    public handleData(ev: HttpResponseBase): Observable<any> {
        this.checkStatus(ev);
        switch (ev["status"]) {
            case 0:
                ev["error"]["message"] = "Something went wrong!";
                return throwError(ev["error"]);
            case 200:
                this.commonService.localStorageRemove("forceUpgradePopup");
                if (ev["body"] &&
                    ev["body"]["code"] == 401 &&
                    !ev["url"].includes("/customer/login") &&
                    !ev["url"].includes("/customer/verify/otp") &&
                    !ev["url"].includes("/customer/logout")
                ) {
                    // Increment the unauthorized count
                    this.unauthorizedCount++;

                    // If it's the first 401 error, call logout
                    if (this.unauthorizedCount === 1) {
                        this.logout();
                        return throwError(ev["error"]);
                    }
                    
                }
                if (ev["body"] && ev["body"]["code"] == 403) {
                    this.showForceUpdateDialog(ev["body"]["message"]);
                }
                break;
            case 400:
                return throwError(ev["error"]);
                break;
            case 401:
                return throwError(ev["error"]);
                break;
            case 403:
                return throwError(ev["error"]);
                break;
            case 404:
                return throwError(ev["error"]);
                break;
            case 409:
                return throwError(ev["error"]);
                break;
            case 410:
                return throwError(ev["error"]);
                break;
            case 500:
                return throwError(ev["error"]);
                break;
            default:
                if (ev instanceof HttpErrorResponse) {
                    console.warn(
                        `I don't know the error, most of it is caused by the backend not supporting CORS or invalid configuration.`,
                        ev["error"]
                    );
                    return throwError(ev["error"]);
                }
                break;
        }
        return of(ev);
    }

    logout() {
        this.authService.logout().subscribe(
            (data) => {
                if (data['code'] === 200) {
                    this.commonService.localStorageClear();
                    this.cartService.isloggedIn = false;
                    this.commonService.setEvent("logoutCall", null);
                    this.router.navigate(['/'+this.translate.currentLang+"/home"]);
                }
                else{
                    this.cartService.isloggedIn = false;
                    this.commonService.localStorageClear();
                    this.commonService.setEvent("logoutCall", null);
                    this.router.navigate(['/'+this.translate.currentLang+"/home"]);
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    async showForceUpdateDialog(msgText) {
        if (this.alertShow) {
            return;
        }
        this.alertShow = true;
        const alert = await this.alertController.create({
            header: this.translate.instant('Updateapp'),
            message: msgText,
            backdropDismiss: false,
            buttons: [
                {
                    text: this.translate.instant('Update'),
                    role: "confirm",
                    handler: () => {
                        if (this.platform.is("android")) {
                            window.open("https://play.google.com/store/apps/details?id=com.jumanmarketplace.myapp&hl=en-IN", "_blank");
                        }
                        if (this.platform.is("ios")) {
                            window.open("https://apps.apple.com/in/app/juman-marketplace/id1673534093", "_blank");
                        }
                    },
                },
            ],
        });

        await alert.present();
        const { role } = await alert.onDidDismiss();
    }
}
