import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TabsPage } from "./tabs.page";

const routes: Routes = [
  {
    path: "",
    component: TabsPage,
    children: [
      {
        path: "categories",
        loadChildren: () =>
          import("../tab1/tab1.module").then((m) => m.Tab1PageModule),
      },
      {
        path: "my-wishlist",
        loadChildren: () =>
          import("../tab2/tab2.module").then((m) => m.Tab2PageModule),
      },
      {
        path: "my-account",
        loadChildren: () =>
          import("../components/mobile-menu/mobile-menu.module").then(
            (m) => m.MobileMenuPageModule
          ),
      },

      {
        path: "home",
        data: {
          title: "Home",
          breadcrumb: "Home",
          url: "/home",
        },
        loadChildren: () =>
          import("../pages/home/home.module").then((m) => m.HomePageModule),
      },
      {
        path: "cart",
        data: {
          title: "Cart",
        },
        loadChildren: () =>
          import("../pages/cart-page/cart-page.module").then(
            (m) => m.CartPageModule
          ),
      },
      {
        path: "checkout-step-1",
        data: {
          title: "Checkout",
        },
        loadChildren: () =>
          import("../pages/checkout-step1/checkout-step1.module").then(
            (m) => m.CheckOutStep1PageModule
          ),
      },
      {
        path: "",
        data: {
          title: "Home",
          breadcrumb: "Home",
          url: "/home",
      },
      loadChildren: () =>
          import("../pages/home/home.module").then((m) => m.HomePageModule),
      },
    ],
  },
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class TabsPageRoutingModule {}
