import { DatePipe, isPlatformServer } from "@angular/common";
import { Component, Inject, Input, OnInit, PLATFORM_ID } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { URLS } from "../../_config/api.config";
import { ApiService } from "../../services/api-data.service";
import { CommonService } from "../../services/common.service";
import { ExportExcelService } from "../../services/export-excel.service";
import { ModalService } from "../../services/modal.service";
import { ToastService } from "../../services/toast.service";

@Component({
	selector: "app-invoices",
	templateUrl: "./invoices.component.html",
	styleUrls: ["./invoices.component.scss"],
})
export class InvoicesComponent implements OnInit {
	isLoading = false;
	isDownloading = false;
	searchText: any;
	private subscription: Subscription = new Subscription();
	perPageInterface = { cssClass: "custom-select-dd per-page-dd" };
	currencySymbol: string = "SR";
	showSearch = false;
	enableNext = false;
	filterObj = {};
	showFilter = false;

	public count = 0;

	public itemsPerPage = "10";

	public currentPage = 1;
	selectInterface = { cssClass: "custom-select-dd", size: "cover" };
	selectStatus: any = "all";
	fromDate: any;
	toDate: any;
	dateRange: FormGroup;
	maxFromDate: any;
	maxDate: any;
	minToDate: any;
	todaysDate = new Date();
	columnHeaders = {
		order_id: "Order #",
		date: "Date",
		ship_to: "Ship to",
		order_total: "Order total",
		status: "Status",
	};
	statusList = [];
	orderList = [];
	@Input() showHeader: boolean;
	@Input() from: any;
	@Input() dataArray: any;
	constructor(
		private modalService: ModalService,
		private dataService: ApiService,
		private toastService: ToastService,
		public commonService: CommonService,
		private translateService: TranslateService,
		private fb: FormBuilder,
		private datePipe: DatePipe,
		private router: Router,
		@Inject(PLATFORM_ID) private platformId: Object,
		private exportService: ExportExcelService
	) {
		if (this.router.url.includes("my-invoice")) {
			this.showHeader = true;
		}
	}

	ngOnInit() {
		if (this.router.url.includes("my-invoice")) {
			this.showHeader = true;

			this.statusList = [
				{ value: "all", label: this.translateService.instant("all") },
				{
					value: "CONFIRMED",
					label: this.translateService.instant("confirmed"),
				},
				{
					value: "DISPATCHED",
					label: this.translateService.instant("dispatched"),
				},
				{
					value: "DELIVERED",
					label: this.translateService.instant("delivered"),
				},
			];
			this.initializeDateForm();

			this.getOrderList(this.filterObj);
		}
		if (this.from == "dashboard") {
			this.orderList = this.dataArray;
		}
	}

	ionViewWillEnter() {
		this.initializeDateForm();
		if (this.router.url.includes("my-invoice")) {
			this.showHeader = true;
			this.statusList = [
				{ value: "all", label: this.translateService.instant("all") },
				{
					value: "CONFIRMED",
					label: this.translateService.instant("confirmed"),
				},
				{
					value: "DISPATCHED",
					label: this.translateService.instant("dispatched"),
				},
				{
					value: "DELIVERED",
					label: this.translateService.instant("delivered"),
				},
			];
			this.getOrderList(this.filterObj);
		}
		if (this.from == "dashboard") {
			this.orderList = this.dataArray;
		}
	}

	initializeDateForm() {
		this.dateRange = this.fb.group({
			from: new FormControl(null),
			to: new FormControl(null),
		});
		let date = new Date();
		this.maxFromDate = date.setDate(this.todaysDate.getDate() - 1);
		this.maxDate = this.datePipe.transform(this.todaysDate, "YYYY-MM-dd");
		this.maxFromDate = this.datePipe.transform(this.maxFromDate, "YYYY-MM-dd");
	}

	applyBtn() {
		this.filterObj = {};
		if ((this.fromDate && !this.toDate) || (!this.fromDate && this.toDate)) {
			if (!this.fromDate) {
				let msg = this.commonService.domDirectionLTR
					? "Please select 'From Date'."
					: 'الرجاء تحديد "من التاريخ".';
				this.toastService.displayToast(msg, null, "danger");
			}
			if (!this.toDate) {
				let msg = this.commonService.domDirectionLTR
					? "Please select 'To Date'."
					: 'الرجاء تحديد "حتى الآن".';
				this.toastService.displayToast(msg, null, "danger");
			}
			return;
		}
		if (this.fromDate && this.toDate) {
			this.filterObj["from_date"] = this.datePipe.transform(
				this.fromDate,
				"yyyy-MM-dd"
			);
			this.filterObj["to_date"] = this.datePipe.transform(
				this.toDate,
				"yyyy-MM-dd"
			);
		}
		if (this.filterObj["from_date"] && this.filterObj["to_date"]) {
			if (this.filterObj["from_date"] > this.filterObj["to_date"]) {
				let msg = this.commonService.domDirectionLTR
					? "'From Date' cannot be greater than 'To Date'."
					: 'لا يمكن أن يكون "من تاريخ" أكبر من "إلى تاريخ".';
				this.toastService.displayToast(msg, null, "danger");
				return;
			}
		}
		let isValid = false;
		if (
			isValid &&
			this.filterObj["from_date"] &&
			this.filterObj["to_date"] &&
			this.filterObj["from_date"] == this.filterObj["to_date"]
		) {
			let msg = this.commonService.domDirectionLTR
				? "'From Date' and 'To Date' cannot be same."
				: 'لا يمكن أن يتطابق "من تاريخ" و "إلى تاريخ".';
			this.toastService.displayToast(msg, null, "danger");
			return;
		}
		if (this.selectStatus == "all") {
			delete this.filterObj["status"];
		} else {
			this.filterObj["status"] = this.selectStatus;
		}
		this.currentPage = 1;
		this.getOrderList(this.filterObj);
	}

	clearFilter() {
		this.fromDate = null;
		this.toDate = null;
		this.selectStatus = "all";
		this.dateRange.reset();
		this.filterObj = {};
		if (this.searchText) {
			this.filterObj["search"] = this.searchText;
		}
		this.getOrderList(this.filterObj);
	}

	onDateChange(datetime) {
		datetime.cancel(true);
	}

	search() {
		if (!this.searchText) return;
		this.filterObj["search"] = this.searchText;
		this.currentPage = 1;
		this.getOrderList(this.filterObj);
	}

	openFilters() {
		this.showFilter = !this.showFilter;
	}

	gotoOrderDetails(id, orderNo) {
		this.router.navigate(["/home/account-details/my-invoice/", id], {
			state: {
				breadcrumb:
					this.translateService.instant("orderDetailsComponent.orders") +
					orderNo,
				url: "/home/account-details/my-invoice/" + id,
			},
		});
	}

	onChange(event): void {
		this.currentPage = event;
		this.getOrderList(this.filterObj);
	}

	getOrderList(params, infiniteScroll?, download?) {
		if (!download) {
			params = {
				page: this.currentPage,
				limit: +this.itemsPerPage,
				...params,
			};
		}
		return new Promise((resolve, reject) => {
			if (!download) {
				this.isLoading = true;
			}
			this.subscription.add(
				this.dataService.get(URLS.orderList, params).subscribe(
					(data) => {
						if (data && data["code"] == 200) {
							if (download) {
								let list =
									data["data"] &&
										data["data"]["result"] &&
										data["data"]["result"].length
										? data["data"]["result"]
										: [];
								if (list.length) {
									list.forEach((element, index) => {
										delete element["paid_order_amount"];
									});
									this.exportService.exportAsExcelFile(list, "order_list");
								}
							} else {
								this.enableNext = data["data"]["enable_next"] ? true : false;
								this.count =
									data["data"] && data["data"]["total_count"]
										? +data["data"]["total_count"]
										: 0;
								let list =
									data["data"] &&
										data["data"]["result"] &&
										data["data"]["result"].length
										? data["data"]["result"]
										: [];
								if (!infiniteScroll) {
									this.orderList = [];
								}
								list.forEach((element) => {
									let shipTo =
										element.shipping_address +
										(element.shipping_address_city
											? ", " + element.shipping_address_city
											: "") +
										(element.shipping_address_region
											? ", " + element.shipping_address_region
											: "") +
										(element.shipping_address_country
											? ", " + element.shipping_address_country
											: "") +
										(element.shipping_address_zipcode
											? ", " + element.shipping_address_zipcode
											: "");
									let obj = {
										order_status: element.shipping_status ? element.shipping_status : "CONFIRMED",
										order_total: element.total_order_amount ? element.total_order_amount : 0,
										invoice_url: element.invoice_url ? element.invoice_url : null,
										date: element.order_date ? element.order_date : "",
										payment_status: element.payment_status,
										order_no: element.order_no,
										ship_to: shipTo ? shipTo : "",
										invoice_id: element.invoice_id,
										zipcode: element.zipcode,
										items_count: element.item_count ? element.item_count : "-",
									};
									this.orderList.push(obj);
								});
							}
							resolve(this.orderList);
						} else {
							reject();
						}
						this.isLoading = false;
					},
					(error) => {
						reject();
						this.isLoading = false;
					}
				)
			);
		});
	}

	loadData(event) {
		setTimeout(() => {
			if (this.enableNext) {
				this.currentPage += 1;
				this.getOrderList(this.filterObj, true).then(
					(data) => {
						event.target.complete();
					},
					(err) => {
						event.target.complete();
					}
				);
			}
		}, 500);
	}

	selectPageLength(event) {
		this.itemsPerPage = event.detail.value;
		this.currentPage = 1;
		this.getOrderList(this.filterObj);
	}

	clearSearch() {
		this.searchText = null;
		delete this.filterObj["search"];
		this.currentPage = 1;
		this.getOrderList(this.filterObj);
	}

	downloadExcel() {
		if (this.isDownloading) {
			return;
		}
		const params = {
			dropdown: true,
		};
		this.isDownloading = true;
		this.getOrderList(params, false, true).then(
			(data) => {
				this.isDownloading = false;
			},
			(err) => {
				this.isDownloading = false;
			}
		);
	}

	orderStatus(orderData, productlevel?) {
		switch (orderData.order_status) {
			case "DISPATCHED":
			case "CONFIRMED":
				if (productlevel) {
					return "btn-green product-status-btn";
				} else {
					return "btn-green";
				}
			case "DELIVERED":
				if (productlevel) {
					return "btn-black product-status-btn";
				} else {
					return "btn-black";
				}
			case "NOT_YET_DELIVERED":
			case "NOT_DELIVERED":
			case "CANCELLED":
				if (productlevel) {
					return "btn-red product-status-btn";
				} else {
					return "btn-red";
				}
			default:
				if (productlevel) {
					return "btn-green product-status-btn";
				} else {
					return "btn-green";
				}
		}
	}

	downloadInvoice(event, invoiceUrl) {
		event.preventDefault();
		event.stopPropagation();
		if (!isPlatformServer(this.platformId)) {
			window.open(invoiceUrl);
		}
	}
}
