<div class="home-page app-header">
    <app-header></app-header>
</div>

<ion-content>
    <ion-refresher *ngIf="!getPlatformService.isDesktop" slot="fixed" refreshingSpinner="circular"
        (ionRefresh)="handleRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <div class="home-page banner-products top-slider p-t-0 mt-20"
        *ngIf="!bannerLoading && topBannerData && topBannerData.length > 0">
        <app-banner [TopBannerData]="topBannerData" [topBanner]="true"></app-banner>
    </div>

    <div class="home-page banner-products top-slider p-t-0" *ngIf="bannerLoading">
        <ion-skeleton-text name="lines-sharp-small" [animated]="true" class="width-full skeleton-text-animated"
            [ngClass]="getPlatformService.isDesktop ? 'height-500':'height-300'"></ion-skeleton-text>

        <ion-row class="p-0" [ngClass]="getPlatformService.isDesktop ? 'mt-40':'mt-20'">
            <ion-col size-xs="6" size-sm="6" size-md="6" size-lg="6" size-xl="6">
                <ion-skeleton-text name="lines-sharp-small" [animated]="true" class="width-full skeleton-text-animated"
                    [ngClass]="getPlatformService.isDesktop ? 'height-80':'height-40'"></ion-skeleton-text>

            </ion-col>
            <ion-col size-xs="6" size-sm="6" size-md="6" size-lg="6" size-xl="6">
                <ion-skeleton-text name="lines-sharp-small" [animated]="true" class="width-full skeleton-text-animated"
                    [ngClass]="getPlatformService.isDesktop ? 'height-80':'height-40'"></ion-skeleton-text>

            </ion-col>
        </ion-row>
    </div>

    <ion-grid class="detail-page">
        <ion-row *ngIf="!recommendLoading">
            <ion-col class="app-slider-col">
                <div class="home-page-container ion-text-center recommended-slider">
                    <app-product-slider [sliderData]="recommendedSliderData"
                        [productTitle]="'homePage.recommended_for_you' | translate">
                    </app-product-slider>
                </div>
            </ion-col>
        </ion-row>
        <ion-row class="divider-row"
            *ngIf="!recommendLoading && !getPlatformService.isDesktop && featuredSliderData && featuredSliderData.length">
            <ion-col>
                <div class="mobile-divider">
                </div>
            </ion-col>
        </ion-row>

        <ion-slides *ngIf="recommendLoading" #productsSlides class="p-0 m-0" [options]="slideOpts"
            [ngClass]="getPlatformService.isDesktop ? 'mt-80':'mt-20'">
            <ion-slide *ngFor="let data of [0,1,2,3,4,5]" class="p-0">
                <ion-skeleton-text name="lines-sharp-small" [animated]="true"
                    class="height-200 width-150 skeleton-text-animated"></ion-skeleton-text>
            </ion-slide>
        </ion-slides>


        <ion-row *ngIf="!featuredLoading">
            <ion-col>
                <div class="home-page-container ion-text-center"
                    [ngClass]="getPlatformService.isDesktop ? 'pt-40' : ''">
                    <app-product-slider [sliderData]="featuredSliderData"
                        [productTitle]="'homePage.featured_products' | translate">
                    </app-product-slider>
                </div>
            </ion-col>
        </ion-row>
        <ion-row class="divider-row" *ngIf="!featuredLoading && !getPlatformService.isDesktop">
            <ion-col>
                <div class="mobile-divider">
                </div>
            </ion-col>
        </ion-row>

        <ion-slides *ngIf="featuredLoading" #productsSlides class="p-0 m-0" [options]="slideOpts"
            [ngClass]="getPlatformService.isDesktop ? 'mt-80':'mt-20'">
            <ion-slide *ngFor="let data of [0,1,2,3,4,5]" class="p-0">
                <ion-skeleton-text name="lines-sharp-small" [animated]="true"
                    class="height-200 skeleton-text-animated"></ion-skeleton-text>
            </ion-slide>
        </ion-slides>

        <ion-row *ngIf="!getPlatformService.isDesktop && !categoryLoading">
            <ion-col class="app-slider-col">
                <div class="home-page-container ion-text-center recommended-slider p-5">
                    <app-product-slider [categories]="true" [sliderData]="categoryData"
                        [productTitle]="'homePage.explore_group' | translate">
                    </app-product-slider>
                </div>
            </ion-col>
        </ion-row>


        <ion-slides *ngIf="!getPlatformService.isDesktop && categoryLoading" #productsSlides class="p-0 m-0"
            [options]="slideOpts" [ngClass]="getPlatformService.isDesktop ? 'mt-80':'mt-20'">
            <ion-slide *ngFor="let data of [0,1,2,3,4,5]" class="p-0">
                <ion-skeleton-text name="lines-sharp-small" [animated]="true"
                    class="height-200 skeleton-text-animated"></ion-skeleton-text>
            </ion-slide>
        </ion-slides>


        <ion-row *ngIf="!isLoading" class="pt-30" class="explore-catalogue-row">
            <ion-col class="explore-catalogue-col">
                <div class="home-page-container explore-catalogue"
                    [ngClass]="getPlatformService.isDesktop ? 'pt-60' : ''">
                    <app-explore-catelogue></app-explore-catelogue>
                </div>
            </ion-col>
        </ion-row>

        <ion-slides *ngIf="isLoading" #productsSlides class="p-0 m-0" [options]="slideOptsCat"
            [ngClass]="getPlatformService.isDesktop ? 'mt-80':'mt-20'">
            <ion-slide *ngFor="let data of [0,1,2,3,4,5]" class="p-0">
                <ion-skeleton-text name="lines-sharp-small" [animated]="true"
                    class="height-200 skeleton-text-animated"></ion-skeleton-text>
            </ion-slide>
        </ion-slides>

        <ion-row *ngIf="!bannerLoading">
            <ion-col class="ph-10 multi-banner-col" *ngIf="bottomBannerData && bottomBannerData.length">
                <div class="banner-products">
                    <app-banner [bottomBannerData]="bottomBannerData" [multiBanner]="true"></app-banner>
                </div>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="bannerLoading" [ngClass]="getPlatformService.isDesktop ? 'mt-80':'mt-20'">
            <ion-skeleton-text name="lines-sharp-small" [animated]="true" class="width-full skeleton-text-animated"
                [ngClass]="getPlatformService.isDesktop ? 'height-400' : 'height-200'"></ion-skeleton-text>
        </ion-row>

        <ion-row *ngIf="!bestSellLoading">
            <ion-col [ngClass]="[ bottomBannerData.length == 0 ? 'pt-25' : '']">
                <div class="ion-text-center" [ngClass]="[ getPlatformService.isDesktop ? 'mb-80' : 'mb-20']">
                    <app-product-slider [productTitle]="'homePage.bestselling_products' | translate"
                        [sliderData]="bestsellingSliderData">
                    </app-product-slider>
                </div>
            </ion-col>
        </ion-row>

        <ion-slides *ngIf="bestSellLoading" #productsSlides class="p-0 m-0" [options]="slideOpts"
            [ngClass]="getPlatformService.isDesktop ? 'mt-80':'mt-20'">
            <ion-slide *ngFor="let data of [0,1,2,3,4,5]" class="p-0">
                <ion-skeleton-text name="lines-sharp-small" [animated]="true"
                    class="height-200 skeleton-text-animated"></ion-skeleton-text>
            </ion-slide>
        </ion-slides>
        <ion-row *ngIf="!cartService.isloggedIn && !isLoading">
            <ion-col class="ph-10" class="register-now-row">
                <div class="ion-text-center">
                    <app-register-now></app-register-now>
                </div>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="isLoading " [ngClass]="getPlatformService.isDesktop ? 'mt-80':'mt-20'">
            <ion-skeleton-text name="lines-sharp-small" [animated]="true" class="width-full skeleton-text-animated"
                [ngClass]="getPlatformService.isDesktop ? 'height-400' : 'height-200'"></ion-skeleton-text>
        </ion-row>

    </ion-grid>
    <div class="wrapper-block home-page">
        <div class="app-footer container">
            <app-footer></app-footer>
        </div>
    </div>


</ion-content>