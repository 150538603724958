import { Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import { isSupported } from "firebase/analytics";
import { Subscription } from "rxjs";
import { CustomFirebaseAnalyticsProvider } from "src/app/services/CustomFirebaseAnalyticsProvider";
import { URLS } from "../../_config/api.config";
import { LoginPage } from "../../pages/login/login.page";
import { ApiService } from "../../services/api-data.service";
import { CartService } from '../../services/cart.service';
import { CommonService } from "../../services/common.service";
import { GetPlatformService } from "../../services/get-platform.service";
import { ModalService } from "../../services/modal.service";
import { ToastService } from "../../services/toast.service";
import { TranslateConfigService } from "../../services/translate-config.service";
import { WishlistService } from "../../services/wishlist.service";

@Component({
	selector: "app-home",
	templateUrl: "./home.page.html",
	styleUrls: ["./home.page.scss"],
})
export class HomePage implements OnInit, OnDestroy {
	private subscription: Subscription = new Subscription();
	public bannerData = [];
	public bottomBannerData = [];
	public topBannerData = [];
	public featuredSliderData = [];
	public isLoading = true;
	public categoryData = [];
	public wishlistData: any;

	public recommendedSliderData = [];

	public bestsellingSliderData = [];

	public slideOpts = {
		initialSlide: 0,
		speed: 200,
		spaceBetween: 20,
		breakpoints: {
			320: {
				slidesPerView: 1.75,

			},
			360: {
				slidesPerView: 2.2,
			},
			375: {
				slidesPerView: 2.2,
			},
			385: {
				slidesPerView: 2.2,
			},
			500: {
				slidesPerView: 2,
			},
			488: {
				slidesPerView: 2.5,
			},
			700: {
				slidesPerView: 2.5,
			},
			768: {
				slidesPerView: 2,
			},
			800: {
				slidesPerView: 2.3,
			},
			1000: {
				slidesPerView: 3.3,
			},
			1100: {
				slidesPerView: 3.5,
			},
			1280: {
				slidesPerView: 4,
			},
			1400: {
				slidesPerView: 4.5,
			},
			1480: {
				slidesPerView: 5,
			},
			1600: {
				slidesPerView: 5,
			},
			1800: {
				slidesPerView: 5,
			},
			1920: {
				slidesPerView: 5,
			},
		},
	};
	public slideOptsCat = {
		initialSlide: 0,
		speed: 200,
		spaceBetween: 20,
		breakpoints: {
			320: {
				slidesPerView: 2.5,

			},
			360: {
				slidesPerView: 2.7,
			},
			375: {
				slidesPerView: 2.7,
			},
			385: {
				slidesPerView: 2.7,
			},
			500: {
				slidesPerView: 3,
			},
			488: {
				slidesPerView: 3,
			},
			700: {
				slidesPerView: 3,
			},
			768: {
				slidesPerView: 3,
			},
			800: {
				slidesPerView: 3.7,
			},
			1000: {
				slidesPerView: 6,
			},
			1100: {
				slidesPerView: 6,
			},
			1280: {
				slidesPerView: 6,
			},
			1400: {
				slidesPerView: 5.5,
			},
			1480: {
				slidesPerView: 6,
			},
			1600: {
				slidesPerView: 6,
			},
			1800: {
				slidesPerView: 6,
			},
			1920: {
				slidesPerView: 6,
			},
		},
	};

	bannerLoading: boolean = true;
	recommendLoading: boolean = true;
	featuredLoading: boolean = true;
	categoryLoading: boolean = true;
	bestSellLoading: boolean = true;

	constructor(
		private modalService: ModalService,
		private translateConfigService: TranslateConfigService,
		public getPlatformService: GetPlatformService,
		private dataService: ApiService,
		private toastService: ToastService,
		private commonService: CommonService,
		public customFirebaseAnalytics: CustomFirebaseAnalyticsProvider,
		public wishlistService: WishlistService,
		public zone: NgZone,
		public cartService: CartService,
	) {
	}

	ionViewWillEnter() {
		this.commonService.scrollToTop();
		this.translateConfigService.getDefaultLanguage();
		this.loadAllData();
	}


	ngOnInit() {
		this.commonService.scrollToTop();
		if (!this.getPlatformService.isDesktop) {
			if (localStorage.getItem('appStartTime')) {
				this.callApiForcefully();
			}
		}
		isSupported().then(() => {
			this.customFirebaseAnalytics.trackEvent("Homepage", "/home"); //Google Analytics Log In Event
		})
		this.translateConfigService.getDefaultLanguage();
	}

	callApiForcefully() {    //Calling api after 24hrs forcefully to handle no record found issue
		let now = +new Date();
		let appInitalised = localStorage.getItem('appStartTime');

		// This returns app initialise date
		let initialisedAt = +appInitalised;
		// const hour= 1000 * 60 * 60;   //check for an hour
		const halfDay = 1000 * 60 * 60 * 12;   //check for half day
		// const oneDay = 1000 * 60 * 60 * 24;   //check for a day

		var compareDatesBoolean = (now - initialisedAt) > halfDay;

		if (compareDatesBoolean) {
			this.loadAllData();
			localStorage.removeItem('appStartTime');
		}
	}

	ngAfterViewInit() {
		this.translateConfigService.getDefaultLanguage();
	}

	handleRefresh(event) {
		setTimeout(() => {
			this.loadAllData(true);
			this.commonService.setEvent("handleRefresh", null);
			// code maybe required
			// this.isLoading = false;
			event.target.complete();
		}, 1000);
	}

	getExploreCatalogue() {
		return new Promise((resolve, reject) => {
			let params = {
				page: 1,
				size: 10,
			};
			this.categoryLoading = true;

			this.subscription.add(
				this.dataService.get(URLS.allGroupList, params).subscribe(
					(data) => {
						if (data["code"] == 200) {
							this.categoryData = [];
							let sliderData = data["data"]["result"];
							if (sliderData && sliderData.length) {
								sliderData.forEach((element, index) => {
									let obj = {
										category_name: element.group_name_en
											? element.group_name_en
											: "",
										product_img: element.group_image_url
											? element.group_image_url
											: "",
										product_description: element.category_description_en
											? element.category_description_en
											: null,
										product_descrption_ar: element.category_description_ar
											? element.category_description_ar
											: null,
										product_title: element.group_name_en
											? element.group_name_en
											: null,
										product_title_ar: element.group_name_ar
											? element.group_name_ar
											: null,
										product_name: element.group_name_en
											? element.group_name_en
											: null,
										product_name_ar: element.group_name_ar
											? element.group_name_ar
											: null,
										category_id: element.group_id ? element.group_id : 0,

										// code required
										// items: "167 items"
									};
									this.categoryData.push(obj);
								});
							}
							resolve(data);

						} else {
							reject();
						}
						this.categoryLoading = false;

					},
					(error) => {
						reject();
						this.categoryLoading = false;

					}
				)
			);
		});
	}

	getBannerData() {
		return new Promise<any>((resolve, reject) => {
			this.bannerLoading = true;
			this.subscription.add(
				this.dataService.get(URLS.getBannerData).subscribe(
					(data) => {
						if (data["code"] == 200) {
							this.bottomBannerData = [];
							this.topBannerData = [];
							this.bannerData = data["data"];
							this.zone.run(() => {
								if (this.bannerData && this.bannerData.length) {
									this.bannerData.forEach((element) => {
										if (element.banner_location == "Homepage Top") {
											element["image_url_ar"] = element.image_url_ar
												? "../../../assets/images/slider.svg"
												: "../../../assets/images/slider.svg";
											element["image_url_en"] = element.image_url_en
												? "../../../assets/images/slider.svg"
												: "../../../assets/images/slider.svg";
											element["mobile_image_url_en"] = element.mobile_image_url_en
												? "../../../assets/images/slider.svg"
												: "../../../assets/images/slider.svg";
											element["mobile_image_url_ar"] = element.mobile_image_url_ar
												? "../../../assets/images/slider.svg"
												: "../../../assets/images/slider.svg";
											this.topBannerData.push(element);

										}
										if (element.banner_location == "Homepage Bottom" && !element.is_default_banner) {
											element.image_url_ar = element.image_url_ar
												? element.image_url_ar
												: "../../../assets/icon/mobile-banner-image.svg";
											element.image_url_en = element.image_url_en
												? element.image_url_en
												: "../../../assets/icon/mobile-banner-image.svg";
											element.gray_image_url = "../../../assets/icon/gray_bg.svg";
											element["image_url_ar"] = element.image_url_ar
												? element.image_url_ar.replace(/\ /g, "%20")
												: null;
											element["image_url_en"] = element.image_url_en
												? element.image_url_en.replace(/\ /g, "%20")
												: null;
											element["mobile_image_url_en"] = element.mobile_image_url_en
												? element.image_url_en.replace(/\ /g, "%20")
												: null;
											element["mobile_image_url_ar"] = element.mobile_image_url_ar
												? element.image_url_en.replace(/\ /g, "%20")
												: null;
											this.bottomBannerData.push(element);
										}
										if (!element.banner_location && element.is_default_banner) {
											element["image_url_ar"] = element.image_url_ar
												? "../../../assets/images/slider.svg"
												: "../../../assets/images/slider.svg";
											element["image_url_en"] = element.image_url_en
												? "../../../assets/images/slider.svg"
												: "../../../assets/images/slider.svg";
											element["mobile_image_url_en"] = element.mobile_image_url_en ? "../../../assets/images/slider.svg"
												: "../../../assets/images/slider.svg";
											element["mobile_image_url_ar"] = element.mobile_image_url_ar ? "../../../assets/images/slider.svg"
												: "../../../assets/images/slider.svg";
											this.topBannerData.push(element);
										}
										// let obj = this.topBannerData.find(
										// 	(el) => !el.is_default_banner
										// );
										// if (obj) {
										// 	let default_obj = this.topBannerData.find(
										// 		(el) => el.is_default_banner
										// 	);
										// 	if (default_obj) {
										// 		// this.topBannerData.splice(this.topBannerData.indexOf(default_obj), 1)
										// 	}
										// }
									});
								}
							});
							setTimeout(() => { this.bannerLoading = false; }, 300);
							resolve(data);
						} else {
							this.bannerLoading = false;
							reject();
						}
					},
					(error) => {
						this.bannerLoading = false;
						this.toastService.displayToast(error["message"], null, "danger");
						reject();
					}
				)
			);
		});
	}

	getRecommendedProducts() {
		const params = {
			page: 1,
			size: 10,
		};
		return new Promise((resolve, reject) => {
			this.recommendLoading = true;
			this.subscription.add(
				this.dataService.get(URLS.getRecommendedProducts, params).subscribe(
					(data) => {
						if (data["code"] == 200) {
							this.recommendedSliderData = [];
							let sliderData = data["data"];
							if (sliderData && sliderData.length) {
								sliderData.forEach((element, index) => {
									let obj = {
										product_description: element.product_name_en
											? element.product_name_en
											: "",
										product_description_ar: element.product_name_ar
											? element.product_name_ar
											: "",
										product_name: element.product_name_en
											? element.product_name_en
											: "",
										product_name_ar: element.product_name_ar
											? element.product_name_ar
											: "",
										category_name_en: element.category_name_en
											? element.category_name_en
											: "",
										category_id: element.category_id ? element.category_id : null,
										category_name_ar: element.category_name_ar
											? element.category_name_ar
											: "",
										brand_name: element.brand_name_en
											? element.brand_name_en
											: "",
										brand_name_ar: element.brand_name_ar
											? element.brand_name_ar
											: "",
										product_currency: "",
										product_price: element.price_per_unit
											? element.price_per_unit
											: "",
										price_per_unit: element.price_per_unit
											? element.price_per_unit
											: "",
										final_price: element.final_price ? element.final_price : "",
										product_unit: element.unit_name_en,
										product_unit_arb: element.unit_name_ar,
										product_id: element.product_id ? element.product_id : "",
										discount_value: element.discount_value
											? element.discount_value
											: "",
										discount_type: element.discount_type
											? element.discount_type
											: "",
										wishlist_id: element.wishlist_id
											? element.wishlist_id
											: null,
										sold_quantity: element.sold_quantity
											? element.sold_quantity
											: null,
										is_discount_applied: element.is_discount_applied
											? element.is_discount_applied
											: null,
										is_tax_free_product: element.is_tax_free_product
											? element.is_tax_free_product
											: null,
										available_quantity: element.available_quantity
											? element.available_quantity
											: null,
										is_sold_out: element.is_sold_out
											? element.is_sold_out
											: null,
										is_wishlist_product: element.is_wishlist_product
											? element.is_wishlist_product
											: null,
										product_sku: element.product_sku
											? element.product_sku
											: null,
										total_quantity: element.total_quantity
											? element.total_quantity
											: null,
										product_container_img: "assets/icon/paint-container.svg"
									};
									if (this.getPlatformService.isDesktop) {
										obj["product_img"] = element.web_image_url
											? element.web_image_url
											: "assets/images/no-img1.png";
									} else {
										obj["product_img"] = element.mobile_image_url
											? element.mobile_image_url
											: "assets/images/no-img1.png";
									}
									this.recommendedSliderData["product_type"] = "RECOMMENDED";
									if (index < 10) {
										this.recommendedSliderData.push(obj);
									}
								});

							}
							this.recommendLoading = false;
							resolve(data);
						} else {
							reject();
							this.recommendLoading = false;

						}
					},
					(error) => {
						this.recommendLoading = false;
						reject();
					}
				)
			);
		});
	}

	getFeatureProductsData() {
		const params = {
			page: 1,
			size: 10,
		};
		return new Promise((resolve, reject) => {
			this.featuredLoading = true;
			this.subscription.add(
				this.dataService.get(URLS.getFeatureProductsData, params).subscribe(
					(data) => {
						if (data["code"] == 200) {
							this.featuredSliderData = [];
							let sliderData = data["data"]["result"];
							if (sliderData && sliderData.length) {
								sliderData.forEach((element, index) => {
									let obj = {
										product_description: element.product_name_en
											? element.product_name_en
											: "",
										product_description_ar: element.product_name_ar
											? element.product_name_ar
											: "",
										product_name: element.product_name_en
											? element.product_name_en
											: "",
										product_name_ar: element.product_name_ar
											? element.product_name_ar
											: "",
										category_name_en: element.category_name_en
											? element.category_name_en
											: "",
										category_name_ar: element.category_name_ar
											? element.category_name_ar
											: "",
										category_id: element.category_id ? element.category_id : null,
										brand_name: element.brand_name_en
											? element.brand_name_en
											: "",
										brand_name_ar: element.brand_name_ar
											? element.brand_name_ar
											: "",
										product_currency: "SR",
										product_price: element.price_per_unit
											? element.price_per_unit
											: "",
										price_per_unit: element.price_per_unit
											? element.price_per_unit
											: "",
										final_price: element.final_price ? element.final_price : "",
										product_unit: this.commonService.domDirectionLTR
											? element.unit_name_en
											: element.unit_name_ar,
										product_unit_arb: element.unit_name_ar,
										product_id: element.product_id ? element.product_id : "",
										discount_value: element.discount_value
											? element.discount_value
											: "",
										discount_type: element.discount_type
											? element.discount_type
											: "",
										wishlist_id: element.wishlist_id
											? element.wishlist_id
											: null,
										sold_quantity: element.sold_quantity
											? element.sold_quantity
											: null,
										is_discount_applied: element.is_discount_applied
											? element.is_discount_applied
											: null,
										is_tax_free_product: element.is_tax_free_product
											? element.is_tax_free_product
											: null,
										available_quantity: element.available_quantity
											? element.available_quantity
											: null,
										is_sold_out: element.is_sold_out
											? element.is_sold_out
											: null,
										is_wishlist_product: element.is_wishlist_product
											? element.is_wishlist_product
											: null,
										product_sku: element.product_sku
											? element.product_sku
											: null,
										total_quantity: element.total_quantity
											? element.total_quantity
											: null,
										product_container_img: "assets/icon/paint-container.svg"
									};
									if (this.getPlatformService.isDesktop) {
										obj["product_img"] = element.web_image_url
											? element.web_image_url
											: "assets/images/no-img1.png";
									} else {
										obj["product_img"] = element.mobile_image_url
											? element.mobile_image_url
											: "assets/images/no-img1.png";
									}
									this.featuredSliderData["product_type"] = "FEATURED";
									if (index < 10) {
										this.featuredSliderData.push(obj);
									}
								});
							}
							this.isLoading = false;
							this.featuredLoading = false;
							resolve(data);
						} else {
							this.featuredLoading = false;
							reject();
						}
					},
					(error) => {
						this.featuredLoading = false;
						reject();
					}
				)
			);
		});
	}

	getBestSellingProducts() {
		const params = {
			page: 1,
			size: 10,
		};
		return new Promise((resolve, reject) => {
			this.bestSellLoading = true;
			this.subscription.add(
				this.dataService.get(URLS.getBestSellingProducts, params).subscribe(
					(data) => {
						if (data["code"] == 200) {
							this.bestsellingSliderData = [];
							let sliderData = data["data"]["result"];
							if (sliderData && sliderData.length) {
								sliderData.forEach((element, index) => {
									let obj = {
										product_description: element.product_name_en
											? element.product_name_en
											: "",
										product_description_ar: element.product_name_ar
											? element.product_name_ar
											: "",
										product_name: element.product_name_en
											? element.product_name_en
											: "",
										product_name_ar: element.product_name_ar
											? element.product_name_ar
											: "",
										category_name_en: element.category_name_en
											? element.category_name_en
											: "",
										category_name_ar: element.category_name_ar
											? element.category_name_ar
											: "",
										category_id: element.category_id ? element.category_id : null,
										brand_name: element.brand_name_en
											? element.brand_name_en
											: "",
										brand_name_ar: element.brand_name_ar
											? element.brand_name_ar
											: "",
										product_currency: "SR",
										product_price: element.price_per_unit
											? element.price_per_unit
											: "",
										price_per_unit: element.price_per_unit
											? element.price_per_unit
											: "",
										final_price: element.final_price ? element.final_price : "",
										product_unit: this.commonService.domDirectionLTR
											? element.unit_name_en
											: element.unit_name_ar,
										product_unit_arb: element.unit_name_ar,
										product_id: element.product_id ? element.product_id : "",
										discount_value: element.discount_value
											? element.discount_value
											: "",
										discount_type: element.discount_type
											? element.discount_type
											: "",
										wishlist_id: element.wishlist_id
											? element.wishlist_id
											: null,
										sold_quantity: element.sold_quantity
											? element.sold_quantity
											: null,
										is_discount_applied: element.is_discount_applied
											? element.is_discount_applied
											: null,
										is_tax_free_product: element.is_tax_free_product
											? element.is_tax_free_product
											: null,
										available_quantity: element.available_quantity
											? element.available_quantity
											: null,
										is_sold_out: element.is_sold_out
											? element.is_sold_out
											: null,
										is_wishlist_product: element.is_wishlist_product
											? element.is_wishlist_product
											: null,
										product_sku: element.product_sku
											? element.product_sku
											: null,
										total_quantity: element.total_quantity
											? element.total_quantity
											: null,
										product_container_img: "assets/icon/paint-container.svg"
									};
									if (this.getPlatformService.isDesktop) {
										obj["product_img"] = element.web_image_url
											? element.web_image_url
											: "assets/images/no-img1.png";
									} else {
										obj["product_img"] = element.mobile_image_url
											? element.mobile_image_url
											: "assets/images/no-img1.png";
									}
									this.bestsellingSliderData["product_type"] = "BESTSELLING";
									if (index < 10) {
										this.bestsellingSliderData.push(obj);
									}
								});
							}
							resolve(data);
							this.bestSellLoading = false;
						} else {
							reject();
							this.bestSellLoading = false;
						}
					},
					(error) => {
						reject();
						this.bestSellLoading = false;
					}
				)
			);
		});
	}

	openModal() {
		let options: any = {
			id: "loginModal",
			backdropDismiss: false,
		};
		options["component"] = LoginPage;
		this.modalService.openModal(options);
	}

	loadAllData(pullRefresh?) {
		let promiseArray = [
			this.getBannerData(),
			this.getFeatureProductsData(),
			this.getRecommendedProducts(),
			this.getBestSellingProducts(),
		];
		if (!this.getPlatformService.isDesktop) {
			promiseArray.push(this.getExploreCatalogue());
		}
		if (pullRefresh) {
			this.isLoading = true;
		}
		Promise.all(promiseArray).then(
			() => {
				this.isLoading = false;
			},
			(error) => {
				this.isLoading = false;
			}
		);

	}
	ngOnDestroy() {
		this.modalService.dismissModal();
		this.subscription.unsubscribe();
	}
}
