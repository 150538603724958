import { Component, ViewChild } from '@angular/core';
import { Http, HttpDownloadFileResult } from '@capacitor-community/http';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { IonContent, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { URLS } from '../_config/api.config';
import { ReturnOrderMobileFilterComponent } from '../components/return-order-mobile-filter/return-order-mobile-filter.component';
import { GetPlatformService } from '../services/get-platform.service';
import { ModalService } from '../services/modal.service';
import { ApiService } from './../services/api-data.service';
import { CommonService } from './../services/common.service';
import { ToastService } from './../services/toast.service';


@Component({
    selector: 'app-tab3',
    templateUrl: 'tab3.page.html',
    styleUrls: ['tab3.page.scss'],
    providers: []
})
export class Tab3Page {
    @ViewChild(IonContent) content: IonContent;
    private subscription: Subscription = new Subscription();
    enableNext = false;
    showFilter = false;
    public myOrders = [];
    orderParams = {
        page: 1,
        Size: 100
    }
    currentPage = 1;
    itemsPerPage = 10;
    count = 0;
    filterObj = {};
    isLoggedIn: boolean;
    orderList = [];
    statusList = [];
    searchText: any;

    public isLoading = false;
    currentLang; 
    constructor(private dataService: ApiService,
        private modalService: ModalService,
        public commonService: CommonService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private navCtrl: NavController,
        public getPlatformService: GetPlatformService,
        public platform: Platform,
        
    ) {
        this.currentLang = this.translateService.currentLang;
        this.commonService.eventObservable.subscribe(data => {
            if (data.event == 'profileData' || data.event == 'logoutCall') {
                const token = this.commonService.localStorageGet('accessToken');
                if (token) { this.isLoggedIn = true; } else this.isLoggedIn = false;
                if (this.isLoggedIn) {
                    // this.getOrderList();  uncomment when integrating API

                }
            }
        });


    }
    ionViewWillEnter() {
        this.statusList = [
            { value: 'CONFIRMED', label: this.translateService.instant('confirmed') },
            { value: 'DISPATCHED', label: this.translateService.instant('dispatched') },
            { value: 'DELIVERED', label: this.translateService.instant('delivered') },
        ];
        const token = this.commonService.localStorageGet('accessToken');
        if (token) { this.isLoggedIn = true; } else this.isLoggedIn = false;

        if (this.isLoggedIn) {
            this.filterObj = {};
            this.currentPage = 1;
            this.getOrderList(this.filterObj);

        }
    }


    ngOnInit() {
        const token = this.commonService.localStorageGet('accessToken');
        if (token) { this.isLoggedIn = true; } else this.isLoggedIn = false;

        if (this.isLoggedIn) {
            this.filterObj = {};
            this.getOrderList(this.filterObj);
        }
    }

    goToProductDetail(data) {
        let prod_id = data.product_id;
        let prodnameUrl = this.commonService.trimUrl(data.product_name ? data.product_name: data.product_name_en);
        let catnameUrl = this.commonService.trimUrl(data.category_name_en ? data.category_name_en : data.category_name);
        this.navCtrl.navigateForward('/'+this.currentLang +"/"+catnameUrl+"/"+ prodnameUrl+"/"+ prod_id, { animated: false, "state": { "breadcrumb": data.product_name_en, "url": "/"+catnameUrl+"/"+ prodnameUrl+"/"+ prod_id } });
    }


    getOrderList(params, infiniteScroll?) {
        if (this.isLoading) {
            return
        }
        params = {
            page: this.currentPage,
            limit: +this.itemsPerPage,
            ...params
        }
        return new Promise((resolve, reject) => {
            if (!infiniteScroll) {
                this.isLoading = true;
            }
            this.subscription.add(
                this.dataService.get(URLS.orderList, params).subscribe(data => {
                    if (data && data['code'] == 200) {
                        this.enableNext = data['data']['enable_next'] ? true : false;
                        this.count = data['data'] && data['data']['total_count'] ? +data['data']['total_count'] : 0;
                        let list = data['data'] && data['data']['result'] && data['data']['result'].length ? data['data']['result'] : [];
                        if (!infiniteScroll) {
                            this.orderList = [];
                        }
                        list.forEach(element => {
                            let shipTo = element.shipping_address + (element.shipping_address_city ? (', ' + element.shipping_address_city) : '') + (element.shipping_address_region ? (', ' + element.shipping_address_region) : '') + (element.shipping_address_country ? (', ' + element.shipping_address_country) : '') + (element.shipping_address_zipcode ? (', ' + element.shipping_address_zipcode) : '');
                            let obj = {
                                order_status: element.shipping_status ? element.shipping_status : 'PENDING',
                                order_total: element.total_order_amount ? element.total_order_amount : 0,
                                date: element.order_date ? element.order_date : '',
                                order_no: element.order_no,
                                ship_to: shipTo ? shipTo : '',
                                invoice_id: element.invoice_id,
                                zipcode: element.zipcode,
                                invoice_url: element.invoice_url ? element.invoice_url : '',
                                items_count: element.item_count ? element.item_count : '-',
                            }
                            this.orderList.push(obj);
                        });
                        resolve(this.orderList)
                    } else {
                        reject();
                    }
                    this.isLoading = false;
                }, error => {
                    reject();
                    this.isLoading = false;
                })
            )
        })
    }

    loadData(event) {
        setTimeout(() => {
            if (this.enableNext) {
                this.currentPage += 1;
                this.getOrderList(this.filterObj, true).then((data) => {
                    event.target.complete();
                }, err => {
                    event.target.complete();
                })
            }
        }, 500);
    }

    openFilterByModal() {
        let modal: any;
        let options: any = {
            id: "orderFilterByModal",
            componentProps: { statusList: this.statusList, fromOrdersList: true, filterObj: this.filterObj },
        };
        options['component'] = ReturnOrderMobileFilterComponent;
        modal = this.modalService.openModal(options)
        modal.then((data) => {
            if (data && data['data']) {
                this.filterObj = {
                    ...data['data']
                };
                if (this.searchText) {
                    this.filterObj['search'] = this.searchText;
                }
                this.currentPage = 1;
                this.getOrderList(this.filterObj)
            }

        })
    }

    openOrderDetailsModal(id) {
        this.navCtrl.navigateForward(['/'+this.currentLang +'/home/order-details-mobile'], {
            queryParams: {
                invoiceId: id
            }
        })
    }

    clearSearch(event) {
        delete this.filterObj['search']
        this.currentPage = 1;
        this.content.scrollToTop(500);
        this.getOrderList(this.filterObj)

    }

    handleRefresh(event) {
        setTimeout(() => {
            // code maybe required
            // this.isLoading = false;
            if (this.isLoggedIn) {
                if (this.filterObj['search']) {
                    this.getOrderList(this.filterObj);
                } else {
                    this.filterObj = {};
                    this.currentPage = 1;
                    this.getOrderList(this.filterObj);
                }

            }
            event.target.complete();
        }, 1000);
    };

    search(event) {
        if (event['data']) {
            this.filterObj['search'] = event['data'];
            this.currentPage = 1;
            this.content.scrollToTop(500);
            this.getOrderList(this.filterObj)
        } else {
            this.filterObj['search'] = null
        }

    }

    goBack() {
        this.navCtrl.navigateRoot('/'+this.currentLang +'/home', { animated: false });
    }

    orderStatus(orderData, productlevel?) {
        switch (orderData.order_status) {
            case 'NOT_YET_DELIVERED':
            case 'DISPATCHED':
            case 'CONFIRMED':
                if (productlevel) {
                    return 'btn-green product-status-btn';
                } else {
                    return 'btn-green';
                }
            case 'DELIVERED':
                if (productlevel) {
                    return 'btn-black product-status-btn';
                } else {
                    return 'btn-black';
                }
            case 'CANCELLED':
                if (productlevel) {
                    return 'btn-red product-status-btn';
                } else {
                    return 'btn-red';
                }
            default:
                if (productlevel) {
                    return 'btn-green product-status-btn';
                } else {
                    return 'btn-green';
                }
        }
    }

    async downloadInvoice(event, invoiceUrl) {
        event.preventDefault();
        event.stopPropagation();
        let invoiceFileName = invoiceUrl.substring(invoiceUrl.lastIndexOf('/') + 1)

        const options = {
            url: invoiceUrl,
            filePath: invoiceFileName,
            fileDirectory: this.platform.is("android") ? Directory.Documents : Directory.Data,
            progress: true,
            // Optional
            method: 'GET',
        };

        // Writes to local filesystem
        const response: HttpDownloadFileResult = await Http.downloadFile(options);
        // Then read the file
        if (response.path) {
            await Filesystem.readFile({
                path: invoiceFileName,
                directory: Directory.Documents,
            }).then(data => {
                const msg = this.commonService.domDirectionLTR ? 'File download successfully, Please check your directory.'
                    : 'تم تنزيل الملف بنجاح ، يرجى التحقق من الدليل الخاص بك.'
                this.toastService.displayToast(msg, null, 'success')
            },
                (error) => {
                    this.toastService.displayToast(error, null, 'error')
                });


        }
    }

}
