import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-sales-information',
	templateUrl: './sales-information.component.html',
	styleUrls: ['./sales-information.component.scss'],
})
export class SalesInformationComponent implements OnInit {

	mobileView = false;

	constructor() { }

	ngOnInit() { }

}
