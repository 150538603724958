<ion-header class="product-review-header" *ngIf="!getPlatformService.isDesktop" mode="md">
	<ion-toolbar mode="md">
		<img src="assets/icon/leftArrow.svg" class="back-icon back-img" alt="Jazeera" slot="start" (click)="goBack()">

		<ion-title mode="ios" class="color-app-black ph-7">
			<div class="cart-header">
				{{'cartPage.Shoppingcart' | translate}}
			</div>
		</ion-title>

		<img *ngIf="cartData && cartData.result && cartData.result.length" src="assets/icon/clear-cart.svg"
			class="back-img" alt="Jazeera" slot="end" (click)="clearCart()">
	</ion-toolbar>
</ion-header>

<app-cart-header [countIndex]="1"></app-cart-header>

<app-loader *ngIf="isLoading"></app-loader>


<ion-content *ngIf="getPlatformService.isDesktop">
    <ion-row [ngClass]="getPlatformService.isDesktop ? 'p-35-190':'p-20'">
        <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="9" size-xl="9" class="pr-30">
            <div class="header-div">
                <p class="title-1 color-app-black">{{'cartPage.Shoppingcart' | translate}}</p>
                <p class="title-2 color-primary pointer clearText" (click)="clearCart()">{{'cartModal.clearCart' |
                    translate}}</p>
            </div>


            <div class="scrollable-container">
                <ion-row class="row-header">
                    <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4" size-xl="4">
                        <p class="row-header-1 color-app-grey">{{'cartPage.Item'|translate}}</p>
                    </ion-col>
                    <ion-col size-xs="2" size-sm="2" size-md="2" size-lg="2" size-xl="2">
                        <p class="row-header-1 color-app-grey">{{'cartPage.Price'|translate}}</p>
                    </ion-col>
					<ion-col size-xs="2" size-sm="2" size-md="2" size-lg="2" size-xl="2">
						<p class="row-header-1 color-app-grey pl-20">{{'Discounted Price'}}</p>
					</ion-col>
                    <ion-col size-xs="2.5" size-sm="2" size-md="2" size-lg="2" size-xl="2">
                        <p class="row-header-1 color-app-grey">{{'cartPage.Quantity'|translate}}</p>
                    </ion-col>
					<ion-col size-xs="2" size-sm="2" size-md="2" size-lg="2" size-xl="2">
						<p class="row-header-1 color-app-grey pl-20">{{'cartPage.distributionCenter' | translate}}</p>
					</ion-col>
					<ion-col size-xs="1.7" size-sm="1.7" size-md="1.7" size-lg="1.7" size-xl="1.7">
						<p class="row-header-1 color-app-grey pl-20">{{'cartPage.availableStock'| translate}}</p>
					</ion-col>
					<ion-col size-xs="1.5" size-sm="1.5" size-md="1.5" size-lg="1.5" size-xl="1.5">
						<p class="row-header-1 color-app-grey">{{'cartPage.vat' | translate }}(15%)</p>
					</ion-col>
					<ion-col size-xs="2" size-sm="2" size-md="2" size-lg="2" size-xl="2">
						<p class="row-header-1 color-app-grey pl-20">{{'cartPage.shippingCharges' | translate }}</p>
					</ion-col>
                    <ion-col size-xs="1.3" size-sm="1.3" size-md="1.3" size-lg="1.3" size-xl="1.3">
                        <p class="row-header-1 color-app-grey">{{'cartPage.netAmount' | translate }}</p>
                    </ion-col>
                </ion-row>

                <div *ngIf="cartData && cartData.result && cartData.result.length">
                    <div *ngFor="let data of cartData.result;let i =index">
                        <ion-row class="head-div">
                            <p class="title-2 color-app-black">{{commonService.domDirectionLTR ?
                                data.supplier_legal_name_en : data.supplier_legal_name_ar}}</p>
                        </ion-row>
                        <ion-row *ngFor="let item of data.products;let j =index" class="mt-10 scrollable-row">
                            <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="4" size-xl="4" class="pl-0">
                                <div class="flex-row">

                                    <div class="image-container"
                                        [ngClass]=" item.is_sold_out ? 'product-image-blur':null">

                                        <div *ngIf="item.discount_type &&  item.discount_type != 'PRICE' && !item.is_sold_out && !item.is_sample_product"
                                            class="product-discount"
                                            [ngClass]="commonService.domDirectionLTR ? '':'product-discount-rtl'">
                                            <span>-</span>{{ item.discount_value }}<span>%</span>
                                        </div>
                                        <div *ngIf="item.discount_type &&  item.discount_type == 'PRICE' && !item.is_sold_out && !item.is_sample_product"
                                            class="product-discount"
                                            [ngClass]="commonService.domDirectionLTR ? '':'product-discount-rtl'">
                                            <span>{{"OnSale" | translate}}</span>
                                        </div>

                                        <img *ngIf="item.product_cover_image" (click)="goToProductDetail(item)"
                                            src="{{item.product_cover_image[0].image_url}}" class="product-img pointer"
                                            alt="Jazeera" />
                                        <img *ngIf="!item.product_cover_image" (click)="goToProductDetail(item)"
                                            src="./assets/images/empty-img.png" class="product-img pointer"
                                            alt="Jazeera" />
                                    </div>
                                    <div *ngIf="item.is_sold_out" class="product-outofstock ml-10">
                                        <div class="outofstock-label">
                                            {{"outOfStock" | translate}}
                                        </div>
                                    </div>


                                    <div class="flex-column ml-20 pr-20">
                                        <p class="label-3 color-app-black pointer" (click)="goToProductDetail(item)">
                                            {{commonService.domDirectionLTR ?
                                            item.product_name_en: item.product_name_ar}}</p>
                                        <div *ngIf="item.is_sample_product == true" class="sample-div">
                                            <p>{{'SAMPLE'|translate}}</p>
                                        </div>

                                        <p class="label-5 color-app-grey "
                                            *ngFor="let config of item.product_configuration">
                                            {{commonService.domDirectionLTR ? config.configuration_name_en:
                                            config.configuration_name_ar}}:
                                            <span class="color-app-black mh-5"> {{commonService.domDirectionLTR ?
                                                config.configuration_value_en: config.configuration_value_ar}}</span>
                                        </p>

                                        <p class="label-5 color-app-grey border-div estimated-delivery"
                                            *ngIf="item.estimated_delivery_date">
                                            {{'cartPage.EstimatedDelivery'|translate}}: <span
                                                class="color-app-grey mh-5 estimated-date">
                                                {{item.estimated_delivery_date}}</span></p>

                                        <p class="label-5 color-app-grey border-div estimated-delivery"
                                            *ngIf="item.delivery_days">
                                            {{'cartPage.EstimatedDelivery'|translate}}: <span
                                                class="color-app-grey mh-5 estimated-date">
                                                {{item.delivery_days}} {{item.delivery_days > 1
                                                ?('cartPage.Days'|translate) : ('cartPage.Day'|translate)}}</span></p>
                                    </div>
                                </div>

                            </ion-col>

                            <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="2" size-xl="2">
                                <p class="label-3 color-app-black">
                                    {{ commonService.currencySymbol}}
                                    {{ item.is_sample_product ? (item.price_per_unit | number) :
                                    (item.final_price_per_unit |number)}}
                                    <span *ngIf="item.unit_name_en && !item.is_sample_product"
                                        class="label-6 color-app-grey">/{{commonService.domDirectionLTR
                                        ?item.unit_name_en:item.unit_name_ar}}</span>


                                </p>

                            </ion-col>
							<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="2" size-xl="2">
								<div class="display-div justify-start pl-20">
									<p class="label-3">
										{{'100'}}</p>
								</div>
							</ion-col>
							<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="2" size-xl="2">
								<div class="qty-div mb-10">
									<div class="div-up-down ml-5">
										<div class="desktop-visibility-in-flex"> <ion-input [min]="data.min_quantity" pattern="[0-9]*"
												(keypress)="isnumeric($event)" (change)="qtychange('enter',item,data)"
												(ionInput)="quantityInput($event)" type="text" inputmode="number" pattern="[0-9]*" type="input"
												autocomplete="new-password" class="registration-pass" clearOnEdit="false"
												[(ngModel)]="item.total_quantity" [disabled]="item.is_sample_product == true">
											</ion-input>
											<div class="div-up-down mr-5" *ngIf="!item.is_sample_product">
												<ion-icon class="feather color-app-grey icon-chevron-up pointer"
													(click)="qtychange('add',item,data)"
													[ngClass]="item.total_quantity >= item.available_quantity ? 'cursor-disable':''"></ion-icon>
												<ion-icon class="feather color-app-grey icon-chevron-down pointer"
													[ngClass]="item.total_quantity <= item.min_quantity? 'cursor-disable':''"
													(click)="qtychange('minus',item,data)"></ion-icon>
											</div>
										</div>
									</div>
									<span class="label-2 color-app-grey text-right">
										{{commonService.domDirectionLTR
										?item.unit_name_en:item.unit_name_ar}}</span>
								</div>
								<div class="qty-div suom" *ngIf="item.unit_id && item.secondary_unit_id && item.unit_id !== item.secondary_unit_id">
									<div class="label-2">
										{{calculateSUOM(item.conversation_ratio,item.total_quantity)}}
										<span class="price-units ml-10 text-right color-app-grey">
											{{commonService.domDirectionLTR ? item.secondary_unit_name_en
											:
											item.secondary_unit_name_ar}} </span>
									</div>
								</div>
								<span *ngIf="item.total_quantity > item.available_quantity" class="error-txt">{{"quantityError" | translate}}
									{{item.available_quantity}}</span>
							</ion-col>
							<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="2" size-xl="2">
								<div class="display-div justify-start pl-20">
									<p class="label-3">
										{{'Mumbai'}}</p>
								</div>
							</ion-col>

                            <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="1.7" size-xl="1.7">
								<div class="display-div justify-start pl-20">
									<p class="label-3">
										{{'25'}}</p>
								</div>
                            </ion-col>
							<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="1.5" size-xl="1.5">
								<p class="label-3 color-app-black">
									{{'21'}}
								</p>
							</ion-col>
							<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="2" size-xl="2">
								<div class="display-div justify-start pl-20">
									<p class="label-3">
										{{'18'}}</p>
								</div>
							</ion-col>
							<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="1.3" size-xl="1.3">
                                <div class="display-div">
                                    <p class="label-4 color-app-black">
                                        {{commonService.currencySymbol}} {{item.total_price |number}}</p>
                                    <div class="text-end">
                                        <div class="more-info more-info-tooltip">
                                            <img src="/assets/icon/trash.svg" class="delete-icon color-app-black"
                                                (click)="deleteProduct(item,data,$event)" alt="Jazeera" />
                                            <span class="more-info-tooltiptext-left more-info-file-tooltip">
                                                {{"delete"|translate }}</span>
                                        </div>

                                    </div>
                                </div>

                            </ion-col>


                            <div class="divider-line mv-10 ml-120"></div>

                        </ion-row>
						<ion-row class="order-summery-div">
							<div class="summery-container">
								<app-order-summary currency="{{commonService.currencySymbol}}"
									orderTotal="{{data.sub_total_price.total_price}}"
									[shippingEliminated]="cartData.is_shipping_cost_eliminated"
									[eliminatedRates]="data.sub_total_price"
									orderSubTotal="{{data.sub_total_price.items_sub_total}}"
									orderDiscount="{{data.sub_total_price.coupon_discounted_value}}" discountCode="{{appliedCouponCode ?
									appliedCouponCode : null}}" orderVAT="{{data.sub_total_price.vat}}"
									orderShipping="{{data.sub_total_price.shipping_charges}}" [orderSummeryData]="data?.sub_total_price"
									orderShippingWithoutVat="{{data.sub_total_price.shipping_charges_without_vat}}"
									shippingVat="{{data.sub_total_price.shipping_vat_charges}}" [fromList]="true"
									[supplierIndex]="(i+1)" [supplierName]="commonService.domDirectionLTR ?
										data.supplier_legal_name_en : data.supplier_legal_name_ar"
									vatPercent="{{data.sub_total_price.vat_percentage}}">
								</app-order-summary>
							</div>
						</ion-row>



                    </div>
                    <div class="add-cart-container" *ngIf="!isQuoteExist">
                        <div class="rfq-btn pb-10 ion-text-start">
                            <ion-button *ngIf="isLoading || btnDisabled">
                                <ion-spinner color="white" class="cart-icon"></ion-spinner>
                                {{'rfqBtntext' | translate}}
                            </ion-button>

                            <ion-button (click)="openRfqConfirmation(cartData.result)"
                                *ngIf="showGetQuote && !btnDisabled">
                                {{'rfqBtntext' | translate}}
                            </ion-button>
                        </div>
                    </div>
                </div>

                <div *ngIf="cartData.result && !cartData.result.length">
                    <div class="no-data">
                        {{"noRecordFound" | translate}}
                    </div>
                </div>
            </div>
            <div class="divider-line mv-10"></div>
            <div class="discount-div width-70" *ngIf="cartData.result && cartData.result.length">

                <p class="apply-discount-lbl mh-15">{{'cartPage.ApplyDiscountCode' | translate}} <span>
                        <i class="feather icon-chevron-up color-app-black ml-10 font-bold"></i>
                    </span></p>

                <div *ngIf="isShowDiscount" class="mb-50">
                    <div class="input-div">
                        <ion-input class="input-border" placeholder="{{ 'cartPage.EnterCode' | translate }}"
                            [(ngModel)]="appliedCouponCodeValue" [disabled]="codeApplied">
                        </ion-input>

                        <app-common-button *ngIf="!appliedCouponCode" color="#FFF" background="var(--ion-color-primary)" type="solid"
                            title="{{'Apply'|translate}}" [isIcon]="false" [isHideInMobile]="false" [isSecondryBtn]="false" [isPrimaryBtn]="true"
                            [specificHeight]="'50px'" [specificWidth]="'150px'" [specificFontSize]="'1rem'"
                            (click)="applyCodeToCart()">
                        </app-common-button>
                        <app-common-button *ngIf="appliedCouponCode" color="#FFF" background="var(--ion-color-primary)" type="solid"
                            title="{{'remove'|translate}}" [isIcon]="false" [isHideInMobile]="false" [isSecondryBtn]="false" [isPrimaryBtn]="true"
                            [specificHeight]="'50px'" [specificWidth]="'150px'" [specificFontSize]="'1rem'"
                            (click)="removeAppliedCode()">
                        </app-common-button>

                    </div>
                    <p class="label-5 color-primary mh-15 cursor-pointer explore-btn" (click)="showOfferDialog()">
                        {{'cartPage.EXPLOREOFFERS'| translate}}</p>
                </div>
            </div>



        </ion-col>


        <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="3" size-xl="3" class="pl-30"
            *ngIf="cartData.result && cartData.result.length">
            <p class="title-3 color-app-black">{{'cartPage.Ordersummary' | translate}}</p>

            <app-order-summary *ngIf="cartData.grand_total" currency="{{commonService.currencySymbol}}"
                orderTotal="{{cartData.grand_total.total_price}}"
                [shippingEliminated]="cartData.is_shipping_cost_eliminated" [eliminatedRates]="cartData.grand_total"
                orderSubTotal="{{cartData.grand_total.items_sub_total}}"
                orderDiscount="{{cartData.grand_total.coupon_discounted_value}}" discountCode="{{appliedCouponCode ?
                appliedCouponCode : null}}" orderVAT="{{cartData.grand_total.vat}}"
                orderShipping="{{cartData.grand_total.shipping_charges}}" [orderSummeryData]="cartData.grand_total"
                orderShippingWithoutVat="{{cartData.grand_total.shipping_charges_without_vat}}"
                shippingVat="{{cartData.grand_total.shipping_vat_charges}}"
                vatPercent="{{cartData.grand_total.vat_percentage}}" isTotalSummary="true">
            </app-order-summary>

            <div class="mv-25">
                <app-common-button color="#FFF" background="var(--ion-color-primary)" type="solid" class="width-100"
                    title="{{'cartPage.Proceedtocheckout'|translate}}" [isIcon]="false" [isHideInMobile]="false"
                    [isFullWidth]="true" (buttonClicked)="proceedCheckout()" [specificHeight]="'50px'"
                    [specificFontSize]="'1rem'" [specificFontWeight]="700" [isSecondryBtn]="false" [isPrimaryBtn]="true"
                    [buttonDisalbed]="cartData.grand_total ? false : true">
                </app-common-button>
            </div>

        </ion-col>

    </ion-row>
</ion-content>

<app-footer [showOnlyLowFooter]="true" *ngIf="getPlatformService.isDesktop"></app-footer>

<ion-content *ngIf="!getPlatformService.isDesktop">
    <ion-refresher *ngIf="!getPlatformService.isDesktop" slot="fixed" refreshingSpinner="circular"
        (ionRefresh)="handleRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-row class="p-0" *ngIf="isLoggedIn">
        <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="9" size-xl="9"
            *ngIf="cartData.result && cartData.result.length" class="p-0">
            <!-- <p class="title-1 color-app-black ph-15">{{'cartPage.Shoppingcart' | translate}}</p> -->

            <div>
                <div *ngIf="cartData && cartData.result && cartData.result.length">
                    <div *ngFor="let data of cartData.result;let i =index">
                        <ion-row class="head-div">
                            <p class="title-2 color-app-black width-70">{{commonService.domDirectionLTR ?
                                data.supplier_legal_name_en : data.supplier_legal_name_ar}}</p>

                            <p class="title-2 color-app-grey">{{data.products.length}}
                                {{data.products.length > 1 ? ('cartModal.products'|translate) :
                                ('cartModal.product'|translate)}}</p>
                        </ion-row>
                        <ion-item-sliding *ngFor="let item of data.products;let j =index" class="p-0">
                            <ion-item lines="none" mode="md" class="p-0">
                                <ion-row class="mt-10">
                                    <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="2" size-xl="2"
                                        class="ph-15">
                                        <div class="mobile-cart-product-card">
                                            <img *ngIf="item.product_cover_image" (click)="goToProductDetail(item)"
                                                src="{{item.product_cover_image[0].image_url}}"
                                                class="product-img pointer" alt="Jazeera" />
                                            <img *ngIf="!item.product_cover_image" (click)="goToProductDetail(item)"
                                                src="./assets/images/empty-img.png" class="product-img pointer"
                                                alt="Jazeera" />
                                            <div *ngIf="item.is_sample_product == true" class="sample-div"
                                                [ngClass]=" commonService.domDirectionLTR ? '':'sample-rtl'">
                                                <p>{{'SAMPLE'|translate}}</p>
                                            </div>
                                            <div *ngIf="item.discount_type &&  item.discount_type != 'PRICE' && !item.is_sold_out && !item.is_sample_product"
                                                [ngClass]=" commonService.domDirectionLTR ? '':'sample-rtl'"
                                                class="discount-mob-div">
                                                <span>-</span>{{ item.discount_value }}<span>%</span>
                                            </div>
                                            <div *ngIf="item.discount_type &&  item.discount_type == 'PRICE' && !item.is_sold_out && !item.is_sample_product"
                                                [ngClass]=" commonService.domDirectionLTR ? '':'sample-rtl'"
                                                class="discount-mob-div">
                                                <span>{{"OnSale" | translate}}</span>
                                            </div>
                                            <div class="pl-15 pr10 width-100">
                                                <p class="label-3 color-app-black pointer"
                                                    (click)="goToProductDetail(item)">
                                                    {{commonService.domDirectionLTR ?
                                                    item.product_name_en: item.product_name_ar}}</p>

                                                <p class="label-5 color-app-grey "
                                                    *ngFor="let config of item.product_configuration">
                                                    {{commonService.domDirectionLTR ? config.configuration_name_en:
                                                    config.configuration_name_ar}}:
                                                    <span class="color-app-black mh-5">
                                                        {{commonService.domDirectionLTR ?
                                                        config.configuration_value_en:
                                                        config.configuration_value_ar}}</span>
                                                </p>


                                                <p class="label-5 color-app-grey border-div estimated-delivery"
                                                    *ngIf="item.estimated_delivery_date">
                                                    {{'cartPage.EstimatedDelivery' | translate}}: <span
                                                        class="color-app-grey mh-5 estimated-date">
                                                        {{item.estimated_delivery_date}}</span></p>

                                                <p class="label-5 color-app-grey border-div estimated-delivery"
                                                    *ngIf="item.delivery_days">
                                                    {{'cartPage.EstimatedDelivery'|translate}}: <span
                                                        class="color-app-grey mh-5 estimated-date">
                                                        {{item.delivery_days}} {{item.delivery_days > 1
                                                        ?('cartPage.Days'|translate) :
                                                        ('cartPage.Day'|translate)}}</span></p>

                                            </div>

                                            <div>
                                                <div><img src="/assets/icon/trash.svg" class="delete-icon-mob"
                                                        (click)="deleteProduct(item,data,$event)" alt="Jazeera"></div>
                                            </div>
                                        </div>
                                    </ion-col>

                                    <ion-row class="row-div ph-15">
                                        <div class="units-container">

                                            <div class="qty-div">
                                                <div class="display-flex">
                                                    <ion-input maxlength="5" (keypress)="isnumeric($event)" type="text"
                                                        inputmode="number" pattern="[0-9]*"
                                                        (ionInput)="quantityInput($event)"
                                                        (change)="qtychange('enter',item,data)"
                                                        autocomplete="new-password" class="registration-pass"
                                                        [disabled]="item.is_sample_product == true" clearOnEdit="false"
                                                        [(ngModel)]="item.total_quantity">
                                                    </ion-input>
                                                    <div class="div-up-down mr-5" *ngIf="!item.is_sample_product">
                                                        <ion-icon class="feather color-app-grey icon-chevron-up pointer"
                                                            [ngClass]="item.total_quantity >= item.available_quantity ? 'cursor-disable':''"
                                                            (click)="qtychange('add',item,data)"></ion-icon>
                                                        <ion-icon
                                                            class="feather color-app-grey icon-chevron-down pointer"
                                                            [ngClass]="item.total_quantity <= item.min_quantity? 'cursor-disable':''"
                                                            (click)="qtychange('minus',item,data)"></ion-icon>
                                                    </div>
                                                </div>
                                                <span class="label-2 color-app-grey text-right">
                                                    {{commonService.domDirectionLTR
                                                    ?item.unit_name_en:item.unit_name_ar}}</span>
                                            </div>
                                            <div class="qty-div suom"
                                                *ngIf="item.unit_id && item.secondary_unit_id && item.unit_id !== item.secondary_unit_id">
                                                <div class="label-2">
                                                    {{calculateSUOM(item.conversation_ratio,item.total_quantity)}}
                                                    <span class="price-units ml-10 text-right color-app-grey">
                                                        {{commonService.domDirectionLTR ? item.secondary_unit_name_en
                                                        :
                                                        item.secondary_unit_name_ar}} </span>
                                                </div>
                                            </div>
                                        </div>
                                        <p class="label-4 color-app-black">X</p>
                                        <p class="label-3 color-app-black">
                                            {{ commonService.currencySymbol }}{{item.final_price_per_unit |
                                            number}}
                                            <span *ngIf="item.unit_name_en && !item.is_sample_product"
                                                class="label-6 color-app-grey">/{{commonService.domDirectionLTR
                                                ?
                                                item.unit_name_en:item.unit_name_ar}}</span>
                                        </p>
                                        <span *ngIf="item.total_quantity > item.available_quantity"
                                            class="error-txt w-160">{{"quantityError" | translate}}
                                            {{item.available_quantity}}</span>
                                    </ion-row>
                                    <ion-row class="row-div ph-15 width-100">
                                        <p class="label-4 color-app-black pv-10 float-end width-100">
                                            {{commonService.currencySymbol}}{{item.total_price|number}}</p>
                                    </ion-row>

                                    <div class="divider-line mv-10"></div>
                                </ion-row>
                            </ion-item>
                            <ion-item-options [side]="'end'">
                                <button ion-button class="btn-delete">
                                    <div class="icon-wrap" (click)="deleteProduct(item,data,$event)">
                                        <div><img src="/assets/icon/trash-white.svg" class="delete-icon" alt="Jazeera">
                                        </div>
                                    </div>
                                </button>
                            </ion-item-options>
                        </ion-item-sliding>


                        <div class="ph-15">
                            <app-order-summary currency="{{commonService.currencySymbol}}"
                                orderTotal="{{data.sub_total_price.total_price}}"
                                [shippingEliminated]="cartData.is_shipping_cost_eliminated"
                                [eliminatedRates]="data.sub_total_price"
                                orderSubTotal="{{data.sub_total_price.items_sub_total}}"
                                orderDiscount="{{data.sub_total_price.coupon_discounted_value}}"
                                discountCode="{{appliedCouponCode ? appliedCouponCode : null}}"
                                orderVAT="{{data.sub_total_price.vat}}" [orderSummeryData]="data?.sub_total_price"
                                orderShipping="{{data.sub_total_price.shipping_charges}}"
                                orderShippingWithoutVat="{{data.sub_total_price.shipping_charges_without_vat}}"
                                [fromList]="true" shippingVat="{{data.sub_total_price.shipping_vat_charges}}"
                                [supplierIndex]="i" vatPercent="{{data.sub_total_price.vat_percentage}}">
                            </app-order-summary>
                        </div>



                    </div>
                </div>

            </div>
            <div class="divider-line mv-10"></div>
            <div *ngIf="cartData.result && cartData.result.length">
                <div class="mob-div-discount">
                    <p class="label-4 color-app-black ph-15 width-50">{{"cartPage.DiscountCode"|translate}}</p>
                    <p class="label-5 color-primary ph-15 pointer  width-50 float-end" (click)="showOfferDialog(true)">
                        {{'cartPage.EXPLOREOFFERS'| translate}}</p>

                </div>
                <div class="input-div input-mobile">
                    <ion-input placeholder="{{ 'cartPage.EnterCode' | translate }}" [(ngModel)]="appliedCouponCodeValue"
                        [disabled]="codeApplied">
                    </ion-input>

                    <span *ngIf="!appliedCouponCode" class="label-5 color-primary mh-5 "
                        (click)="applyCodeToCart()">{{'Apply'|translate}}</span>
                    <span *ngIf="appliedCouponCode" class="label-5 color-primary mh-5 "
                        (click)="removeAppliedCode()">{{'remove'|translate}}</span>
                </div>

            </div>
        </ion-col>


        <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="3" size-xl="3" class="ph-10"
            *ngIf="cartData.result && cartData.result.length">
            <div class="divider-line mv-10"></div>
            <p class="title-3 color-app-black ph-15">{{'cartPage.Ordersummary' | translate}}</p>

            <div class="ph-15" *ngIf="cartData.grand_total">
                <app-order-summary currency="{{commonService.currencySymbol}}"
                    orderTotal="{{cartData.grand_total.total_price}}"
                    orderSubTotal="{{cartData.grand_total.items_sub_total}}"
                    [shippingEliminated]="cartData.is_shipping_cost_eliminated" [eliminatedRates]="cartData.grand_total"
                    orderDiscount="{{cartData.grand_total.coupon_discounted_value}}" discountCode="{{appliedCouponCode ?
                    appliedCouponCode : null}}" orderVAT="{{cartData.grand_total.vat}}"
                    orderShipping="{{cartData.grand_total.shipping_charges}}" [orderSummeryData]="cartData.grand_total"
                    orderShippingWithoutVat="{{cartData.grand_total.shipping_charges_without_vat}}"
                    shippingVat="{{cartData.grand_total.shipping_vat_charges}}"
                    vatPercent="{{cartData.grand_total.vat_percentage}}" isTotalSummary="true">
                </app-order-summary>
            </div>
            <div class="ph-15" *ngIf="!isQuoteExist && showGetQuote && !btnDisabled">
                <app-common-button color="#FFF" background="#434345" type="solid" class="ph-15 width-100"
                    title="{{'rfqBtntext'|translate}}" [isIcon]="false" [isHideInMobile]="false" [isFullWidth]="true"
                    (buttonClicked)="openRfqConfirmation(cartData.result)" [specificHeight]="'50px'"
                    [specificFontSize]="'1rem'" [buttonDisalbed]="cartData.grand_total ? false : true">
                </app-common-button>
            </div>
            <div class="ph-15">
                <app-common-button color="#FFF" background="var(--ion-color-primary)" type="solid" class="ph-15 width-100"
                    title="{{'cartPage.Proceedtocheckout'|translate}}" [isIcon]="false" [isHideInMobile]="false"
                    [isFullWidth]="true" (buttonClicked)="proceedCheckout()" [specificHeight]="'50px'" [isSecondryBtn]="false" [isPrimaryBtn]="true"
                    [specificFontSize]="'1rem'" [buttonDisalbed]="cartData.grand_total ? false : true">
                </app-common-button>
            </div>



        </ion-col>

    </ion-row>

    <div *ngIf="!isLoggedIn" class="vertical_center">
        <img src="./assets/icon/emptyCart.svg" alt="Jazeera" class="mb-30" />
        <div class="mb-20">
            <p class="label-3 color-app-black ">{{'Thecartisempty' | translate}}</p>
        </div>
        <p class="label-4 ">
            <a (click)="openLoginModal()">{{'Login' | translate}}</a> {{'or' | translate}}
            <a (click)="openRegisterModal()">{{'Register' | translate}}</a>
        </p>
    </div>

    <div *ngIf="cartData.result && !cartData.result.length">
        <div class="vertical_center">
            <img src="./assets/icon/emptyCart.svg" alt="Jazeera" class="mb-30" />
            <div class="mb-20">
                <p class="label-3 color-app-black ">{{'Thecartisempty' | translate}}</p>
            </div>
        </div>
    </div>
</ion-content>