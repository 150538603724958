import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-payment-information',
	templateUrl: './payment-information.component.html',
	styleUrls: ['./payment-information.component.scss'],
})
export class PaymentInformationComponent implements OnInit {

	mobileView = false;

	constructor() { }

	ngOnInit() { }

}
