import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AuthGuard } from "src/app/_helpers/auth.guard";
import { RFQCartMobileComponent } from "src/app/components/rfq-cart-mobile/rfq-cart-mobile.component";
import { HomePage } from "./home.page";

const routes: Routes = [
    {
        path: "",
        component: HomePage,
    },
    {
        path: "privacy-policy",
        data: {
            title: "Privacy policy",
            breadcrumb: "cmsComponent.privacyPolicy",
            url: "/privacy-policy",
        },
        loadChildren: () =>
            import("../../pages/privacy-policy/privacy-policy.module").then(
                (m) => m.PrivacyPolicyPageModule
            ),
    },
    {
        path: "faq",
        data: {
            title: "FAQ",
            breadcrumb: "cmsComponent.faq",
            url: "/faq",
        },
        loadChildren: () =>
            import("../../pages/faq/faq.module").then((m) => m.FaqPageModule),
    },

    {
        path: "pdf-view",
        data: {
            title: "PDF",
            url: "/home/pdf-view",
        },
        loadChildren: () =>
            import("../../pages/pdf-view/pdf-view.module").then((m) => m.PdfViewModule),
    },
    {
        path: "about-us",
        data: {
            title: "About us",
            breadcrumb: "cmsComponent.aboutUs",
            url: "/about-us",
        },
        loadChildren: () =>
            import("../../pages/about-us/about-us.module").then(
                (m) => m.AboutUsPageModule
            ),
    },
    {
        path: "press",
        data: {
            title: "Press",
            breadcrumb: "cmsComponent.press",
            url: "/press",
        },
        loadChildren: () =>
            import("../../pages/press/press.module").then((m) => m.PressModule),
    },
    {
        path: "press/:id",
        data: {
            title: "Press",
            breadcrumb: "cmsComponent.press",
            url: "/press",
        },
        loadChildren: () =>
            import("../../pages/press-details/press-details.module").then(
                (m) => m.PressDetailsModule
            ),
    },
    {
        path: "return-policy",
        data: {
            title: "Return policy",
            breadcrumb: "cmsComponent.returnPolicy",
            url: "/return-policy",
        },
        loadChildren: () =>
            import("../../pages/return-policy/return-policy.module").then(
                (m) => m.ReturnPolicyPageModule
            ),
    },
    {
        path: "account-details",
        data: {
            title: "Account",
        },
        loadChildren: () =>
            import("../../pages/account-details/account-details.module").then(
                (m) => m.AccountDetailsPageModule
            ),
    },
    {
        path: "product-listing/:category-name/:id",
        data: {
            title: "Product listing",
            breadcrumb: "products",
            url: "/home/product-listing",
        },
        loadChildren: () =>
            import("../../pages/product-listing/product-listing.module").then(
                (m) => m.ProductListingPageModule
            ),
    },
    {
        path: "product-listing",
        data: {
            title: "Product listing",
            breadcrumb: "products",
            url: "/home/product-listing",
        },
        loadChildren: () =>
            import("../../pages/product-listing/product-listing.module").then(
                (m) => m.ProductListingPageModule
            ),
    },
    {
        path: "category-listing/:group-name/:id",
        data: {
            title: "Category",
            breadcrumb: "category",
            url: "/home/category",
        },
        loadChildren: () =>
            import("../../pages/category-page/category-page.module").then(
                (m) => m.CategoryPageModule
            ),
    },
    {
        path: "category",
        data: {
            title: "Category",
            breadcrumb: "category",
            url: "/home/category",
        },
        loadChildren: () =>
            import("../../pages/category-page/category-page.module").then(
                (m) => m.CategoryPageModule
            ),
    },
    {
        path: ":category-name/:product-name/:id",
        data: {
            title: "Product details",
        },
        loadChildren: () =>
            import("../../pages/product-detail/product-detail.module").then(
                (m) => m.ProductDetailPageModule
            ),
    },
    {
        path: "search",
        data: {
            breadcrumb: "Search result",
            url: "/home/search",
        },
        loadChildren: () =>
            import("../../pages/search/search.module").then(
                (m) => m.SearchPageModule
            ),
    },
    {
        path: "career",
        data: {
            title: "Career",
            breadcrumb: "Careers",
            url: "/career",
        },
        loadChildren: () =>
            import("../../pages/career/career.module").then(
                (m) => m.CareerPageModule
            ),
    },
    {
        path: "contact-us",
        data: {
            title: "Contact us",
            breadcrumb: "footerComponent.contactUs",
            url: "/contact-us",
        },
        loadChildren: () =>
            import("../../pages/contact-us/contact-us.module").then(
                (m) => m.ContactUsPageModule
            ),
    },
    {
        path: "order-thank-you",
        canActivate: [AuthGuard],
        data: {
            title: "Thank you",
            breadcrumb: "Thank you",
            url: "/home/order-thank-you",
        },
        loadChildren: () =>
            import("../../pages/order-thank-you/order-thank-you.module").then(
                (m) => m.OrderThankYouModule
            ),
    },
    {
        path: "order-payment",
        canActivate: [AuthGuard],
        data: {
            title: "Payment",
            breadcrumb: "Payment",
            url: "home/order-payment",
        },
        loadChildren: () =>
            import("../../pages/order-payment/order-payment.module").then(
                (m) => m.OrderPaymentModule
            ),
    },
    {
        path: "payment-loading",
        canActivate: [AuthGuard],
        data: {
            breadcrumb: "Payment Loading",
            url: "home/payment-loading",
        },
        loadChildren: () =>
            import("../../pages/payment-loading/payment-loading.module").then(
                (m) => m.PaymentLoadingModule
            ),
    },
    {
        path: "cookie-policy",
        data: {
            title: "Cookie policy",
            breadcrumb: "footerComponent.cookies",
            url: "/cookie-policy",
        },
        loadChildren: () =>
            import("../../pages/cookie-policy/cookie-policy.module").then(
                (m) => m.CookiePolicyPageModule
            ),
    },
    {
        path: "terms-of-use",
        data: {
            title: "Terms of use",
            breadcrumb: "footerComponent.term",
            url: "/terms-of-use",
        },
        loadChildren: () =>
            import("../../pages/terms-of-use/terms-of-use.module").then(
                (m) => m.TermsOfUsePageModule
            ),
    },
    {
        path: "order-details-mobile",
        loadChildren: () =>
            import(
                "../../pages/order-details-mobile/order-details-mobile.module"
            ).then((m) => m.OrderDetailsMobilePageModule),
    },
    {
        path: "rfq-mobile",
        component: RFQCartMobileComponent
    },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class HomePageRoutingModule { }
