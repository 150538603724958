import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-customer-group',
	templateUrl: './customer-group.component.html',
	styleUrls: ['./customer-group.component.scss'],
})
export class CustomerGroupComponent implements OnInit {

	mobileView = false;

	constructor() { }

	ngOnInit() { }

}
