<app-loader *ngIf="isLoading"></app-loader>
<div class="my-account" *ngIf="getPlatformService.isDesktop">
    <div class="main-title">
        <h2>{{'myAccountComponent.myAccount' | translate}}</h2>
    </div>
    <ng-container *ngFor="let data of componentData">

        <div *ngIf="!isLoading">
            <div class="section-heading">
                <div class="section-name">{{data.section_name | translate}}</div>
				<!-- code maybe needed in future -->
                <!-- <div class="section-button-containter" *ngIf="data.section_btn">
                    <span class="section-btn" (click)="data.id == '0' ? editProfileWebOpen() : manageAddressClick()">
                        {{data.section_btn | translate}}
                    </span>
                </div> -->
            </div>
            <div class="section-content" class="pt-30">
                <!-- <app-orders *ngIf="data.id == '2'"></app-orders> -->
                <app-account-information *ngIf="data.id == '0'"></app-account-information>
				<app-payment-information *ngIf="data.id == '1'"></app-payment-information>
				<app-customer-group *ngIf="data.id == '2'"></app-customer-group>
				<app-financial-details *ngIf="data.id == '3'"></app-financial-details>
				<app-sales-information *ngIf="data.id == '4'"></app-sales-information>
                <app-address *ngIf="data.id == '5'" [showEditBtn]="true" [showTable]="false"></app-address>
            </div>
        </div>

    </ng-container>
	<div class="status-info-container">
		<div class="status-header">
			Status
		</div>
		<div class="status-label pt-30 pl-10">
			Acitve
		</div>
		<div class="status pl-10">
			Yes
		</div>
	</div>
</div>

<ng-container *ngIf="!getPlatformService.isDesktop">
	<ion-header class="address-header" mode="md">
		<ion-toolbar mode="md">
			<img src="./assets/icon/leftArrow.svg" alt="back-arrow" slot="start" (click)="goBack()"
				[ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
			<ion-title mode="ios">
				<div>{{'myAccountComponent.myAccountDetails'| translate}}</div>
			</ion-title>
			<img src="./assets/icon/edit-review.svg" alt="add" slot="end" (click)="editProfileOpen()">
		</ion-toolbar>
	</ion-header>




	<ion-content *ngIf="!isLoading">
		<div *ngFor="let data of mobComponentData">

			<div class="my-mob-account" *ngIf="customerData">
				<div class="section-heading">
					<div class="section-name">{{data.section_name | translate}}</div>
					<div class="section-button-containter" *ngIf="data.section_btn">
						<span class="section-btn">
							{{data.section_btn | translate}}
						</span>
					</div>
				</div>
				<div class="section-content" class="pt-20'">
					<app-account-information *ngIf="data.id == '0'"
						[isMobile]="!getPlatformService.isDesktop"></app-account-information>

					<ng-container *ngIf="data.id == '1'">
						<div class="mb-10">
							<!-- <ion-item lines="none" mode="md"> -->
							<img slot="start" src="assets/icon/company-registration-icon.svg" alt="Jazeera">
							<ion-input label-placement="floating" fill="outline" class="company-registration p-0"
								[(ngModel)]="customer_cr_no" placeholder="Company registration number"
								inputmode="numeric" readonly></ion-input>
							<!-- </ion-item> -->

							<div class="file-name pointer color-primary" *ngIf="customerData.customer_cr_url"
								(click)="openFile(customerData.customer_cr_url)">
								<i class="feather color-primary icon-file"></i>
								{{customerData.customer_cr_url ?
								getFileName(customerData.customer_cr_url):"--"}}
							</div>
						</div>
					</ng-container>

					<ng-container *ngIf="data.id == '3'">
						<div class="mb-10">
							<!-- <ion-item lines="none" mode="md"> -->
							<img slot="start" src="assets/icon/company-registration-icon.svg" alt="Jazeera">
							<ion-input label-placement="floating" fill="outline" class="company-registration p-0"
								[(ngModel)]="customer_vat_no" placeholder="Company registration number"
								inputmode="numeric" readonly></ion-input>
							<!-- </ion-item> -->


							<div class="file-name pointer color-primary" *ngIf="customerData.customer_vat_url"
								(click)="openFile(customerData.customer_vat_url)">
								<i class="feather color-primary icon-file"></i>
								{{customerData.customer_vat_url ?
								getFileName(customerData.customer_vat_url):"--"}}
							</div>
						</div>
					</ng-container>

					<ng-container *ngIf="data.id == '4'">
						<div class="common-input mb-10">
							<div class="file-name pointer color-primary" *ngIf="customerData.national_address_url"
								(click)="openFile(customerData.national_address_url)">
								<i class="feather color-primary icon-file"></i>
								{{customerData.national_address_url ?
								getFileName(customerData.national_address_url):"--"}}
							</div>

						</div>
					</ng-container>

					<ng-container *ngIf="data.id == '2'">
						<ion-list class="p-0">
							<ion-item *ngFor="let item of registrationCheckbox" lines="none" class="interest-css">
								<ion-label class="label-medium p-0">
									{{commonService.domDirectionLTR ? item.label : item.label_ar}}</ion-label>
								<ion-checkbox mode="md" slot="start" [value]="item.value" class="checkbox-css"
									disabled="true" (ionChange)="selectCheckbox(item)"
									[checked]="item.isChecked"></ion-checkbox>
							</ion-item>
						</ion-list>
					</ng-container>
				</div>
			</div>



		</div>
		<!-- Don't remove important code -->
		<!-- <div class="ph-20 pb-30">
            <app-common-button color="#FFF" background="#0081CA" type="solid" style="width: 100%;"
                (click)="editProfileOpen()" title="{{'Edit' | translate}}" [isIcon]="false" [isHideInMobile]="false"
                [isFullWidth]="true" [specificFontSize]="'1rem'">
            </app-common-button>
        </div> -->

	</ion-content>


</ng-container>