<div class="account-information">
	<div class="account-info-grid">
		<ion-grid>
			<ion-row>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"Payment Terms Code"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{'N60'}}
							</div>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"Payment Terms Name"}}
						</div>
						<div class="information-container">
							<div class="security-note">
								<span>{{"Net 60"}}</span>
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>

			<ion-row class="mt-30">
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="information-holder">
						<div class="information-label">
							{{"Currency"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{"INR"}}
							</div>
						</div>
					</div>
				</ion-col>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
					<div class="information-holder">
						<div class="information-label">
							{{"Payment Disc"}}
						</div>
						<div class="information-container">
							<div class="account-name">
								{{"20%"}}
							</div>
						</div>
					</div>
				</ion-col>
			</ion-row>
		</ion-grid>
	</div>
</div>
<div class="account-information" *ngIf="mobileView">
	<div class="account-info-grid">
		<ion-grid class="p-0">
			<ion-row>
				<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
					<div class="common-input mb-10">
						<ion-item lines="none" mode="md">
							<img slot="start" src="assets/icon/email-icon.svg" alt="emailicon" class="input-icon">
							<ion-label class="input-color p-0">{{contactInformation.customer_email}}</ion-label>
						</ion-item>

					</div>

					<div class="common-input mb-10">
						<ion-item lines="none" mode="md">
							<img slot="start" src="assets/icon/user.svg" alt="jazeera" class="input-icon">
							<ion-label class="input-color p-0">{{contactInformation.customer_name}}</ion-label>
						</ion-item>

					</div>

					<div class="common-input mb-10">
						<ion-item lines="none" mode="md">
							<img slot="start" src="assets/icon/password-icon.svg" alt="password" class="input-icon">
							<ion-label class="input-color p-0 lbl-password ">12345678</ion-label>

							<ion-label slot="end" class="btn-label"
								(click)="openModal()">{{"accountInfoComponent.change" |
								translate}}</ion-label>
						</ion-item>


					</div>

				</ion-col>

			</ion-row>
		</ion-grid>
	</div>
</div>
