import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SharedParamsService } from 'src/app/services/sharedParams.service';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { GetPlatformService } from '../../services/get-platform.service';
import { ToastService } from '../../services/toast.service';

@Component({
	selector: 'app-explore-catelogue',
	templateUrl: './explore-catelogue.component.html',
	styleUrls: ['./explore-catelogue.component.scss'],
})
export class ExploreCatelogueComponent implements OnInit, AfterViewInit {

	@ViewChild('cateogrySlide', { static: false }) slider!: ElementRef;
	sliderEl: any;
	private subscription: Subscription = new Subscription();
	public recommendedData = [];
	public showAll: boolean = false;
	public isLoading: boolean = false;

	public categoriesOpts = {
		initialSlide: 0,
		speed: 200,
		spaceBetween: 0,
		slidesPerView: 6,
		breakpoints: {
			320: {
				slidesPerView: 1,
			},
			360: {
				slidesPerView: 1,
			},
			488: {
				slidesPerView: 4,
			},
			768: {
				slidesPerView: 6,
			},
			1024: {
				slidesPerView: 6,
			},
			1280: {
				slidesPerView: 6,
			},
			1600: {
				slidesPerView: 6,
			},
			1920: {
				slidesPerView: 6,
			},

		},
	};
	// @ViewChild("productsSlides", { static: false }) slider: IonSlides;

	public lastSlide: boolean = false;
	public slideIndex: any;
	public firstSlide: boolean = true;
	public sliderEnd: any;

	constructor(public router: Router,
		private dataService: ApiService,
		private toastService: ToastService,
		public commonService: CommonService,
		private translate: TranslateService,
		private navCtrl: NavController,
		public getPlatform: GetPlatformService,
		public sharedParamsService: SharedParamsService) {
	}

	ngOnInit() {
		if (this.getPlatform.isDesktop)
			this.getExploreCatalogue();
	}

	ngAfterViewInit() {
		setTimeout(() => {
			if (this.slider) {
				this.sliderEl = this.slider.nativeElement;
				Object.assign(this.sliderEl, this.categoriesOpts);
				this.sliderEl.initialize();
			}

		}, 200);
	}

	viewallcategories() {
		this.showAll = !this.showAll;
	}

	getExploreCatalogue() {
		this.isLoading = true;
		this.subscription.add(
			this.dataService.get(URLS.allGroupList).subscribe(data => {
				if (data['code'] == 200) {
					this.isLoading = false;
					let sliderData = data['data']['result'];
					sliderData.forEach((element, index,) => {
						let obj = {
							product_img: element.group_image_url ? element.group_image_url : "",
							product_description: element.category_description_en ? element.category_description_en : "Adipiscing consectetur do",
							product_descrption_ar: element.category_description_ar ? element.category_description_ar : "Adipiscing consectetur do",
							product_title: element.group_name_en ? element.group_name_en : "",
							product_title_ar: element.group_name_ar ? element.group_name_ar : "",
							product_name: element.group_name_en ? element.group_name_en : "",
							product_name_ar: element.group_name_ar ? element.group_name_ar : "",
							group_id: element.group_id ? element.group_id : 0,
						}
						this.recommendedData.push(obj);
					})
				} else {
					this.isLoading = false;
				}
			}, error => {
				this.isLoading = false;
			})
		)
	}

	goToCategory(data) {
		let grp_id = data.group_id;
		let catnameUrl: any = this.commonService.trimUrl(data.product_name ? data.product_name : data.product_name_en);
		let currentLang = this.translate.currentLang;
		this.navCtrl.navigateForward('/' + currentLang + '/category-listing/' + catnameUrl + '/' + grp_id,
			{
				animated: false,
				"state": {
					"breadcrumb": this.commonService.domDirectionLTR ? data.product_name : data.product_name_ar,
					"url": '/' + currentLang + '/category-listing/' + catnameUrl + '/' + grp_id,
				},
			});
	}

	async slideChanged() {
		this.slideIndex = await this.sliderEl.swiper.activeIndex;
		this.sliderEnd = await this.sliderEl.swiper.isEnd;
		if (this.slideIndex === 0) {
			this.firstSlide = true;
			this.lastSlide = false;
		} else if (this.slideIndex > 0) {
			this.firstSlide = false;
			this.lastSlide = false;
		}
	}

	slideToNext() {
		this.sliderEl.swiper.slideNext();
		this.slideChanged();
	}

	slideToPrev() {
		this.sliderEl.swiper.slidePrev();
		this.slideChanged();
	}
}
