import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/services/api-data.service';
import { ModalService } from '../../services/modal.service';
import { CartService } from './../../services/cart.service';
import { CommonService } from './../../services/common.service';
import { GetPlatformService } from './../../services/get-platform.service';
import { ToastService } from './../../services/toast.service';

@Component({
    selector: 'app-cart-modal',
    templateUrl: './cart-modal.page.html',
    styleUrls: ['./cart-modal.page.scss'],
})
export class CartModalPage implements OnInit {


    public cartData: any = [];
    cartDetails: any = [];
    isLoading: boolean = false;
    subscription: Subscription = new Subscription();
    isAddToCartLoading = false;

    constructor(
        public getPlatformService: GetPlatformService,
        private modalService: ModalService,
        private formBuilder: FormBuilder,
        public cartService: CartService,
        public router: Router,
        public commonService: CommonService,
        private translate: TranslateService,
        private alertController: AlertController,
        public navCtrl: NavController,
        private dataService: ApiService,
        public toastService: ToastService,

    ) {
    }

    ngOnInit() {
        this.commonService.localStorageRemove('discount_id');
        this.commonService.localStorageRemove('discount_code');
        if (this.cartData && this.cartData.length == 0) {
            this.isLoading = true;

        }
        this.cartService.getCart().then((data) => {
            let cartDetails: any = data;
            this.cartDetails = data;
            this.cartData = [];
            cartDetails.result.forEach(element => {
                element.products.forEach(element2 => {
                    this.cartData.push(element2);
                });
            });
            this.isLoading = false;

        }, error => {
            this.isLoading = false;
        });

    }

    closeModal() {
        this.modalService.dismissModal()
    }

    gotoCartPage() {
        this.commonService.localStorageSet('url', this.router.url);
        this.commonService.localStorageSet('fromCartHeader', 'false');
        this.commonService.localStorageRemove('tempQuoteId');
        this.isAddToCartLoading = true;
        this.cartService.getCart().then((data) => {
        let cartData: any = data;
        let is_not_sample_product = true;
        let is_sample_item_and_shipment_free = false;
            for(let i = 0; i < cartData.result.length; i++) {
                const element = cartData.result[i];
                if (element.products && element.products.length) {
                    is_not_sample_product = element.products.some(obj => !obj.is_sample_product);
                    is_sample_item_and_shipment_free = element.products.some(obj => obj.is_sample_item_and_shipment_free);
                    if(is_not_sample_product || (!is_not_sample_product && !is_sample_item_and_shipment_free)){
                        break;
                    }
                    
                }
            }

            if (is_not_sample_product || (!is_not_sample_product && !is_sample_item_and_shipment_free)) {
                this.isAddToCartLoading = false;
                this.modalService.dismissModal();
                this.router.navigate(['/' + this.translate.currentLang + '/cart']);
            } else if(!is_not_sample_product && is_sample_item_and_shipment_free) {
                this.isAddToCartLoading = false;
                this.modalService.dismissModal();
                this.navCtrl.navigateForward(['' + this.translate.currentLang + '/checkout-step-1'], { animated: false });
            }
            this.isAddToCartLoading = false;
       });
    }

    async deleteProduct(data) {
        const alertCtrl = await this.alertController.create({
            header: this.translate.instant("DeleteCartProduct"),
            message: this.translate.instant("DeleteCartProductMsg"),
            buttons: [
                {
                    text: this.translate.instant("no"),
                    role: 'cancel',
                    handler: () => {

                    },
                },
                {
                    text: this.translate.instant("yes"),
                    role: 'confirm',
                    handler: () => {
                        this.isLoading = true;
                        this.cartService.deleteCart(data).then(data2 => {
                            if (data2 && data2['code'] == 200) {
                                this.cartData = [];
                                this.commonService.setEvent("cartDeleteEvent", null);
                                this.cartService.getCart().then((data) => {
                                    let cartDetails: any = data;
                                    this.cartDetails = data;
                                    this.isLoading = false;
                                    cartDetails.result.forEach(element => {
                                        element.products.forEach(element2 => {
                                            this.cartData.push(element2);
                                        });
                                    });

                                }, error => {
                                    this.isLoading = false;
                                });
                            }
                        });
                    },
                },
            ],
        });

        await alertCtrl.present();
        const { role } = await alertCtrl.onDidDismiss();

    }


    goToProductDetail(data) {
        this.closeModal();
        let prod_id = data.main_product_ref_id;
        let prodnameUrl = this.commonService.trimUrl(data.product_name ? data.product_name: data.product_name_en);
        let catnameUrl = this.commonService.trimUrl(data.category_name_en ? data.category_name_en : data.category_name);
            
        this.navCtrl.navigateForward('/'+this.translate.currentLang +"/"+catnameUrl+"/"+ prodnameUrl+"/"+ prod_id,
            {
                animated: false,
                "state": {
                    "breadcrumb": data.product_name_en,
                    "url": "/"+catnameUrl+"/"+ prodnameUrl+"/"+ prod_id,
                    "sub_product_id": data.product_id
                }
            });
    }

    clearCart() {
        this.isLoading = true;
        this.subscription.add(
            this.dataService.get(URLS.clearCartAPI).subscribe(data => {
                if (data['data'] && data['code'] == 200) {
                    this.commonService.setEvent("cartDeleteEvent", null);
                    this.cartService.getCart().then((data) => {
                        let cartDetails: any = data;
                        this.cartDetails = data;
                        this.cartData = [];
                        cartDetails.result.forEach(element => {
                            element.products.forEach(element2 => {
                                this.cartData.push(element2);
                            });
                        });
                        this.isLoading = false;

                    })
                }
                else {
                    this.isLoading = false;
                    this.toastService.displayToast(data['message'], null, 'danger');
                }

            }, error => {
                this.isLoading = false;
                this.toastService.displayToast(error['message'], null, 'danger');
            })
        )
    }

    calculateSUOM(ratio, quantity) {
        if (ratio && quantity) {
            return ratio * (+quantity);
        } else {
            return ratio;
        }
    }


}
