import { Component } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { ToastService } from 'src/app/services/toast.service';
import { MyAccountMenuComponent } from '../components/my-account-menu/my-account-menu.component';
import { WishlistFilterMobileComponent } from '../components/wishlist-filter-mobile/wishlist-filter-mobile.component';
import { ModalService } from '../services/modal.service';
import { URLS } from './../_config/api.config';
import { ApiService } from './../services/api-data.service';
import { GetPlatformService } from './../services/get-platform.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-tab2',
    templateUrl: 'tab2.page.html',
    styleUrls: ['tab2.page.scss']
})
export class Tab2Page {
    private subscription: Subscription = new Subscription();
    selectedSubClass: any = 0;
    subClassArray: any = [];
    inerfaceOption = { cssClass: 'custom-dropdown-mobile' };
    filterObj = {};

    public myWishlist = [];
    public subClassId: any;
    public enableNext: any;
    wishListaParams = {
        page: 1,
        size: 10
    }
    isLoggedIn: boolean;
    public isLoading = false;

    public subClassSelect = {
        size: 'cover',
        side: 'bottom',
        alignment: 'center',
        cssClass: 'location-popover'
    }

    showFilter = false;
    statusList;
    selectedCategory: any = 0;
    catagoryListData: any = [];
    currentLang; 
    constructor(private dataService: ApiService,
        private modalService: ModalService,
        public commonService: CommonService,
        private toastService: ToastService,
        public getPlatformService: GetPlatformService,
        private navCtrl: NavController,
        private translate: TranslateService,
        private alertController: AlertController) {
            this.currentLang = this.translate.currentLang;
        this.commonService.eventObservable.subscribe(data => {
            if (data.event == 'profileData' || data.event == 'logoutCall') {
                const token = this.commonService.localStorageGet('accessToken');
                if (token) { this.isLoggedIn = true; } else this.isLoggedIn = false;
                if (this.isLoggedIn) {
                    this.selectedSubClass = 0;
                    this.wishListaParams.page = 1;
                    this.myWishlist = [];
                    this.subClassArray = [];
                    this.getWishList();
                    this.getCatagoryList();
                }
            }
        });

    }

    //catagory filter list
    getCatagoryList() {
        this.subscription.add(
            this.dataService.get(URLS.getWishListSubClass).subscribe(data => {
                if (data['code'] == 200) {
                    this.catagoryListData = data['data']['result'];
                }
                else {
                    this.catagoryListData = [];
                }
            }, error => {
                this.catagoryListData = [];
            })
        )
    }


    openModal() {

        this.modalService.openModal({ component: MyAccountMenuComponent, componentProps: { mobileView: true } })
    }

    ionViewWillEnter() {
        const token = this.commonService.localStorageGet('accessToken');
        if (token) { this.isLoggedIn = true; } else this.isLoggedIn = false;

        if (this.isLoggedIn) {
            this.wishListaParams.page = 1;
            this.myWishlist = [];
            this.subClassArray = [];
            this.catagoryListData = [];
            this.selectedCategory = 0;
            this.subClassId = null;
            this.selectedSubClass = null;
            this.filterObj = {};
            this.getCatagoryList();
            this.getWishList();
        }
    }

    ngOnInit() {

    }


    getSubClassListing() {
        if (!this.selectedCategory) return;
        let param = {
            'category_id': this.selectedCategory
        }
        this.subscription.add(
            this.dataService.get(URLS.getWishListSubClass, param).subscribe(data => {
                if (data && data['code'] == 200) {
                    this.subClassArray = data['data']['result'][0]['children'];
                }
            }, error => {
            })
        )
    }

    async subClassChanged(event) {
        this.subClassId = event.target.value;
        this.wishListaParams.page = 1;
        this.wishListaParams.size = 10;
        this.myWishlist = [];
        await this.getWishList(false);
    }

    async categoyChanged(event) {
        this.selectedCategory = event.target.value;
        this.subClassId = null;
        this.selectedSubClass = null;
        this.wishListaParams.page = 1;
        this.wishListaParams.size = 10;
        this.myWishlist = [];
        this.getSubClassListing();
        await this.getWishList();

    }

    getWishList(isInfiniteScroll?) {
        if (!isInfiniteScroll) {
            this.isLoading = true;
        }
        const params = {
            page: this.wishListaParams.page,
            size: this.wishListaParams.size,
            ...this.filterObj
        }

        if (this.subClassId) {
            params['sub_class_id'] = this.subClassId
        }
        if (this.selectedCategory) {
            params['category_id'] = this.selectedCategory;
        }

        this.subscription.add(
            this.dataService.get(URLS.getWishList, params).subscribe(data => {
                if (data && data['code'] == 200) {
                    this.isLoading = false;
                    let wishListData = data['data']['result'];
                    this.enableNext = data['data']['enable_next'];
                    let tempWishListData = [];
                    if (wishListData && wishListData.length) {
                        wishListData.forEach(element => {
                            let obj = {
                                product_description: element.product_name_en ? element.product_name_en : "",
                                product_name: element.product_name_en ? element.product_name_en : "",
                                product_name_ar: element.product_name_ar ? element.product_name_ar : "",
                                brand_name: element.brand_name_en ? element.brand_name_en : "",
                                product_currency: "SR",
                                product_price: element.price_per_unit ? element.price_per_unit : "",
                                price_per_unit: element.price_per_unit ? element.price_per_unit : "",
                                final_price: element.final_price ? element.final_price : "",
                                product_unit: this.commonService.domDirectionLTR ? element.unit_name_en : element.unit_name_ar,
                                product_unit_arb: element.unit_name_ar,
                                product_id: element.product_id,
                                take_action: true,
                                main_product_ref_id: element.main_product_ref_id,
                                wishlist_id: element.wishlist_id ? element.wishlist_id : null,
                                sold_quantity: element.sold_quantity ? element.sold_quantity : null,
                                is_discount_applied: element.is_discount_applied ? element.is_discount_applied : null,
                                is_tax_free_product: element.is_tax_free_product ? element.is_tax_free_product : null,
                                available_quantity: element.available_quantity ? element.available_quantity : null,
                                is_sold_out: element.is_sold_out ? element.is_sold_out : null,
                                is_wishlist_product: element.is_wishlist_product ? element.is_wishlist_product : null,
                                product_sku: element.product_sku ? element.product_sku : null,
                                total_quantity: element.total_quantity ? element.total_quantity : null,
                                discount_type: element.discount_type ? element.discount_type : null,
                                discount_value: element.discount_value ? element.discount_value : null,
                                sub_product_id: element.sub_product_id ? element.sub_product_id : null,

                            }

                            if (this.getPlatformService.isDesktop) {
                                obj['product_img'] = element.web_image_url ? element.web_image_url : "assets/images/no-img1.png";
                            } else {
                                obj['product_img'] = element.mobile_image_url ? element.mobile_image_url : "assets/images/no-img1.png";
                            }

                            tempWishListData.push(obj);

                        });

                        if (isInfiniteScroll) {
                            this.myWishlist = this.myWishlist.concat(tempWishListData);
                        }
                        else {
                            this.myWishlist = tempWishListData;
                        }

                    }
                    else {
                        if (isInfiniteScroll) {
                            this.myWishlist = [...this.myWishlist];
                        }
                        else {
                            this.myWishlist = [];
                        }
                    }
                } else {
                    this.isLoading = false;
                }
            }, error => {
                this.isLoading = false;
            })
        )
    }
    loadData(event) {
        if (this.enableNext) {
            setTimeout(() => {
                if (this.enableNext) {
                    this.wishListaParams.page += 1
                    this.getWishList(true);
                    event.target.complete();
                }
            }, 1000);
        }
    }

    goToProductDetail(data) {
        let prod_id = data.product_id;
        let prodnameUrl = this.commonService.trimUrl(data.product_name ? data.product_name: data.product_name_en);
        let catnameUrl = this.commonService.trimUrl(data.category_name_en ? data.category_name_en : data.category_name);
        if (data.sub_product_id) {
            this.navCtrl.navigateForward('/'+this.currentLang +'/'+this.currentLang +"/"+catnameUrl+"/"+ prodnameUrl+"/"+ prod_id, { animated: false, "state": { "breadcrumb": data.product_description, "url": "/"+catnameUrl+"/"+ prodnameUrl+"/"+ prod_id, "sub_product_id": data.sub_product_id } });
        } else {
            this.navCtrl.navigateForward('/'+this.currentLang +"/"+catnameUrl+"/"+ prodnameUrl+"/"+ prod_id, { animated: false, "state": { "breadcrumb": data.product_description, "url": "/"+catnameUrl+"/"+ prodnameUrl+"/"+ prod_id, } });
        }
    }

    removeFromWishList(data, index) {
        const params = {
            product_id: data.product_id,
            wishlist_id: data.wishlist_id,
        }
        this.subscription.add(
            this.dataService.post(URLS.removeWishList, params).subscribe(data => {
                if (data && data['code'] == 200) {
                    this.myWishlist.splice(index, 1);
                } else {
                    this.toastService.displayToast(data['message'], null, 'danger');
                }
            }, error => {
                this.toastService.displayToast(error['message'], null, 'danger');
            })
        )
    }

    async presentAlert(data, index) {
        const alert = await this.alertController.create({
            header: this.commonService.domDirectionLTR ? 'Are you sure?' : 'هل أنت متأكد؟',
            message: this.commonService.domDirectionLTR ? "You want to remove from wishlist" : "تريد إزالة من قائمة الرغبات",
            buttons: [
                {
                    text: this.translate.instant("no"),
                    role: 'cancel',
                    handler: () => {
                    },
                },
                {
                    text: this.translate.instant("yes"),
                    role: 'confirm',
                    handler: () => {
                        this.removeFromWishList(data, index)
                    },
                },
            ],
        });

        await alert.present();
        const { role } = await alert.onDidDismiss();
    }

    handleRefresh(event) {
        setTimeout(() => {
            // code maybe required
            // this.isLoading = false;
            if (this.isLoggedIn) {
                this.selectedSubClass = null;
                this.selectedCategory = 0;
                this.getWishList();
                this.getCatagoryList();
                this.getSubClassListing();
            }
            event.target.complete();
        }, 1000);
    };

    goBack() {
        this.navCtrl.navigateRoot('/'+this.currentLang +'/home', { animated: false });
    }

    openFilterByModal() {
        setTimeout(() => {
            let modal: any;
            let options: any = {
                id: "filterByModal",
                componentProps: { filterObj: this.filterObj },
            };
            options['component'] = WishlistFilterMobileComponent;
            modal = this.modalService.openModal(options)
            modal.then((data) => {
                if (data) {
                    if (data['data'] && Object.keys(data['data']).length > 1) {
                        this.filterObj = {
                            ...data['data']
                        };
                        this.getWishList();
                    }
                    else if (data['data'] && Object.keys(data['data']).length < 1) {
                        this.selectedCategory = 0;
                        this.selectedSubClass = null;
                        this.filterObj = {};
                        this.getWishList();
                    }

                }

            })
        }, 300);
    }



}
