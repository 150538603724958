import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { CommonService } from '../../services/common.service';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';

@Component({
    selector: 'app-press',
    templateUrl: './press.component.html',
    styleUrls: ['./press.component.scss'],
})
export class PressComponent implements OnInit, OnDestroy {
    @Input() productData;
    @Output() loadMore = new EventEmitter();
    productlist1 = "productlist1"
    @Input() showBanner: boolean = false;
    @Input() pageName: any;
    @Input() btnStatus: any;
    ;
    public categoryType: any;
    public posts = [];
    public itemsPerPage = 8;
    public currentPage = 1;
    public count = 0;
    perPageInterface = { cssClass: 'custom-select-dd per-page-dd' };
    selectInterface = { cssClass: 'custom-select-dd' };
    private subscription: Subscription = new Subscription();
    public isLoading = false;
    public enableNext = false;

    public pressGridData = [];

    @ViewChild("player") player: ElementRef;

    constructor(
        public router: Router,
        private navCtrl: NavController,
        private route: ActivatedRoute,
        public commonService: CommonService,
        private dataService: ApiService,
        public getPlatformService: GetPlatformService,
        public translateService: TranslateService
    ) { }
    ngOnInit() {
        this.categoryType = this.route.snapshot.params;
        this.getPressList();
    }

    getPressList(isInfiniteScroll?) {
        this.isLoading = true;
        const params = {
            page: this.currentPage,
            size: this.itemsPerPage
        }
        this.subscription.add(
            this.dataService.get(URLS.pressList, params).subscribe(data => {
                if (data['code'] == 200) {
                    if (data['data']) {
                        if (!isInfiniteScroll) {
                            this.pressGridData = data['data']['result'];
                            this.pressGridData.forEach(element => {
                                element.media_type = element.media_url ? element.media_url.substring(element.media_url.lastIndexOf('.') + 1) : null;
                            });
                            this.enableNext = data['data']['enable_next'];
                            this.count = data['data']['total_count'] ? data['data']['total_count'] : 0;
                        }
                        if (isInfiniteScroll) {
                            let pressGridDataInfinte = data['data']['result'];
                            this.pressGridData = this.pressGridData.concat(pressGridDataInfinte);
                            this.pressGridData.forEach(element => {
                                element.media_type = element.media_url ? element.media_url.substring(element.media_url.lastIndexOf('.') + 1) : null;
                            });
                            this.enableNext = data['data']['enable_next'];
                            this.count = data['data']['total_count'] ? data['data']['total_count'] : 0;
                        }

                    }

                    this.isLoading = false;
                }
                else {
                    this.isLoading = false;
                    this.pressGridData = [];
                }
            }, error => {
                this.isLoading = false;
                this.pressGridData = [];
            })
        )
    }



    goToPressDetail(data) {
        this.router.navigateByUrl('/home/press/' + data.press_release_document_id, {
            "state": {
                "breadcrumb": this.commonService.domDirectionLTR ? data.content_title_en : data.content_title_ar,
                "url": '/home/press/', id: data.group_id
            }
        });
    }

    onChange(event): void {
        this.currentPage = event;
        this.getPressList();
    }

    goBack() {
        this.router.navigate(['/home']);
    }

    gomobileBack() {
        this.navCtrl.back({ animated: false });
    }

    loadData(event) {
        setTimeout(() => {
            if (this.enableNext) {
                this.currentPage = this.currentPage + 1;
                this.getPressList(true);
                event.target.complete();
            } else {
                this.getPressList(false);
                event.target.complete();
            }
        }, 1500);
    }

    handleRefresh(event) {
        setTimeout(() => {
            this.currentPage = 1;
            this.itemsPerPage = 8;
            this.pressGridData = [];
            this.getPressList();
            event.target.complete();
        }, 1000);
    };

    ngOnDestroy() {
        // this.player.nativeElement.pause();
    }

    ionViewWillLeave() {
        // this.player.nativeElement.pause();
    }
}
