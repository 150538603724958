import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-financial-details',
	templateUrl: './financial-details.component.html',
	styleUrls: ['./financial-details.component.scss'],
})
export class FinancialDetailsComponent implements OnInit {

	mobileView = false;

	constructor() { }

	ngOnInit() { }

}
