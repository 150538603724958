import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { URLS } from 'src/app/_config/api.config';
import { ModalService } from 'src/app/services/modal.service';
import { QuoteService } from 'src/app/services/quote.service';
import { TranslateConfigService } from '../../services/translate-config.service';
import { ApiService } from './../../services/api-data.service';
import { CartService } from './../../services/cart.service';
import { CommonService } from './../../services/common.service';
import { GetPlatformService } from './../../services/get-platform.service';
import { ToastService } from './../../services/toast.service';

@Component({
    selector: 'app-checkout-step-review-page',
    templateUrl: './checkout-step-review.html',
    styleUrls: ['./checkout-step-review.scss'],
})
export class CheckOutStepReviewPage implements OnInit, OnDestroy {

    public shipping_info: any;
    public billing_info: any;

    public shipping_methods: any = [
        {
            id: 1,
            title: 'Shipping method #2',
            value: '2-3 days delivery',
        }
    ]
    public payment_method: any = [];

    public showCards: boolean = false;
    isLoading: boolean = false;
    private subscription: Subscription = new Subscription();
    cartData: any = [];
    appliedCouponCode: any;
    cartProductData: any = [];
    closed$ = new Subject<any>();
    currentLang;
    is_not_sample_product = true;
    is_sample_item_and_shipment_free = false;
    constructor(private translateConfigService: TranslateConfigService,
        public getPlatformService: GetPlatformService,
        public commonService: CommonService,
        public navCtrl: NavController,
        private dataService: ApiService,
        public router: Router,
        public cartService: CartService,
        private toastService: ToastService,
        private translate: TranslateService,
        private alertController: AlertController,
        public quoteService: QuoteService,
        public modalService: ModalService) {
        this.translateConfigService.getDefaultLanguage();
            this.currentLang = this.translate.currentLang;
    }

    ngOnInit() {
        this.commonService.routerEventVariable = this.router.events.pipe(
            filter(e => e instanceof NavigationEnd),
            takeUntil(this.closed$)
        ).subscribe(event => {

            const token = this.commonService.localStorageGet('accessToken');
            if (token && this.router.url.includes("checkout-step-review")) {
                this.isLoading = true;

                this.cartService.getCart().then((data) => {
                    this.cartData = data;
                    this.cartProductData = [];
                    this.cartData.result.forEach(element => {
                        element.products.forEach(element2 => {
                            this.cartProductData.push(element2);
                        });
                    });
                    for(let i = 0; i < this.cartData.result.length; i++) {
                        const element = this.cartData.result[i];
                        if (element.products && element.products.length) {
                            this.is_not_sample_product = element.products.some(obj => !obj.is_sample_product);
                            this.is_sample_item_and_shipment_free = element.products.some(obj => obj.is_sample_item_and_shipment_free);
                            if(this.is_not_sample_product || (!this.is_not_sample_product && !this.is_sample_item_and_shipment_free)){
                                break;
                            }
                            
                        }
                    }
                    this.routeHistory();
                    this.isLoading = false;
                }).catch(error => {
                    this.routeHistory();
                    this.isLoading = false;
                });

                this.payment_method = this.commonService.localStorageGet('selectedPaymentMethod') ? JSON.parse(this.commonService.localStorageGet('selectedPaymentMethod')) : null;
                this.getAddresses();
            }
        });
    }


    routeHistory() {
        if (!this.cartService.cartList.length && this.getPlatformService.isDesktop && this.router.url.includes("checkout-step-review")) {
            let url = this.commonService.localStorageGet('url');
            if (url) {
                this.commonService.localStorageRemove('url');
                this.navCtrl.navigateRoot(`/${this.currentLang}${url}`, { animated: false, replaceUrl: true })
            } else {
                this.navCtrl.navigateRoot('/'+this.currentLang+"/home/product-listing", { animated: false, replaceUrl: true })
            }
        }
    }

    ionViewWillEnter() {
        this.translateConfigService.getDefaultLanguage();
        this.routeHistory();
        this.payment_method = this.commonService.localStorageGet('selectedPaymentMethod') ? JSON.parse(this.commonService.localStorageGet('selectedPaymentMethod')) : null;
        this.getAddresses();
    }

    async getAddresses() {
        await this.subscription.add(
            this.dataService.get(URLS.getAddressList).subscribe(data => {
                if (data['data'] && data['code'] == 200) {
                    let addresses = data['data'];
                    let shipping_id = this.commonService.localStorageGet('cart_shipping_address');
                    let billing_id = this.commonService.localStorageGet('cart_billing_address');

                    addresses.forEach(element => {
                        if (element.address_id == billing_id) {
                            this.billing_info = element
                        }
                        if (element.address_id == shipping_id) {
                            this.shipping_info = element
                        }
                    });

                } else {
                    this.toastService.displayToast(data['message'], null, 'danger');
                }
            }, error => {
                this.toastService.displayToast(error['message'], null, 'danger');
            })
        )
    }

    goBack() {
        this.navCtrl.navigateBack('/'+this.currentLang+'/checkout-step-2');
    }

    placeOrderMethod() {
        if (this.payment_method && this.payment_method.payment_type_id && this.payment_method.payment_type_id == "SADDAD") {
            this.submitSaddadPayment();
        }
        else {
            this.proceedtoChechout();
        }
    }

    async proceedtoChechout() {
        this.isLoading = true;

        const params = {
            "shipping_address_id": this.shipping_info.address_id,
            "billing_address_id": this.billing_info.address_id,
        }
        if(this.payment_method && this.payment_method.payment_type_id){
            params['payment_type'] = this.payment_method.payment_type_id
        }
        if(!this.is_not_sample_product && this.is_sample_item_and_shipment_free){
            delete params['payment_type']
            params['is_free_sample_product'] = true;
        }
        if(!this.is_not_sample_product && this.is_sample_item_and_shipment_free){
            delete params['payment_type']
            params['is_free_sample_product'] = true;
        }
        let discount_id = this.commonService.localStorageGet('discount_id');
        let discount_code = this.commonService.localStorageGet('discount_code');

        if ((discount_id || discount_code) && (discount_code != "null" ||
            discount_id != "null") && (discount_code != null || discount_id != null)) {
            if (discount_id && discount_id != "null" && discount_id != null) {
                params['discount_code_id'] = discount_id;
            }
            if (discount_code && discount_code != "null" && discount_code != null) {
                params['discount_code'] = discount_code;
            }
        }


        this.subscription.add(
            this.dataService.post(URLS.checkoutInvoice, params).subscribe(async data => {
                if (data && data['code'] == 200) {
                    this.isLoading = false;
                    this.commonService.localStorageRemove('fromCartHeader');
                    if (data && data['data']) {
                        if (this.commonService.localStorageGet('tempQuoteId')) {
                            await this.quoteService.updateQuote(this.commonService.localStorageGet('tempQuoteId'), null, null, null, data['data'].invoice_id).then(data2 => {
                                if (data2 && data2['code'] == 200) {
                                    this.commonService.localStorageRemove('tempQuoteId');
                                }
                            }).catch(error => {
                                console.log(error);
                            });
                        }

                        this.router.navigate([''+this.translate.currentLang+'/home/order-payment'], {
                            queryParams: {
                                "orderId": data['data'].order_no ? data['data'].order_no : 0,
                                "id": data['data'].invoice_id ? data['data'].invoice_id : 0,
                                "payment_url": data['data'].payment_url ? data['data'].payment_url : null,
                                "transaction_id": data['data'].transaction_id ? data['data'].transaction_id : null,
                            }
                        });
                    }


                } else {
                    this.isLoading = false;
                    this.toastService.displayToast(data['message'], null, 'danger');
                }
            }, error => {
                this.isLoading = false;
                this.toastService.displayToast(error['message'], null, 'danger');
            })
        )
    }

    goToProductDetail(data) {
        let prod_id = data.main_product_ref_id;
        let prodnameUrl = this.commonService.trimUrl(data.product_name ? data.product_name: data.product_name_en);
        let catnameUrl = this.commonService.trimUrl(data.category_name_en ? data.category_name_en : data.category_name);
        this.navCtrl.navigateForward('/'+this.currentLang+"/"+catnameUrl+"/"+ prodnameUrl+"/"+ prod_id,
            {
                animated: false,
                "state": {
                    "breadcrumb": data.product_name_en,
                    "url": "/"+catnameUrl+"/"+ prodnameUrl+"/"+ prod_id,
                    "sub_product_id": data.product_id
                }
            });
    }

    navigateToPaymentCart() {
        this.router.navigate([''+this.translate.currentLang+'/checkout-step-2']);
    }

    navigateToshippingCart() {
        this.router.navigate([''+this.translate.currentLang+'/checkout-step-1']);
    }

    ngOnDestroy() {
        this.commonService.localStorageRemove('fromCartHeader');
        this.commonService.localStorageRemove('selectedPaymentMethod');
        this.commonService.routerEventVariable.unsubscribe();
    }

    ionViewWillLeave() {
        this.commonService.localStorageRemove('fromCartHeader');
        this.commonService.localStorageRemove('selectedPaymentMethod');
        this.commonService.routerEventVariable.unsubscribe();
    }


    paymentStatusMethodCall(data) {
        const params = {
            "transaction_id": data.transaction_id ? data.transaction_id : null,
            "invoice_id": data.invoice_id ? data.invoice_id : null,
        }

        this.subscription.add(
            this.dataService.get(URLS.paymentStatus, params).subscribe(data => {
                if (data && data['code'] == 200) {
                    this.isLoading = false;
                    let resData = data['data'];
                    this.commonService.localStorageRemove('selectedPaymentMethod');
                    this.commonService.localStorageRemove('discount_id');
                    this.commonService.localStorageRemove('discount_code');
                    this.commonService.localStorageRemove('fromCartHeader');

                    if (resData['payment_status']) {
                        this.router.navigate(['/home/order-thank-you'], {
                            queryParams: {
                                "payment_amount": resData.payment_amount ? resData.payment_amount : 0,
                                "payment_id": resData.payment_id ? resData.payment_id : 0,
                                "payment_status": resData.payment_status ? resData.payment_status : 0,
                                "transaction_id": resData.transaction_id ? resData.transaction_id : 0,
                                "orderId": data.order_no ? data.order_no : 0,
                                "id": data.invoice_id ? data.invoice_id : 0,
                                // "due_date": data.due_date ? data.due_date :
                            }
                        });
                    }


                } else {
                    this.isLoading = false;
                    this.toastService.displayToast(data['message'], null, 'danger');
                    this.gotoCart();
                }
            }, error => {
                this.isLoading = false;
                this.toastService.displayToast(error['message'], null, 'danger');
                this.gotoCart();
            })
        )
    }

    gotoCart() {
        this.navCtrl.navigateBack('/'+this.currentLang+"/cart", { animated: false })
    }

    async submitSaddadPayment() {
        this.isLoading = true;

        const params = {
            "shipping_address_id": this.shipping_info.address_id,
            "billing_address_id": this.billing_info.address_id,
            "payment_type": this.payment_method.payment_type_id,
        }
        let discount_id = this.commonService.localStorageGet('discount_id');
        let discount_code = this.commonService.localStorageGet('discount_code');

        if ((discount_id || discount_code) && (discount_code != "null" ||
            discount_id != "null") && (discount_code != null || discount_id != null)) {
            if (discount_id && discount_id != "null" && discount_id != null) {
                params['discount_code_id'] = discount_id;
            }
            if (discount_code && discount_code != "null" && discount_code != null) {
                params['discount_code'] = discount_code;
            }
        }
        await this.subscription.add(
            this.dataService.post(URLS.sadadPaymentAPI, params).subscribe(async data => {
                this.isLoading = false;
                if (data['data'] && data['code'] == 200) {
                    this.commonService.localStorageRemove('selectedPaymentMethod');
                    this.commonService.localStorageRemove('discount_id');
                    this.commonService.localStorageRemove('discount_code');
                    this.commonService.localStorageRemove('fromCartHeader');

                    if (this.commonService.localStorageGet('tempQuoteId')) {
                        await this.quoteService.updateQuote(this.commonService.localStorageGet('tempQuoteId'), null, null, null, data['data'].invoice_id,true).then(data2 => {
                            if (data2 && data2['code'] == 200) {
                                this.commonService.localStorageRemove('tempQuoteId');
                            }
                        }).catch(error => {
                            console.log(error);
                        });
                    }

                    let response = data['data'];
                    this.router.navigate(['/home/order-thank-you'], {
                        queryParams: {
                            "payment_amount": response.payment_amount ? response.payment_amount : 0,
                            "payment_id": response.payment_id ? response.payment_id : 0,
                            "payment_status": response.payment_status ? response.payment_status : 0,
                            "transaction_id": response.saddad_invoice_no ? response.saddad_invoice_no : 0,
                            "orderId": response.order_no ? response.order_no : 0,
                            "id": response.invoice_id ? response.invoice_id : 0,
                            "due_date": response.due_date ? response.due_date : null
                        }
                    });

                } else {
                    this.isLoading = false;
                    this.toastService.displayToast(data['message'], null, 'danger');
                    this.gotoCart();
                }
            }, error => {
                this.isLoading = false;
                this.toastService.displayToast(error['message'], null, 'danger');
                this.gotoCart();
            })
        )
    }

    calculateSUOM(ratio, quantity) {
        if (ratio && quantity) {
            return ratio * (+quantity);
        } else {
            return ratio;
        }
    }

}
