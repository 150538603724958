<div class="desktop-visibility">
    <div class="mb-30">
        <h1 class="head-title color-app-black">{{'dashboard' | translate}}</h1>
    </div>

    <div class="scroll-div">
        <ion-row>
            <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="6" size-xl="6" class="pr-15 pb-30">
                <div class="bg-primary page-grid">
                    <div class="row-css mb-16">
                        <p class="label1 color-white width-100">{{'accountDashboard.Orders' | translate}}</p>
                        <img src="/assets/icon/dashboard-order.svg" class="img-grid" alt="juman" height="100%"
                            width="100%" />
                    </div>
                    <div class="row-css">
                        <div class="width-70 row-css justify-space-between">
                            <div>
                                <p class="label2 color-white">{{'accountDashboard.Total' | translate}}</p>
                                <p class="label3 color-white">{{ dashboardData && dashboardData.total_order
                                    ?dashboardData.total_order:0}}
                                </p>
                            </div>
                            <div class="verical-line"></div>
                            <div>
                                <p class="label2 color-white">{{'accountDashboard.Completed' | translate}}</p>
                                <p class="label3 color-white">{{dashboardData && dashboardData.completed_orders
                                    ?dashboardData.completed_orders:0}}</p>
                            </div>
                        </div>


                        <div class="text-right width-100">
                            <p class="label3 color-white text-uppercase cursor-pointer" (click)="gotoOrder()">
                                {{'ViewAll' |
                                translate}}</p>
                        </div>
                    </div>
                </div>

            </ion-col>

            <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="6" size-xl="6" class="pl-15 pb-30">
                <div class="bg-dark-grey page-grid">
                    <div class="row-css mb-16">
                        <p class="label1 color-white width-100">{{'accountDashboard.Cancelledordershistory' |
                            translate}}
                        </p>
                        <img src="/assets/icon/dashboard-return.svg" class="img-grid" alt="juman" height="100%"
                            width="100%" />
                    </div>
                    <div class="row-css">
                        <div class="width-70 row-css">
                            <div>
                                <p class="label2 color-white">{{'accountDashboard.TotalCancelledorders' | translate}}
                                </p>
                                <p class="label3 color-white">{{dashboardData && dashboardData.cancelled_orders
                                    ?dashboardData.cancelled_orders:0}}</p>
                            </div>
                        </div>

                        <div class="text-right width-100">
                            <p class="label3 color-white text-uppercase cursor-pointer" (click)="gotoCancelOrder()">
                                {{'ViewAll' |
                                translate}}</p>
                        </div>
                    </div>
                </div>

            </ion-col>

            <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="6" size-xl="6" class="pr-15 pb-50">
                <div class="bg-light-green page-grid">
                    <div class="row-css mb-16">
                        <p class="label1 color-white width-100">{{'accountDashboard.Invoice' | translate}}
                        </p>
                        <img src="/assets/icon/dashboard-invoice.svg" class="img-grid" alt="juman" height="100%"
                            width="100%" />
                    </div>
                    <div class="row-css">
                        <div class="width-70 row-css">
                            <div>
                                <p class="label2 color-white">{{'accountDashboard.PurchaseHistory' | translate}}</p>
                                <p class="label3 color-white">
                                    {{commonService.currencySymbol}}{{ dashboardData && dashboardData.total_amount
                                    ?(dashboardData.total_amount|number):0}}</p>
                            </div>
                        </div>

                        <div class="text-right width-100">
                            <p class="label3 color-white text-uppercase cursor-pointer" (click)="gotoOrder()">
                                {{'ViewAll' |
                                translate}}</p>
                        </div>
                    </div>
                </div>

            </ion-col>

            <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="6" size-xl="6" class="pl-15 pb-50">
                <div class="bg-light-orange page-grid">
                    <div class="row-css mb-16">
                        <p class="label1 color-white width-100">{{'accountDashboard.Refund' | translate}}
                        </p>
                        <img src="/assets/icon/dashboard-refund.svg" class="img-grid" alt="juman" height="100%"
                            width="100%" />
                    </div>
                    <div class="row-css">
                        <div class="width-70 row-css">
                            <div>
                                <p class="label2 color-white">{{'accountDashboard.RefundHistory' | translate}}</p>
                                <p class="label3 color-white">{{commonService.currencySymbol}}
                                    {{dashboardData && dashboardData.total_refund_amount
                                    ?(dashboardData.total_refund_amount|number):0}}</p>
                            </div>
                        </div>

                        <div class="text-right width-100">
                            <p class="label3 color-white text-uppercase cursor-pointer" (click)="gotoReturnOrder()">
                                {{'ViewAll' |
                                translate}}</p>
                        </div>
                    </div>
                </div>

            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12" class="pl-15 pb-50">
                <div class="row-css mb-15">
                    <p class="head-title-3 color-app-black width-calc-100">{{'accountDashboard.Mywishlist' |
                        translate}}
                    </p>
                    <div class="row-css" *ngIf="wishlistArray && wishlistArray.length">
                        <p (click)="goToWiishlistPage()"
                            class="label3 color-app-grey2 text-line text-uppercase width-100-px text-right cursor-pointer">
                            {{'ViewAll' |
                            translate}}</p>
                    </div>
                </div>
                <app-horizontal-wishlist *ngIf="wishlistArray && wishlistArray.length"
                    [array]="wishlistArray"></app-horizontal-wishlist>

                <p class="label6 color-app-black ion-text-center no-data " *ngIf="wishlistArray.length == 0">
                    {{'noRecordFound'| translate}}</p>
            </ion-col>
        </ion-row>
        <div>
            <div class="row-css mb-15">
                <p class="head-title-2 color-app-black width-calc-100">{{'accountDashboard.RecentOrders' |
                    translate}}
                </p>
                <div class="row-css" *ngIf="orderArray && orderArray.length">
                    <p (click)="gotoOrder()"
                        class="label3 color-app-grey2 text-line text-uppercase width-100-px text-right cursor-pointer">
                        {{'ViewAll' |
                        translate}}</p>
                </div>
            </div>
            <ion-row class="row-order-bg mb-30 pointer" *ngIf="orderArray && orderArray.length"
                (click)="gotoOrderDetails(orderArray[0].invoice_id , orderArray[0].order_no)">
                <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="7" size-xl="7">
                    <div class="row-css">
                        <img src="/assets/icon/dashboard-order-primary.svg" class="img-grid" alt="juman" height="100%"
                            width="100%" />
                        <div class="ml-16">
                            <p class="label4 color-primary text-uppercase">{{orderArray[0].order_no}}</p>
                            <p class="label5 color-app-black text-uppercase">{{'accountDashboard.OrderDate' |
                                translate}}:
                                {{orderArray[0].date | date:'MMM dd, YYYY'}}</p>
                        </div>
                    </div>
                </ion-col>
                <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="4" size-xl="4">
                    <p class="label4 color-app-grey">{{commonService.currencySymbol}}
                        {{orderArray[0].order_total ? (orderArray[0].order_total | number) :'-'}}</p>
                </ion-col>
                <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="2" size-xl="2" *ngIf="false">

                    <ion-button fill="clear" class="order-status-btn dashboard-status-btn"
                        [ngClass]="{'btn-green': orderArray[0].order_status == 'CONFIRMED' ||orderArray[0].order_status == 'DISPATCHED' || orderArray[0].order_status ==
                    'NOT_YET_DELIVERED' ,'btn-black':orderArray[0].order_status == 'DELIVERED','btn-red':orderArray[0].order_status == 'CANCELLED'}">{{orderArray[0].order_status
                        == 'CANCELLED'
                        ? ('cancelled' | translate) :orderArray[0].order_status == 'DISPATCHED' ?
                        ('dispatched' |
                        translate) :orderArray[0].order_status == 'DELIVERED' ? ('delivered' | translate)
                        :('confirmed' |
                        translate)}}
                    </ion-button>
                </ion-col>
                <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="1" size-xl="1">
                    <div class="right-arrow height-20">
                        <img src="./assets/icon/rightArrow.svg" alt="righta-arrow" alt="juman" height="100%"
                            width="100%"
                            (click)="gotoOrderDetails(orderArray[0].invoice_id , orderArray[0].order_no)" />
                    </div>
                </ion-col>

            </ion-row>

            <app-orders [from]="'dashboard'" [dataArray]="orderArray"></app-orders>


        </div>
    </div>



</div>


<!-- mobile design start-->
<ion-header class="mobile-visibility" mode="md">
    <ion-toolbar mode="md">
        <img src="assets/icon/leftArrow.svg" class="back-icon" alt="Juman" slot="start" (click)="closeModal()"
            [ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
        <ion-title mode="ios">
            <div class="dashboard-header">
                {{'dashboard' | translate}}
            </div>
        </ion-title>
    </ion-toolbar>
</ion-header>


<ion-content class="mobile-visibility">
    <div class="wrapper">
        <div class="top-block">
            <div class="bg-primary page-grid">
                <div class="row-css mb-16 top-section">
                    <div class="block">
                        <p class="label1 color-white width-100">{{'accountDashboard.Orders' | translate}}</p>
                        <img src="/assets/icon/dashboard-order.svg" class="img-grid" alt="juman" height="100%"
                            width="100%" />
                    </div>
                    <div class="navigate">
                        <img src="assets/icon/right_arrow_white.svg" alt="juman" height="100%" width="100%"
                            (click)="gotoOrderMobile()" />
                    </div>
                </div>
                <div class="row-css">
                    <div class="width-100 row-css justify-space-between order-section">
                        <div>
                            <p class="label2 color-white">{{'accountDashboard.Total' | translate}}</p>
                            <p class="label3 color-white">{{dashboardData && dashboardData.total_order
                                ?dashboardData.total_order:0}}</p>
                        </div>
                        <div>
                            <p class="label2 color-white">{{'accountDashboard.Completed' | translate}}</p>
                            <p class="label3 color-white">{{dashboardData && dashboardData.completed_orders
                                ?dashboardData.completed_orders:0}}</p>
                        </div>
                    </div>

                </div>
            </div>
            <div class="bg-dark-grey page-grid">
                <div class="row-css mb-16 top-section">
                    <div class="block">
                        <p class="label1 color-white width-100">{{'accountDashboard.Cancelledorders' |
                            translate}}
                        </p>
                        <img src="/assets/icon/dashboard-return.svg" class="img-grid" alt="juman" height="100%"
                            width="100%" />
                    </div>
                    <div class="navigate">
                        <img src="assets/icon/right_arrow_white.svg" alt="juman" height="100%" width="100%"
                            (click)="gotoCancelOrderMobile()" />

                    </div>
                </div>
                <div class="row-css">
                    <div class="width-100 row-css">
                        <div>
                            <p class="label2 color-white">{{'accountDashboard.TotalCancelledorders' | translate}}
                            </p>
                            <p class="label3 color-white">{{ dashboardData && dashboardData.cancelled_orders
                                ?dashboardData.cancelled_orders:0}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-light-green page-grid">
                <div class="row-css mb-16 top-section">
                    <div class="block">
                        <p class="label1 color-white width-100">{{'accountDashboard.Invoice' | translate}}
                        </p>
                        <img src="/assets/icon/dashboard-invoice.svg" class="img-grid" alt="juman" height="100%"
                            width="100%" />
                    </div>
                    <div class="navigate">
                        <img src="assets/icon/right_arrow_white.svg" alt="juman" height="100%" width="100%"
                            (click)="gotoOrderMobile()" />
                    </div>
                </div>
                <div class="row-css">
                    <div class="width-100 row-css">
                        <div>
                            <p class="label2 color-white">{{'accountDashboard.PurchaseHistory' | translate}}</p>
                            <p class="label3 color-white">{{commonService.currencySymbol}}{{ dashboardData &&
                                dashboardData.total_amount
                                ?(dashboardData.total_amount|number):0}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-light-orange page-grid">
                <div class="row-css mb-16 top-section">
                    <div class="block">
                        <p class="label1 color-white width-100">{{'accountDashboard.Refund' | translate}}
                        </p>
                        <img src="/assets/icon/dashboard-refund.svg" class="img-grid" alt="juman" height="100%"
                            width="100%" />
                    </div>
                    <div class="navigate">
                        <img src="assets/icon/right_arrow_white.svg" alt="juman" height="100%" width="100%"
                            (click)="gotoReturnOrderMobile()" />
                    </div>
                </div>
                <div class="row-css">
                    <div class="width-100 row-css">
                        <div>
                            <p class="label2 color-white">{{'accountDashboard.RefundHistory' | translate}}</p>
                            <p class="label3 color-white">
                                {{commonService.currencySymbol}}{{ dashboardData && dashboardData.total_refund_amount
                                ?(dashboardData.total_refund_amount|number):0}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row-css mb-15 view-all-section">
            <p class="head-title-3 color-app-black width-calc-100">{{'accountDashboard.Mywishlist' |
                translate}}
            </p>
            <div class="row-css" *ngIf="wishlistArray && wishlistArray.length">
                <p (click)="gotoMobileWishlist()"
                    class="label3 color-app-grey2 text-line text-uppercase width-100-px text-right cursor-pointer view-all">
                    {{'ViewAll' |
                    translate}}</p>
            </div>
        </div>
        <div class="wishlist-section">
            <app-horizontal-wishlist *ngIf="wishlistArray && wishlistArray.length"
                [array]="wishlistArray"></app-horizontal-wishlist>

            <p class="label6 color-app-black ion-text-center no-data " *ngIf="wishlistArray.length == 0">
                {{'noRecordFound'| translate}}</p>
        </div>
        <div class="order-section">
            <div class="row-css mb-15 view-all-section">
                <p class="head-title-3 color-app-black width-calc-100">{{'accountDashboard.RecentOrders' |
                    translate}}</p>

                <div class="row-css" *ngIf="orderArray && orderArray.length">
                    <p (click)="gotoOrderMobile()"
                        class="label3 color-app-grey2 text-line text-uppercase width-100-px text-right cursor-pointer view-all">
                        {{'ViewAll' |
                        translate}}</p>
                </div>
            </div>


            <div *ngIf="orderArray && orderArray.length">
                <div class="order-card" *ngFor="let item of orderArray">
                    <div class="row-css inner-order-card"
                        (click)="gotoOrderDetailsFromMobile(item.invoice_id , item.order_no)">
                        <div class="order-section-data">
                            <span class="title">{{'accountDashboard.Order' | translate}} #</span>
                            <span class="data">{{item['order_no']}}</span>
                        </div>
                        <div class="order-section-data">
                            <span class="title">{{'accountDashboard.OrderTotal' | translate}}</span>
                            <span class="data">{{item.order_total ? (item.order_total | number) :'-'}}</span>
                        </div>
                        <div class="order-section-data">
                            <span class="title">{{'accountDashboard.Date' | translate}}</span>
                            <span class="data">{{item['date'] | date:'MMM dd, YYYY'}}</span>
                        </div>
                        <div class="order-section-data">
                            <div class="title">{{"ordersComponent.countOfItems" |
                                translate}}</div>
                            <div class="data">{{item.items_count}}</div>
                        </div>
                        <ion-button fill="clear" *ngIf="false" class="order-status-btn"
                            [ngClass]="{'btn-green': item.order_status == 'CONFIRMED' ||item.order_status == 'DISPATCHED' ||item.order_status ==
                            'NOT_YET_DELIVERED','btn-black':item.order_status == 'DELIVERED','btn-red':item.order_status == 'CANCELLED'}">{{item.order_status
                            == 'CANCELLED'
                            ? ('cancelled' | translate) :item.order_status == 'DISPATCHED' ?
                            ('dispatched' |
                            translate) :item.order_status == 'DELIVERED' ? ('delivered' | translate)
                            :('confirmed' |
                            translate)}}
                        </ion-button>
                    </div>
                    <div class="right-arrow">
                        <img src="./assets/icon/rightArrow.svg" alt="juman" height="100%" width="100%"
                            (click)="gotoOrderDetailsFromMobile(item.invoice_id , item.order_no)" />
                    </div>
                </div>
            </div>


            <p class="label6 color-app-black ion-text-center no-data " *ngIf="orderArray.length == 0">
                {{'noRecordFound'| translate}}</p>
        </div>
    </div>
</ion-content>
<!-- end -->