import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { URLS } from '../../_config/api.config';
import { CancelProductComponent } from '../../components/cancel-product/cancel-product.component';
import { ApiService } from '../../services/api-data.service';
import { CartService } from '../../services/cart.service';
import { CommonService } from '../../services/common.service';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';
import { TranslateService } from "@ngx-translate/core";
@Component({
    selector: 'app-order-details-mobile',
    templateUrl: './order-details-mobile.component.html',
    styleUrls: ['./order-details-mobile.component.scss'],
})
export class OrderDetailsMobileComponent implements OnInit {
    invoiceId: any;
    isLoading: boolean = false;
    private subscription: Subscription = new Subscription();
    orderData = {
        order_no: '-',
        order_date: '',
        order_status: 'CONFIRMED',
        shipping_address: '',
        sub_total: '',
        shipping_charges: '',
        shipping_charges_without_vat: '',
        shipping_vat_charges: '',
        total_price: '',
        vat: '',
        vat_percent: '15',
        discount_price: '',
        discount_code: '',
        mobile_no: '',
        city_en: '',
        city_ar: '',
        region_ar: '',
        region_en: '',
        country_ar: '',
        country_en: '',
        zipcode: '',
        is_cancellation_allowed: '',
        is_return_allowed: '',
    };
    supplierData = [];
    isReordering = false;

    constructor(
        private modalCtrl: ModalController,
        private dataService: ApiService,
        private toastService: ToastService,
        public commonService: CommonService,
        private modalService: ModalService,
        private cartService: CartService,
        private router: Router,
        private navCtrl: NavController,
        private route: ActivatedRoute,
        public translate: TranslateService

    ) {
        this.route.queryParams
            .subscribe(params => {
                this.invoiceId = params['invoiceId'] ? params['invoiceId'] : null;
            });
    }

    ngOnInit() {
        this.getOrderDetails();
    }

    getOrderDetails() {
        if (!this.invoiceId) return;
        const params = {
            invoice_id: this.invoiceId
        }
        this.isLoading = true;
        this.subscription.add(
            this.dataService.get(URLS.orderDetails, params).subscribe(data => {
                if (data && data['code'] == 200) {
                    if (data['data']) {
                        this.orderData = {
                            order_no: data['data']['order_no'] ? data['data']['order_no'] : '-',
                            order_date: data['data']['order_date'] ? data['data']['order_date'] : '',
                            order_status: data['data']['order_status'] ? data['data']['order_status'] : 'CONFIRMED',
                            shipping_address: data['data']['shipping_address'] ? data['data']['shipping_address'] : '',
                            sub_total: data['data']['grand_total'] && data['data']['grand_total']['items_sub_total'] ? data['data']['grand_total']['items_sub_total'] : '',
                            shipping_charges: data['data']['grand_total'] && data['data']['grand_total']['shipping_charges'] ? data['data']['grand_total']['shipping_charges'] : 0,
                            shipping_charges_without_vat: data['data']['grand_total'] && data['data']['grand_total']['shipping_charges_without_vat'] ? data['data']['grand_total']['shipping_charges_without_vat'] : '',
                            shipping_vat_charges: data['data']['grand_total'] && data['data']['grand_total']['shipping_vat_charges'] ? data['data']['grand_total']['shipping_vat_charges'] : '',
                            total_price: data['data']['grand_total'] && data['data']['grand_total']['total_price'] ? data['data']['grand_total']['total_price'] : '',
                            vat: data['data']['grand_total'] && data['data']['grand_total']['vat'] ? data['data']['grand_total']['vat'] : '',
                            vat_percent: data['data']['grand_total'] && data['data']['grand_total']['vat_percentage'] ? data['data']['grand_total']['vat_percentage'] : '15',
                            discount_price: data['data']['grand_total'] && data['data']['grand_total']['total_discount'] ? data['data']['grand_total']['total_discount'] : '',
                            discount_code: data['data']['grand_total'] && data['data']['grand_total']['coupon_code'] ? data['data']['grand_total']['coupon_code'] : '',
                            mobile_no: data['data']['mobile_no'] ? data['data']['mobile_no'] : '',
                            city_en: data['data']['city_en'] ? data['data']['city_en'] : '',
                            city_ar: data['data']['city_ar'] ? data['data']['city_ar'] : '',
                            region_ar: data['data']['region_ar'] ? data['data']['region_ar'] : '',
                            region_en: data['data']['region_en'] ? data['data']['region_en'] : '',
                            country_ar: data['data']['country_ar'] ? data['data']['country_ar'] : '',
                            country_en: data['data']['country_en'] ? data['data']['country_en'] : '',
                            zipcode: data['data']['zipcode'] ? data['data']['zipcode'] : '',
                            is_cancellation_allowed: data['data']['is_cancellation_allowed'] ? data['data']['is_cancellation_allowed'] : null,
                            is_return_allowed: data['data']['is_return_allowed'] ? data['data']['is_return_allowed'] : null,
                        }
                        this.supplierData = data['data']['result'] && data['data']['result'].length ? data['data']['result'] : [];
                    }
                    this.isLoading = false;
                } else {
                    this.isLoading = false;
                    this.goBack();
                }
            }, error => {
                this.isLoading = false;
                this.goBack();
            })
        )
    }

    goBack() {
        this.navCtrl.back();
    }

    cancelProduct(data, returnProduct?) {
        let options: any = {
            cssClass: 'cancel-product-mobile-popup',
            backdropDismiss: false,
            componentProps: { orderData: data, rows: 10, mobileClasses: true, invoiceId: this.invoiceId, isCancel: returnProduct ? false : true },
        };
        options['component'] = CancelProductComponent;
        let modal = this.modalService.openModal(options);
        modal.then(data => {
            if (data && data['data'] && data['data']['data']) {
                this.getOrderDetails();
            }
        })
    }


    reorder() {
        if (this.isReordering) {
            return
        }
        let promiseArray = [];
        this.supplierData.forEach(element => {
            if (element.products && element.products.length) {
                element.products.forEach(product => {
                    this.isReordering = true;
                    promiseArray.push(
                        this.cartService.addIntoCart(product, product.total_quantity, product.is_sample_product, true)
                    )
                });
            }
        });
        Promise.all(promiseArray).then(data => {
            this.isReordering = false;
            this.router.navigate([''+this.translate.currentLang+'/cart'])
        }, err => {
            this.isReordering = false;
            this.router.navigate([''+this.translate.currentLang+'/cart'])

        })
    }

    orderStatus(orderData, productlevel?) {
        let status = orderData.order_status ? orderData.order_status : orderData.shipping_status;
        switch (status) {
            case 'NOT_YET_DELIVERED':
            case 'DISPATCHED':
            case 'CONFIRMED':
                if (productlevel) {
                    return 'btn-green product-status-btn';
                } else {
                    return 'btn-green';
                }
            case 'DELIVERED':
                if (productlevel) {
                    return 'btn-black product-status-btn';
                } else {
                    return 'btn-black';
                }
            case 'CANCELLED':
                if (productlevel) {
                    return 'btn-red product-status-btn';
                } else {
                    return 'btn-red';
                }
            default:
                if (productlevel) {
                    return 'btn-green product-status-btn';
                } else {
                    return 'btn-green';
                }
        }
    }

    goToProductDetail(data) {
        let currentLang = this.translate.currentLang; 
        let prod_id = data.main_product_ref_id;
        let prodnameUrl = this.commonService.trimUrl(data.product_name ? data.product_name: data.product_name_en);
        let catnameUrl = this.commonService.trimUrl(data.category_name_en ? data.category_name_en : data.category_name);
        if (data.product_id) {
            this.navCtrl.navigateRoot('/'+currentLang +"/"+catnameUrl+"/"+ prodnameUrl+"/"+ prod_id,
                {
                    animated: false,
                    queryParams: {
                        "sub_product_id": data.product_id
                    }
                });
        } else {
            this.navCtrl.navigateRoot('/'+currentLang +"/"+catnameUrl+"/"+ prodnameUrl+"/"+ prod_id,
                {
                    animated: false,
                });
        }
    }
}
