import { Location } from "@angular/common";
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { CommonService } from "../services/common.service";
import { TranslateService } from "@ngx-translate/core";
@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private router: Router,
        private location: Location,
        private commonService: CommonService,
        private cookieService: CookieService,
        public translate: TranslateService) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let routeUrl: any;
        if (next.url && next.url.length > 0) {
            routeUrl = next.url[0].path;
        }
        let token = this.commonService.localStorageGet('accessToken');
        // not logged in so redirect to login page with the return url
        if (!token) {
            this.router.navigate(['/'+this.translate.currentLang+'/home']);
            return false;
        }
        return true;


    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        return this.canActivate(childRoute, state)
    }


}
