<div class="home-page app-header account-details-header">
    <app-header></app-header>
</div>

<ion-content>
    <div class="account-details-page" *ngIf="accounDetailsPage">
        <div class="container-row set-padding">
            <div class="side-menu-section mr-40" *ngIf="accounDetailsPage && !hideMenu && !hideShowMenu">
                <app-my-account-menu></app-my-account-menu>
            </div>
            <div class="main-content" [ngClass]="hideMenu ||  hideShowMenu? 'full-width':''">
                <!-- <ion-router-outlet [animated]="false"></ion-router-outlet> -->
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
    <div class="account-details-page" *ngIf="orderDetailsPage">
        <div class="container-row set-padding">
            <app-order-details></app-order-details>
        </div>
    </div>
    <div class="wrapper-block home-page">
        <div class="app-footer">
            <app-footer></app-footer>
        </div>
    </div>
</ion-content>