import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ReturnOrderMobileFilterComponent } from 'src/app/components/return-order-mobile-filter/return-order-mobile-filter.component';
import { ModalService } from 'src/app/services/modal.service';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../services/api-data.service';
import { ToastService } from '../../services/toast.service';
import { CommonService } from './../../services/common.service';
import { GetPlatformService } from './../../services/get-platform.service';

@Component({
	selector: 'app-my-wishlist',
	templateUrl: './my-wishlist.page.html',
	styleUrls: ['./my-wishlist.page.scss'],
})
export class MyWishlistPage implements OnInit {
	selectedSubClass: any = 0;
	inerfaceOption = { size: 'cover' };
	private subscription: Subscription = new Subscription();
	public subClassArray: any = [];
	public subClassId: any;
	public enableNext = false;
	public moreWishlistData = [];
	public isLoadMoreLoading = false;
	public myWishlist: any = [];
	public page = 1;
	public size = 8;
	public isLoading = false;
	fromDate: any;
	toDate: any;
	selectedCategory = 0;
	catagoryListData: any = [];
	showFilter = false;
	statusList;
	isCancelPage = false;
	filterObj = {};
	dateRange: FormGroup;
	isNoData = true;
	maxFromDate: any;
	maxDate: any;
	minToDate: any;
	todaysDate = new Date();
	public optionSelect = {
		cssClass: 'location-popover'
	}
	currentLang;
	constructor(private dataService: ApiService,
		private getPlatformService: GetPlatformService,
		public commonService: CommonService,
		private toastService: ToastService,
		private navCtrl: NavController,
		private alertController: AlertController,
		private translate: TranslateService,
		private modalService: ModalService,
		private datePipe: DatePipe,
		private fb: FormBuilder) {
		this.currentLang = this.translate.currentLang;
	}

	ngOnInit() {
		this.dateRange = this.fb.group({
			from: new FormControl(null),
			to: new FormControl(null),
		})
		let date = new Date();
		this.maxFromDate = date.setDate(this.todaysDate.getDate() - 1);
		this.maxDate = this.datePipe.transform(this.todaysDate, 'YYYY-MM-dd');
		this.maxFromDate = this.datePipe.transform(this.maxFromDate, 'YYYY-MM-dd');
		this.getWishList();
		this.getCatagoryList();
	}

	async subClassChanged(event) {
		this.subClassId = event.target.value
		this.page = 1;
		this.size = 8;
		this.enableNext = false;
		this.myWishlist = [];
		if (this.subClassId) this.getWishList();
	}

	async categoyChanged(event) {
		this.page = 1;
		this.size = 8;
		this.enableNext = false;
		this.myWishlist = [];
		this.selectedSubClass = null;
		this.subClassId = null;
		this.getSubClassListing();
		if (this.selectedCategory) this.getWishList();

	}

	async getWishList() {
		const params = {
			page: this.page,
			size: this.size,
			...this.filterObj
		}
		if (this.subClassId) {
			params['sub_class_id'] = this.subClassId;
		}
		if (this.selectedCategory) {
			params['category_id'] = this.selectedCategory;
		}
		if (!this.enableNext) {
			this.isLoading = true;
		}
		if (!this.isLoadMoreLoading) {
			this.myWishlist = [];
		}
		this.subscription.add(
			this.dataService.get(URLS.getWishList, params).subscribe(data => {
				if (data && data['code'] == 200) {
					let wishListData = [];
					wishListData = data['data']['result'];
					if (wishListData.length < 1 && !this.subClassId && !this.selectedCategory && !this.filterObj['from_date'] && !this.filterObj['to_date']) this.isNoData = false; else this.isNoData = true;
					this.enableNext = data['data']['enable_next'];
					wishListData.forEach(element => {
						let obj = {
							product_description: element.product_name_en ? element.product_name_en : "",
							product_name: element.product_name_en ? element.product_name_en : "",
							product_name_ar: element.product_name_ar ? element.product_name_ar : "",
							category_name_en: element.category_name_en ? element.category_name_en : "",
							category_name_ar: element.category_name_ar ? element.category_name_ar : "",
							brand_name: element.brand_name_en ? element.brand_name_en : "",
							product_currency: "SR",
							product_price: element.price_per_unit ? element.price_per_unit : "",
							price_per_unit: element.price_per_unit ? element.price_per_unit : "",
							final_price: element.final_price ? element.final_price : "",
							product_unit: this.commonService.domDirectionLTR ? element.unit_name_en : element.unit_name_ar,
							product_unit_arb: element.unit_name_ar,
							product_id: element.product_id,
							take_action: true,
							is_wishlist_product: element.is_wishlist_product ? element.is_wishlist_product : false,
							main_product_ref_id: element.main_product_ref_id,
							wishlist_id: element.wishlist_id ? element.wishlist_id : null,
							sold_quantity: element.sold_quantity ? element.sold_quantity : null,
							is_discount_applied: element.is_discount_applied ? element.is_discount_applied : null,
							is_tax_free_product: element.is_tax_free_product ? element.is_tax_free_product : null,
							available_quantity: element.available_quantity ? element.available_quantity : null,
							is_sold_out: element.is_sold_out ? element.is_sold_out : null,
							product_sku: element.product_sku ? element.product_sku : null,
							total_quantity: element.total_quantity ? element.total_quantity : null,
							discount_type: element.discount_type ? element.discount_type : null,
							discount_value: element.discount_value ? element.discount_value : null,
							sub_product_id: element.sub_product_id ? element.sub_product_id : null,
							product_container_img: "assets/icon/paint-container.svg"
						}

						if (this.getPlatformService.isDesktop) {
							obj['product_img'] = element.web_image_url ? element.web_image_url : "assets/images/no-img1.png";
						} else {
							obj['product_img'] = element.mobile_image_url ? element.mobile_image_url : "assets/images/no-img1.png";
						}
						this.myWishlist.push(obj);
					});
					this.isLoadMoreLoading = false;
					this.isLoading = false;
					this.isLoadMoreLoading = false;
				} else {
					this.isLoading = false;
					this.isLoadMoreLoading = false;
				}
			}, error => {
				this.isLoading = false;
				this.isLoadMoreLoading = false;
			})
		)
	}

	loadMoreData(event) {
		if (this.enableNext) {
			this.page += 1;
			this.isLoadMoreLoading = true;
			this.getWishList();
		}
	}

	getSubClassListing() {
		if (!this.selectedCategory) return;
		let param = {
			'category_id': this.selectedCategory
		}
		this.subscription.add(
			this.dataService.get(URLS.getWishListSubClass, param).subscribe(data => {
				if (data && data['code'] == 200) {
					this.subClassArray = data['data']['result'][0]['children'];
				}
			}, error => {
			})
		)
	}

	goToProductDetail(data) {
		let prod_id = data.product_id;
		let prodnameUrl = this.commonService.trimUrl(data.product_name ? data.product_name : data.product_name_en);
		let catnameUrl = this.commonService.trimUrl(data.category_name_en ? data.category_name_en : data.category_name);
		if (data.sub_product_id) {
			this.navCtrl.navigateForward('/' + this.currentLang + "/" + catnameUrl + "/" + prodnameUrl + "/" + prod_id,
				{
					animated: false, "state": {
						"breadcrumb": data.product_description,
						"url": "/" + catnameUrl + "/" + prodnameUrl + "/" + prod_id,
						"sub_product_id": data.sub_product_id
					}
				});
		}
		else {
			this.navCtrl.navigateForward('/' + this.currentLang + "/" + catnameUrl + "/" + prodnameUrl + "/" + prod_id, { animated: false, "state": { "breadcrumb": data.product_description, "url": "/" + catnameUrl + "/" + prodnameUrl + "/" + prod_id } });
		}
	}

	removeFromWishList(data, index) {
		const params = {
			product_id: data.product_id,
			wishlist_id: data.wishlist_id,
		}
		this.subscription.add(
			this.dataService.post(URLS.removeWishList, params).subscribe(data => {
				if (data && data['code'] == 200) {
					this.myWishlist.splice(index, 1);
					if (this.myWishlist.length < 1 && !this.subClassId && !this.selectedCategory && !this.filterObj['from_date'] && !this.filterObj['to_date']) this.isNoData = false; else this.isNoData = true;
				} else {
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

	async presentAlert(data, index) {
		const alert = await this.alertController.create({
			header: this.commonService.domDirectionLTR ? 'Are you sure?' : 'هل أنت متأكد؟',
			message: this.commonService.domDirectionLTR ? "You want to remove from wishlist" : "تريد إزالة شكل قائمة الرغبات",
			buttons: [
				{
					text: this.translate.instant("no"),
					role: 'cancel',
					handler: () => {
					},
				},
				{
					text: this.translate.instant("yes"),
					role: 'confirm',
					handler: () => {
						this.removeFromWishList(data, index)
					},
				},
			],
		});

		await alert.present();
		const { role } = await alert.onDidDismiss();
	}


	async subProductStock(product_id) {
		const params = {
			"product_id": product_id,
		}
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.get(URLS.productAvailability, params).subscribe(data => {
					if (data && data['code'] == 200) {
						resolve(data['data']);

					} else {
						reject();
					}
				}, error => {
					reject(error);
				})

			)
		});

	}

	//catagory filter list
	getCatagoryList() {
		this.subscription.add(
			this.dataService.get(URLS.getWishListSubClass).subscribe(data => {
				if (data['code'] == 200) {
					this.catagoryListData = data['data']['result'];
				}
				else {
					this.catagoryListData = [];
				}
			}, error => {
				this.catagoryListData = [];
			})
		)
	}

	openFilterByModal() {
		setTimeout(() => {
			let options: any = {
				id: "filterByModal",
				componentProps: { statusList: this.statusList },
			};
			options['component'] = ReturnOrderMobileFilterComponent;
			this.modalService.openModal(options)
		}, 300);
	}

	goBack() { }


	async clearFilter() {
		this.fromDate = null;
		this.toDate = null;
		this.filterObj = {};
		this.selectedCategory = null;
		this.selectedSubClass = null;
		this.subClassId = null;
		this.myWishlist = [];
		this.dateRange.reset();
		setTimeout(() => {
			this.getWishList();
		}, 200);
	}

	onDateChange(datetime, type) {
		datetime.cancel(true);
		if (this.fromDate && this.toDate) {
			this.filterObj = {}
			if ((this.fromDate && !this.toDate) || (!this.fromDate && this.toDate)) {
				if (!this.fromDate) {
					let msg = this.commonService.domDirectionLTR ? "Please select 'From Date'." : 'الرجاء تحديد "من التاريخ".';
					this.toastService.displayToast(msg, null, 'danger');
				}
				if (!this.toDate) {
					let msg = this.commonService.domDirectionLTR ? "Please select 'To Date'." : 'الرجاء تحديد "حتى الآن".';
					this.toastService.displayToast(msg, null, 'danger');
				}
				return
			}
			if (this.fromDate && this.toDate) {
				this.filterObj['from_date'] = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
				this.filterObj['to_date'] = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');
			}
			if (this.filterObj['from_date'] && this.filterObj['to_date']) {
				if (this.filterObj['from_date'] > this.filterObj['to_date']) {
					let msg = this.commonService.domDirectionLTR ? "'From Date' cannot be greater than 'To Date'." : 'لا يمكن أن يكون "من تاريخ" أكبر من "إلى تاريخ".';
					this.toastService.displayToast(msg, null, 'danger');
					return
				}
			}
			let isValid = false;
			if (isValid && this.filterObj['from_date'] && this.filterObj['to_date'] && (this.filterObj['from_date'] == this.filterObj['to_date'])) {
				let msg = this.commonService.domDirectionLTR ? "'From Date' and 'To Date' cannot be same." : 'لا يمكن أن يتطابق "من تاريخ" و "إلى تاريخ".';
				this.toastService.displayToast(msg, null, 'danger');
				return
			}
			this.myWishlist = [];
			this.getWishList()
		}
	}

	openFilters() {
		this.showFilter = !this.showFilter;
	}

	search() { }


}
