import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { GetPlatformService } from '../../services/get-platform.service';
import { ToastService } from '../../services/toast.service';
import { WishlistService } from '../../services/wishlist.service';
import { TranslateService } from "@ngx-translate/core";
@Component({
    selector: 'app-search',
    templateUrl: './search.page.html',
    styleUrls: ['./search.page.scss'],
})
export class SearchPage implements OnInit, OnDestroy {

    private subscription: Subscription = new Subscription();
    public results_count = 0;
    public search_type: any;
    public searchData: any = [];
    public searchTypeData: any = [];
    public searchInput: any;
    public isLoading = false;
    public enableNext = false;
    public enableNextCategory = false;
    public isLoggedIn: boolean = false;
    public isLoadMoreLoading: boolean = false;
    public page: number = 1;
    public size: number = 10;
    public categoryPage: number = 1;
    public categorySize: number = 10;
    public gridArray = [];
    public noRecordFound: boolean = false;
    public wishlistData: any;
    public searchDataType: any;

    constructor(
        private dataService: ApiService,
        private route: ActivatedRoute,
        private toastService: ToastService,
        private router: Router,
        public commonService: CommonService,
        private navCtrl: NavController,
        public wishlistService: WishlistService,
        public getPlatformService: GetPlatformService,
        public translate: TranslateService) { }

    ngOnInit() {
        this.isLoggedIn = this.commonService.localStorageGet('accessToken') ? true : false;
        this.page = 1;
        this.size = 10;
        this.gridArray = [];
        if(this.router.getCurrentNavigation().extras.state){
            let state = this.router.getCurrentNavigation().extras.state;
            this.searchInput = state.searchInput
            this.commonService.localStorageSet("searchData", this.searchInput)
        } else {
           this.searchInput = this.commonService.localStorageGet("searchData")
        }
        this.getSearchTypes();
    }

    ionViewWillEnter() {
        this.isLoggedIn = this.commonService.localStorageGet('accessToken') ? true : false;
        this.page = 1;
        this.size = 10;
        this.gridArray = [];
        this.getSearchTypes();
    }

    async goToProductDetail(data) {
        let prod_id = data.product_id;
        let prodnameUrl = this.commonService.trimUrl(data.product_name);
        let catnameUrl = this.commonService.trimUrl(data.category_name_en ? data.category_name_en : data.category_name);
        await this.searchProductEntry(data.item_id);
        this.navCtrl.navigateForward('/'+this.translate.currentLang+'/' + catnameUrl + '/' + prodnameUrl + '/' + prod_id ,
            { 
                animated: false,
                "state": { 
                    "breadcrumb": this.commonService.domDirectionLTR ? data.product_description : data.product_description_ar,
                    "url": '/' + catnameUrl + '/' + prodnameUrl + '/' + prod_id ,
                } 
            }
        );
    }

    getSearchResults(searchData: any, isScrollCalled?: boolean) {
        return new Promise((resolve, reject) => {
            let params = {
                page: this.page,
                limit: this.size,
                search: this.searchInput ? this.searchInput.trim() : null,
                search_type_id: searchData.search_type_id
            }
            if (searchData.search_type_id == 2) {
                params['page'] = this.categoryPage
                params['limit'] = this.categorySize
            }
            this.subscription.add(
                this.dataService.get(URLS.getSearchResults, params).subscribe(
                    data => {
                        let dataArray: any;
                        if (data['code'] == 200) {
                            dataArray = data['data'];
                            if (searchData.search_type_id == 1) {
                                this.enableNext = dataArray.enable_next;
                            }
                            if (searchData.search_type_id == 2) {
                                this.enableNextCategory = dataArray.enable_next;
                            }
                            this.search_type = this.searchInput?.trim();
                            this.isLoadMoreLoading = false;

                            searchData.enable_next = dataArray.enable_next;
                            if (!isScrollCalled) {
                                this.results_count = 0;
                            }

                            if (searchData.search_type_en == 'PRODUCTS' && dataArray) {
                                if (!isScrollCalled) {
                                    searchData.total_count = dataArray.total_count ? dataArray.total_count : 0;
                                    searchData.result = dataArray.result;
                                    this.searchTypeData.forEach(element => {
                                        if (element.search_type_id == searchData.search_type_id) {
                                            element.total_count = dataArray.total_count ? dataArray.total_count : 0;
                                            element.result = dataArray.result;
                                            element.enable_next = dataArray.enable_next;
                                        }
                                        this.results_count = this.results_count + parseInt(element.total_count ? element.total_count : 0);

                                    });
                                    if (dataArray.result && dataArray.result.length) {
                                        dataArray.result.forEach(element2 => {
                                            element2.product_description = element2.item_name_en ? element2.item_name_en : "";
                                            element2.product_description_ar = element2.item_name_ar ? element2.item_name_ar : "";
                                            element2.product_name = element2.item_name_en ? element2.item_name_en : "";
                                            element2.product_name_ar = element2.item_name_ar ? element2.item_name_ar : "";
                                            element2.brand_name = element2.reference_name_en ? element2.reference_name_en : "";
                                            element2.brand_name_ar = element2.reference_name_ar ? element2.reference_name_ar : "";
                                            element2.product_price = element2.item_price ? element2.item_price : "0";
                                            element2.price_per_unit = element2.item_price ? element2.item_price : "0";
                                            element2.final_price = element2.final_price ? element2.final_price : "0";
                                            element2.product_unit = this.commonService.domDirectionLTR ? element2.item_unit_en : element2.item_unit_ar;
                                            element2.product_id = element2.item_id ? element2.item_id : null;
                                            element2.item_id = element2.item_id ? element2.item_id : null;
                                            element2.discount_value = element2.discount_value ? element2.discount_value : "";
                                            element2.discount_type = element2.discount_type ? element2.discount_type : "";
                                            element2.wishlist_id = element2.wishlist_id ? element2.wishlist_id : null;
                                            element2.sold_quantity = element2.sold_quantity ? element2.sold_quantity : null;
                                            element2.is_discount_applied = element2.is_discount_applied ? element2.is_discount_applied : null;
                                            element2.is_tax_free_product = element2.is_tax_free_product ? element2.is_tax_free_product : null;
                                            element2.available_quantity = element2.available_quantity ? element2.available_quantity : null;
                                            element2.is_sold_out = element2.is_sold_out ? element2.is_sold_out : null;
                                            element2.product_sku = element2.product_sku ? element2.product_sku : null;
                                            element2.total_quantity = element2.total_quantity ? element2.total_quantity : null;
                                            element2.is_wishlist_product = element2.is_wishlist_product ? element2.is_wishlist_product : null;

                                            if (this.getPlatformService.isDesktop) {
                                                element2['product_img'] = element2.item_image_web ? element2.item_image_web : "assets/images/no-img1.png";
                                            } else {
                                                element2['product_img'] = element2.item_image_mobile ? element2.item_image_mobile : "assets/images/no-img1.png";
                                            }
                                            this.gridArray.push(element2);
                                        })
                                    }

                                }
                                if (isScrollCalled) {
                                    if (dataArray.result && dataArray.result.length) {
                                        dataArray.result.forEach(element2 => {
                                            element2.product_description = element2.item_name_en ? element2.item_name_en : "";
                                            element2.product_description_ar = element2.item_name_ar ? element2.item_name_ar : "";
                                            element2.product_name = element2.item_name_en ? element2.item_name_en : "";
                                            element2.product_name_ar = element2.item_name_ar ? element2.item_name_ar : "";
                                            element2.brand_name = element2.reference_name_en ? element2.reference_name_en : "";
                                            element2.brand_name_ar = element2.reference_name_ar ? element2.reference_name_ar : "";
                                            element2.product_price = element2.item_price ? element2.item_price : "0";
                                            element2.price_per_unit = element2.item_price ? element2.item_price : "0";
                                            element2.final_price = element2.final_price ? element2.final_price : "0";
                                            element2.product_unit = this.commonService.domDirectionLTR ? element2.item_unit_en : element2.item_unit_ar;
                                            element2.product_id = element2.item_id ? element2.item_id : null;
                                            element2.discount_value = element2.discount_value ? element2.discount_value : "";
                                            element2.discount_type = element2.discount_type ? element2.discount_type : "";
                                            element2.wishlist_id = element2.wishlist_id ? element2.wishlist_id : null;
                                            element2.sold_quantity = element2.sold_quantity ? element2.sold_quantity : null;
                                            element2.is_discount_applied = element2.is_discount_applied ? element2.is_discount_applied : null;
                                            element2.is_tax_free_product = element2.is_tax_free_product ? element2.is_tax_free_product : null;
                                            element2.available_quantity = element2.available_quantity ? element2.available_quantity : null;
                                            element2.is_sold_out = element2.is_sold_out ? element2.is_sold_out : null;
                                            element2.product_sku = element2.product_sku ? element2.product_sku : null;
                                            element2.total_quantity = element2.total_quantity ? element2.total_quantity : null;
                                            element2.is_wishlist_product = element2.is_wishlist_product ? element2.is_wishlist_product : null;
                                            if (this.getPlatformService.isDesktop) {
                                                element2['product_img'] = element2.item_image_web ? element2.item_image_web : "assets/images/no-img1.png";
                                            } else {
                                                element2['product_img'] = element2.item_image_mobile ? element2.item_image_mobile : "assets/images/no-img1.png";
                                            }

                                            this.gridArray.push(element2);
                                        });
                                    }
                                }
                            }
                            if (searchData.search_type_en == "CATEGORIES" && dataArray) {
                                if (!isScrollCalled) {
                                    searchData.total_count = dataArray.total_count ? dataArray.total_count : 0;
                                    searchData.result = dataArray.result;
                                    this.searchTypeData.forEach(element => {
                                        if (element.search_type_id == searchData.search_type_id) {
                                            element.total_count = dataArray.total_count ? dataArray.total_count : 0;
                                            element.result = dataArray.result;
                                            element.enable_next = dataArray.enable_next;
                                        }
                                        this.results_count = this.results_count + parseInt(element.total_count ? element.total_count : 0);

                                    });
                                }
                                if (isScrollCalled) {
                                    this.searchTypeData.forEach(element => {
                                        if (element.search_type_en == "CATEGORIES") {
                                            element.result = [...element.result, ...dataArray.result]
                                        }
                                    });
                                }
                            }
                            resolve(data);
                        } else {
                            reject();
                            this.isLoadMoreLoading = false;
                        }
                    }, error => {
                        reject();
                        this.isLoadMoreLoading = false;
                        let dataArray: any;
                    })
            )
        })
    }

    getSearchTypes(isScrollCalled?: boolean) {
        if (this.isLoading) {
            return
        }
        if (!isScrollCalled) {
            this.isLoading = true;
        }
        this.subscription.add(
            this.dataService.get(URLS.getSearchTypes).subscribe(data => {
                if (data['code'] == 200) {
                    let promiseArray = [];
                    this.searchTypeData = data['data'];
                    this.searchTypeData.forEach(element => {
                        promiseArray.push(this.getSearchResults(element))
                        this.searchDataType = element;
                    });
                    Promise.all(promiseArray).then(data => {
                        this.isLoading = false;
                    }, error => {
                        this.isLoading = false;
                    });
                } else {
                    this.isLoading = false;
                }
            }, error => {
                this.isLoading = false;
            })
        )
    }

    loadData(event) {
        if (this.getPlatformService.isDesktop && this.enableNext) {
            this.page += 1;
            this.isLoadMoreLoading = true;
            this.getSearchResults(this.searchDataType, true);
        } else {
            setTimeout(() => {
                if (this.enableNext) {
                    this.page += 1;
                    this.getSearchResults(this.searchDataType, true);
                    event.target.complete();
                }
            }, 500);
        }
    }

    loadCategoryData(searchData) {
        this.categoryPage += 1;
        this.getSearchResults(searchData, true);
    }


    ngOnDestroy() {
        this.searchInput = null;
    }

    ionViewWillLeave() {
        let currentURL = this.router.url;
        if (!currentURL.includes('search/product') && !currentURL.includes('home/search')) {
            this.searchInput = null;
            this.commonService.setEvent('emptySerach', null);
        }

    }

    async goToProductListing(data) {
        let itemNameUrl = this.commonService.trimUrl(data.item_name_en ? data.item_name_en : data.item_name);
        this.navCtrl.navigateForward('/'+this.translate.currentLang+'/product-listing/' + itemNameUrl, {
            animated: false, "state": {
                "breadcrumb": itemNameUrl.replace(/-/g, ' '),
                "url": '/product-listing/' + itemNameUrl,
                id: data.item_id,
                "ar_label": data.item_name_ar

            }
        });
    }
    getdata(value) {
        return JSON.stringify(value);
    }

    searchProductEntry(product_id) {
        let params = { product_id: product_id }
        this.subscription.add(
            this.dataService.post(URLS.searchedProductAdd, params).subscribe(data => {
                if (data['code'] == 200) {
                    this.isLoading = false;

                } else {
                    this.isLoading = false;
                    this.toastService.displayToast(data['message'], 'danger');
                }
            }, error => {
                this.isLoading = false;
                this.toastService.displayToast(error['message'], null, 'danger');
            })
        )
    }
}
