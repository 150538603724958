<div class="register-block">
	<!-- <img class="graph graph-one" src="assets/icon/graph_1.svg" alt="Jazeera">
	<img class="graph graph-two" src="assets/icon/graph_2.svg" alt="Jazeera"> -->
	<div class="heading">{{"registerOnComponent.title" | translate}}</div>
	<span class="text">{{"registerOnComponent.text" | translate}}</span>
	<div class="custom-button">
		<app-common-button (click)="openRegisterModal()" color="white" background="var(--ion-color-blue-100)"
			type="solid" title="{{ 'registerOnComponent.action' | translate }}" [isIcon]="false"
			[specificPaddingStart]="'16px'" [isPrimaryBtn]="true" [isSecondryBtn]="false" [buttonDisabled]="false"
			[specificPaddingEnd]="'16px'" [isHideInMobile]="true" [specificWidth]="'fit-content'" [borderRadius]="'4px'"
			[specificHeight]="'54px'" [borderWidth]="'1px'" [borderStyle]="'solid'"
			borderColor="var(--ion-color-blue-100)"></app-common-button>
	</div>
</div>