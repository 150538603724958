<ion-header class="mobile-visibility app-header" [translucent]="true">
	<ion-toolbar mode="md">
		<img src="assets/icon/leftArrow.svg" class="back-icon back-img"
			[ngClass]="commonService.domDirectionLTR ? '':'rotate-arrow'" alt="Jazeera" slot="start" (click)="goBack()">

		<ion-title mode="ios">
			<div class="category-header">
				{{'categoriesComponent.title' | translate}}
			</div>
		</ion-title>
	</ion-toolbar>

	<div class="main-label-mobile">

		<div class="sort-by-mobile">
			<div class="dropdown-icon">
				<img src="assets/icon/filter-mobile-icon.svg" alt="Jazeera">
			</div>
			<div class="filter-icon">
				<ion-icon src="assets/icon/relevancy_down_arrow.svg" alt="Jazeera"></ion-icon>
			</div>
			<div class="dropdown-container">
				<div class="sort-by-mobile-header">
					{{"filterBy" | translate}}
				</div>
				<ion-list>
					<ion-item class="sort-by-item" lines="none" *ngIf="groupData.length > 0">
						<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
							[(ngModel)]="filterCount" class="pointer-none" [multiple]="true"
							[interfaceOptions]="{ 'header': ('filterBy' | translate)}"
							placeholder="{{'none'|translate}}" (ngModelChange)="selectGroup($event)">
							<ion-select-option *ngFor="let data of groupData" [value]="data.group_id">
								{{commonService.domDirectionLTR ? data.group_name_en : data.group_name_ar}}
							</ion-select-option>
						</ion-select>
					</ion-item>
				</ion-list>
			</div>
		</div>

		<div class="sort-by-mobile">
			<div class="dropdown-icon">
				<img src="assets/icon/sort-mobile-icon.svg" alt="Jazeera">
			</div>
			<div class="filter-icon">
				<ion-icon src="assets/icon/relevancy_down_arrow.svg" alt="Jazeera"></ion-icon>
			</div>
			<div class="dropdown-container">
				<div class="sort-by-mobile-header">
					{{"sortBy" | translate}} <dd></dd>
				</div>
				<ion-list>
					<ion-item class="sort-by-item" lines="none">
						<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
							*ngIf="selectedSortBy" [(ngModel)]="selectedSortBy" class="pointer-none"
							[interfaceOptions]="{ 'header': ('sortBy' | translate)}"
							(ngModelChange)="selectSorting($event)">
							<ion-select-option value="A-Z">{{'A-Z' |
								translate}}</ion-select-option>
							<ion-select-option value="Z-A">{{'Z-A' |
								translate}}</ion-select-option>
							<ion-select-option value="New_To_Old">{{'New To Old' |
								translate}}</ion-select-option>
							<ion-select-option value="Old_To_New">{{'Old To New' |
								translate}}</ion-select-option>
						</ion-select>

						<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
							*ngIf="!selectedSortBy" value="1" class="pointer-none">
							<ion-select-option value="1">0 selected</ion-select-option>
						</ion-select>
					</ion-item>
				</ion-list>
			</div>
		</div>



	</div>


</ion-header>
<app-loader *ngIf="isLoading"></app-loader>
<ion-content id="mainHomePagescrollId" class="categoryContent" mode="md">
	<ion-refresher slot="fixed" refreshingSpinner="circular" (ionRefresh)="handleRefresh($event)">
		<ion-refresher-content></ion-refresher-content>
	</ion-refresher>
	<div class="height-100 page-scroll">
		<app-categories [categoryData]="categoryData" (loadMoreData)="loadMoreData($event)"
			[enableNext]="enableNext"></app-categories>
	</div>
</ion-content>