import { Injectable } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { GetPlatformService } from './get-platform.service';
import { Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    public toast;
    public toasterCtrl: any;
    
    msgText: any;
    msgType: any;
    isVisible: boolean = false;
    modalPopupAlert: boolean = false;
    isCartPage: boolean = false;
    currentLang;

    constructor(public toastController: ToastController,
        public getPlatformService: GetPlatformService,
        public modalCtrl: ModalController,
        public router:Router,
        public translate: TranslateService) { 
            this.currentLang = this.translate.currentLang;
        }

    async displayToast(message: string, position?: any, color?, longDuration?: any) {
        this.modalPopupAlert = false
        this.isCartPage = false;
        let isModalOpen;
        this.modalCtrl.getTop().then((data) => {
            isModalOpen = data;
            if (isModalOpen) {
                this.modalPopupAlert = true;
            }
        });
        if(this.router.url === '/'+this.currentLang+"/cart" || this.router.url === '/'+this.currentLang+"/checkout-step-1" || this.router.url === '/'+this.currentLang+"/checkout-step-2" || this.router.url === '/'+this.currentLang+"/checkout-step-review"){
            this.isCartPage = true;
        }

        this.msgText = message;
        this.msgType = color;
        this.isVisible = true;
        let timerout;
        if (longDuration == true) timerout = 5000; else timerout = 8000
        setTimeout(() => {
            this.isVisible = false;
            this.modalPopupAlert = false;
            this.isCartPage = false;
        }, timerout)

    }
}
