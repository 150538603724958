import { DOCUMENT, isPlatformServer, Location } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Http, HttpDownloadFileResult } from '@capacitor-community/http';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { isSupported } from 'firebase/analytics';
import { Subscription } from 'rxjs';
import { RFQCartComponent } from 'src/app/components/rfq-cart/rfq-cart.component';
import { RFQConfirmationComponent } from 'src/app/components/rfq-confirmation/rfq-confirmation.component';
import { CustomFirebaseAnalyticsProvider } from 'src/app/services/CustomFirebaseAnalyticsProvider';
import { URLS } from '../../_config/api.config';
import { AddReviewComponent } from '../../components/add-review/add-review.component';
import { DesignAndColorComponent } from '../../components/design-and-color/design-and-color.component';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';
import { WishlistService } from '../../services/wishlist.service';
import { NotifyMeComponent } from './../../components/notify-me/notify-me.component';
import { CartService } from './../../services/cart.service';
import { GetPlatformService } from './../../services/get-platform.service';
import { LoginPage } from './../login/login.page';
import { ProductReviewPage } from './../product-review/product-review.page';

@Component({
	selector: 'app-product-detail',
	templateUrl: './product-detail.page.html',
	styleUrls: ['./product-detail.page.scss'],
})
export class ProductDetailPage implements OnInit, AfterViewInit {

	private subscription: Subscription = new Subscription();
	// @ViewChild("slides", { static: false }) slider: IonSlides;
	@ViewChild('scrollable', { read: ElementRef }) scrollable: ElementRef;
	@ViewChild('slides', { static: false }) prodSlider!: ElementRef;

	public zoomButtonClick: boolean = false;
	public slideOpts = {
		initialSlide: 0,
		speed: 200,
		slidesPerView: 1,
		spaceBetween: 10,
		touchRatio: 1,
		resistance: true,
	};
	public sliderPager: boolean = false;
	public isChecked: any = 0;
	public productValue = 1;
	public noImageData: boolean = false;
	public attributePopUp = {
		cssClass: 'attribute-popover'
	}
	activeSection = 'section1';
	public iontabs: any = 0;
	public defaultSelectedImage: any = 0;
	public defaultSelectedSize: any = 0;
	public product_images: any = [];
	public product_name: any;
	public supplier_name: any;
	public showToolTip: boolean = false;
	public product_price: any;
	public final_price: any;
	public price_per_piece: any;
	public is_discount_applied: any;
	public discount_type: any;
	public discount_value: any;
	public is_sold_out: any;
	public productNotExist: boolean;
	public product_unit: any;
	public reviews: any = [];
	public isLoggedIn: boolean = false;
	public isWishlistDataLoading: boolean = false;
	public reviewsArray: any = [];
	public reviewsDetails: any;
	public loadMore: boolean = false;
	public average_rating: any = 0;
	public total_ratings: any;
	public sizeOptions: any = [];
	public isLoading = false;
	public product_description: any;
	public brand_name: any;
	public is_tax_free_product: any;
	public product_id: any;
	public fav_product: boolean = false;
	public imagesArray = [];
	public productImagesArray = [];
	public configData = [];
	public wishListData: any = [];
	public wishListProductData: any = [];
	public documentData: any = [];
	public isDocument = true;
	public step: any;
	public configDataOptions: any;
	public selectedConfigData = 0;
	public selectedConfigValue: any;
	public selectedId: any;
	public subProduct: any;
	public selected_subproduct: any;
	public selectedConfigurationId: any = [];
	public productSpecification: any = [];
	public showSpecification = false;
	public isSampleAvailable: boolean = false;
	public sampleUnitAvailable: any;
	public product_name_ar: any;
	public brand_name_ar: any;
	public supplier_name_ar: any;
	public product_unit_ar: any;
	public product_description_ar: any;

	public productDesignImages = [];
	public productDetails = [];


	public productGridData = [];

	public recommendedSliderData = [];

	public reviewCount = []
	public category_name_ar: any;
	public category_name_en: any;
	public prodSlideEl: any;

	reviewparams = {
		page: 1,
		size: 10,
	}

	recommendParams = {
		page: 1,
		size: 100,
	}

	subClassId: any;
	mainProductData: any;
	pre_selected_subproduct_id: any;
	availableQuantErr = false;
	minQuantErr = false;
	btnDisabled: boolean;
	constructor(
		@Inject(DOCUMENT) private document: Document,
		@Inject(PLATFORM_ID) private platformId: Object,
		public getPlatformService: GetPlatformService,
		private modalService: ModalService,
		public commonService: CommonService,
		private dataService: ApiService,
		private toastService: ToastService,
		private route: ActivatedRoute,
		public platform: Platform,
		public cartService: CartService,
		public wishlistService: WishlistService,
		private navCtrl: NavController,
		public router: Router,
		private location: Location,
		private translate: TranslateService,
		public customFirebaseAnalytics: CustomFirebaseAnalyticsProvider,
		private metaService: Meta,
		private setTitleService: Title,
		private renderer: Renderer2
	) {

		setTimeout(() => {
			const element = this.document.querySelector('#scrollId');
			if (element) {
				element.scrollIntoView();
			}
		}, 600);

		this.commonService.eventObservable.subscribe((data) => {
			if (data.event == "cartDeleteEvent") {
				this.availableQuantErr = false;
				this.minQuantErr = false;
				this.requiredMethodCall();
			}
		});

	}

	ionViewDidEnter() {
		setTimeout(() => {
			// if (this.slider) {
			// 	this.slider.getSwiper().then((swiper) => {
			// 		this.renderer.listen(swiper.el, 'mousedown', (event) => {
			// 			event.preventDefault();
			// 		});
			// 	});
			// }
		}, 1500);
	}

	ngOnInit() {
		this.route.params.subscribe(params => {
			if (!this.getPlatformService.isDesktop) {
				this.sliderPager = true;
			} else {
				this.sliderPager = false;
			}
			this.product_id = this.route.snapshot.paramMap.get('id');
			// console.log(this.product_id, "<=== Id on detail screen" );

			let stateData = this.router.getCurrentNavigation().extras.state;
			if (stateData && stateData.sub_product_id) {
				this.pre_selected_subproduct_id = stateData.sub_product_id;
			}


			this.loadAllData();

			const token = this.commonService.localStorageGet('accessToken');
			if (token) {
				this.isLoggedIn = true;
			} else {
				this.isLoggedIn = false;
			}
		});

	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.prodSlideEl = this.prodSlider.nativeElement;
			Object.assign(this.prodSlideEl, this.slideOpts);
			this.prodSlideEl.initialize();
		}, 400);
	}

	async segmentChanged(event?, index?) {
		await this.prodSlideEl.swiper.slideTo(this.iontabs);
	}


	async slideChanged() {
		this.iontabs = await this.prodSlideEl.swiper.activeIndex;
		this.zoomButtonClick = false
		const slider = await this.prodSlideEl.swiper.getSwiper;
		const zoom = slider.zoom;
		zoom.out();
	}

	async openReviewMobileModal() {
		let options: any = {
			componentProps: { mobileView: true, product_id: this.product_id },
			id: "addressModal"
		};

		options['component'] = ProductReviewPage;
		this.modalService.openModal(options)

	}


	addReviewModal() {
		let options: any = {
			id: "addReviewModal",
			componentProps: { product_id: this.product_id },
		};
		options['component'] = AddReviewComponent;
		let modal: any = this.modalService.openModal(options);
		modal.then((data) => {
			if (data && data.data == true) {
				this.reviews = [];
				this.getProductReviews();
			}
		})

	}

	openDesignColorModal(dataValues, id, selectedValue, selectedAttribute, selectedAttributeArabic) {
		let options: any = {
			id: "designAndColor",
			componentProps: {
				configData: dataValues,
				selectedValue: selectedValue,
				config_id: id,
				selected_attribute: this.commonService.domDirectionLTR ? selectedAttribute : selectedAttributeArabic
			}
		};
		options['component'] = DesignAndColorComponent;
		this.modalService.openModal(options)
	}

	incrementQuantity() {
		this.minQuantErr = false;
		let available_quantity: number = this.selected_subproduct.available_quantity - this.selected_subproduct.cart_quantity;
		if (this.productValue < available_quantity) {
			this.productValue++;
			this.availableQuantErr = false;
		}

	}

	decrementQuantity() {
		this.availableQuantErr = false;
		if (this.productValue > this.selected_subproduct.min_quantity) {
			this.productValue--;
			this.minQuantErr = false;
		}
	}


	quantityInput(event) {

		this.availableQuantErr = false;
		this.minQuantErr = false;
		let value = event.target.value;
		this.productValue = +event.target.value;
		if (value === 'e' || value === '+' || value === '-') {
			this.productValue = this.selected_subproduct.min_quantity;
		}
		if (this.productValue == 0 || !this.productValue || this.productValue == null) {
			this.productValue = this.selected_subproduct.min_quantity;
		}

	}

	qtySet() {
		if (this.productValue < this.selected_subproduct.min_quantity) {
			this.productValue = this.selected_subproduct.min_quantity;
		}
	}
	onScroll(event) {
		if (event.detail.deltaY < 0) {
			this.activeSection = 'section1';
		}
	}

	scrollToReview(data) {

		switch (data) {

			case 'review':
				this.step = 'review';
				this.activeSection = 'section2';
				let toReview = this.document.getElementById('section2');
				toReview.scrollIntoView({ behavior: 'smooth' });
				break;

			case 'overview':
				this.step = 'overview';
				this.activeSection = 'section1';
				let toOverview = this.document.getElementById('section1');
				toOverview.scrollIntoView({ behavior: 'smooth' });
				break;

			case 'relatedProducts':
				this.step = "relatedProducts";
				this.activeSection = "section3";
				let toRelatedProducts = this.document.getElementById('section3')
				toRelatedProducts.scrollIntoView({ behavior: 'smooth' });
				break;

			case 'documents':
				this.step = 'documents';
				this.activeSection = 'section4';
				let toDocuments = this.document.getElementById('section4')
				toDocuments.scrollIntoView({ behavior: 'smooth' });
				break;

			case 'specification':
				this.step = 'specification';
				this.activeSection = 'section5';
				let toSpecification = this.document.getElementById('section5')
				toSpecification.scrollIntoView({ behavior: 'smooth' });
				break;

		}
	}

	designChanged(event) {
		this.isChecked = +event.detail.value;
	}

	addToWishlist(isProductAdded?) {
		if (!this.isWishlistDataLoading) {
			if (!isProductAdded) {
				const params = {
					"product_id": +this.product_id,
					"sub_product_id": +this.selected_subproduct.product_id, // sub product add in to wishlist
				}
				this.isWishlistDataLoading = true;
				this.subscription.add(
					this.dataService.post(URLS.addWishList, params).subscribe(data => {
						if (data && data['code'] == 200) {
							isSupported().then(() => {
								this.customFirebaseAnalytics.trackEvent('Add_to_wishlist', params.sub_product_id);  //Google Analytics Log In Event
							})
							this.mainProductData['is_wishlist_product'] = true;
							this.mainProductData['wishlist_id'] = data['data']['wishlist_id'];
							this.isWishlistDataLoading = false;
						} else {
							// this.selected_subproduct['is_wishlist_product'] = false;
							this.mainProductData['is_wishlist_product'] = false;
							this.isWishlistDataLoading = false;
							this.toastService.displayToast(data['message'], null, 'danger');
						}
					}, error => {
						// this.selected_subproduct['is_wishlist_product'] = false;
						this.mainProductData['is_wishlist_product'] = false;
						this.isWishlistDataLoading = false;
						this.toastService.displayToast(error['message'], null, 'danger');
					})
				)
			} else {
				if (+this.mainProductData.wishlist_id) {
					this.isWishlistDataLoading = true;
					const params = {
						"product_id": +this.product_id,
						// "product_id": +this.selected_subproduct.product_id, // sub product remove from wishlist
						"wishlist_id": +this.mainProductData.wishlist_id,
					}
					this.subscription.add(
						this.dataService.post(URLS.removeWishList, params).subscribe(data => {
							if (data && data['code'] == 200) {
								this.mainProductData['is_wishlist_product'] = false;
								this.isWishlistDataLoading = false;
							} else {
								this.mainProductData['is_wishlist_product'] = false;
								this.isWishlistDataLoading = false;
								this.toastService.displayToast(data['message'], null, 'danger');
							}
						}, error => {
							this.mainProductData['is_wishlist_product'] = false;
							this.isWishlistDataLoading = false;
							this.toastService.displayToast(error['message'], null, 'danger');
						})
					)
				}
			}
		}

	}

	async zoom() {
		this.zoomButtonClick = !this.zoomButtonClick
		const slider = await this.prodSlideEl.swiper;
		const zoom = slider.zoom;
		if (this.zoomButtonClick) {
			zoom.in();
		} else {
			zoom.out();
		}

	}


	loadFlags() {
		setTimeout(() => {
			let designImages = [
				{
					image: "assets/icon/design_image1.svg",
					price: "+SR0"
				},
				{
					image: "assets/icon/design_image2.svg",
					price: "+SR0"
				},
				{
					image: "assets/icon/design_image3.svg",
					price: "+SR0"
				},
				{
					image: "assets/icon/design_image4.svg",
					price: "+SR0"
				},
				{
					image: "assets/icon/design_image5.svg",
					price: "+SR0"
				},
				{
					image: "assets/icon/design_image6.svg",
					price: "+SR0"
				},
				{
					image: "assets/icon/design_image7.svg",
					price: "+SR0"
				}
			]
			if (!isPlatformServer(this.platformId)) {
				let radios = this.document.getElementsByClassName('alert-radio-label');
				for (let index = 0; index < radios.length; index++) {
					let element = radios[index];
					element.innerHTML = element.innerHTML.concat('<img alt="Jazeera" class="country-image" src="' + designImages[index].image + '" />');
				}
			}
		}, 100);

	}
	getProductDetails() {
		if (+this.product_id) {
			let params = {
				product_id: +this.product_id ? +this.product_id : 0
			}
			return new Promise((resolve, reject) => {
				this.subscription.add(
					this.dataService.get(URLS.getProductDetail, params).subscribe(data => {
						if (data['code'] == 200) {
							let category_id = data['data']['category_id'] ? +data['data']['category_id'] : null
							this.commonService.localStorageSet("category_id", category_id)
							this.category_name_ar = data['data']['category_name_ar'];
							this.category_name_en = data['data']['category_name_en'];
							if (data['data']['related_products'] && data['data']['related_products'].length > 0) {
								let relatedProductData: any = data['data']['related_products'];
								relatedProductData.forEach((element, index) => {
									let obj = {
										product_description: element.product_name_en ? element.product_name_en : "",
										product_description_ar: element.product_name_ar ? element.product_name_ar : "",
										product_name: element.product_name_en ? element.product_name_en : "",
										product_name_ar: element.product_name_ar ? element.product_name_ar : "",
										brand_name: element.brand_name_en ? element.brand_name_en : "",
										brand_name_ar: element.brand_name_ar ? element.brand_name_ar : "",
										product_currency: "SR",
										product_price: element.price_per_unit ? element.price_per_unit : "",
										price_per_unit: element.price_per_unit ? element.price_per_unit : "",
										final_price: element.final_price ? element.final_price : "",
										price_per_piece: element.price_per_piece ? element.price_per_piece : "",
										product_unit: element.unit_name_en,
										product_unit_arb: element.unit_name_ar,
										product_id: element.product_id ? element.product_id : "Metal Ceiling CT1782",
										wishlist_id: element.wishlist_id ? element.wishlist_id : null,
										sold_quantity: element.sold_quantity ? element.sold_quantity : null,
										is_discount_applied: element.is_discount_applied ? element.is_discount_applied : null,
										is_tax_free_product: element.is_tax_free_product ? element.is_tax_free_product : null,
										available_quantity: element.available_quantity ? element.available_quantity : null,
										// is_sold_out: element.is_sold_out ? element.is_sold_out : null,
										is_wishlist_product: element.is_wishlist_product ? element.is_wishlist_product : null,
										product_sku: element.product_sku ? element.product_sku : null,
										total_quantity: element.total_quantity ? element.total_quantity : null,
										discount_type: element.discount_type ? element.discount_type : null,
										discount_value: element.discount_value ? element.discount_value : null,
										category_name_en: element.category_name_en ? element.category_name_en : "",
										category_name_ar: element.category_name_ar ? element.category_name_ar : "",
										category_id: element.category_id ? element.category_id : null,
										product_container_img: "assets/icon/paint-container.svg"
									}
									if (this.getPlatformService.isDesktop) {
										obj['product_img'] = element.product_images_web ? element.product_images_web : "assets/images/no-img1.png";
									} else {
										obj['product_img'] = element.product_images_mobile ? element.product_images_mobile : "assets/images/no-img1.png";
									}

									this.recommendedSliderData.push(obj);
								});
							}
							this.getCommonRelatedProducts();
							if (Object.keys(data['data']).length === 0) {
								this.toastService.displayToast("No data found", null, 'danger');
								this.location.back();
							}
							this.mainProductData = data['data'];
							this.imagesArray = data['data']['product_images'];
							this.product_name = data['data']['product_name_en'];
							let tagKeyword = '';
							let tags = data['data']['product_tags'] ? data['data']['product_tags'] : [];
							if (tags.length > 0) {
								tagKeyword = tags.join(", ");
								this.metaService.updateTag(
									{ name: 'keywords', content: tagKeyword },
								);
							}
							if (data['data']['product_name_en']) {
								this.metaService.updateTag({
									property: "og:title",
									content: "Jazeera Paints | " + data['data']['product_name_en'],
								});
								let title = 'Jazeera Paints | ' + data['data']['product_name_en'];
								this.setTitleService.setTitle(title);
							}
							if (data['data']['product_desc_en']) {
								this.metaService.updateTag({
									name: "description",
									content: "Jazeera Paints | " + data['data']['product_desc_en'],
								});
								this.metaService.updateTag({
									property: "og:description",
									content: "Jazeera Paints | " + data['data']['product_desc_en'],
								});
							}

							isSupported().then(() => {

								this.customFirebaseAnalytics.trackEvent('Product_Visited',
									JSON.stringify({
										Name: this.product_name,
										Brand: data['data']['brand_name_en']
									}))//Google Analytics Log In Event
							})
							this.product_name_ar = data['data']['product_name_ar'];
							this.brand_name = data['data']['brand_name_en'];
							this.brand_name_ar = data['data']['brand_name_ar'];
							this.is_tax_free_product = data['data']['is_tax_free_product'] ? data['data']['is_tax_free_product'] : null;
							this.supplier_name = data['data']['supplier_name_en'];
							this.supplier_name_ar = data['data']['supplier_name_ar']
							this.total_ratings = data['data']['total_reviews'];
							this.documentData = data['data']['documents'];
							this.product_price = data['data']['price_per_unit'];
							this.final_price = data['data']['final_price'];
							this.price_per_piece = data['data']['price_per_piece'];
							this.product_unit = data['data']['unit_name_en'];
							this.product_unit_ar = data['data']['unit_name_ar'];
							this.product_description = data['data']['product_desc_en'];
							this.product_description_ar = data['data']['product_desc_ar'];
							this.productSpecification = data['data']['common_configurations'];
							this.subClassId = data['data']['sub_class_id'];
							this.isSampleAvailable = data['data']['is_sample_unit_available'];
							this.sampleUnitAvailable = data['data']['max_sample_unit_provided'];

							if (this.documentData && this.documentData.length) {
								let obj = this.documentData.find(element => element.documents && element.documents.length)
								if (obj) {
									this.isDocument = false;
								} else {
									this.isDocument = true;
								}
							}
							this.showSpecification = false;
							if (this.productSpecification && this.productSpecification.length) {
								this.productSpecification.forEach(element => {
									if (!this.showSpecification) {
										if (element.configuration_value) {
											this.showSpecification = true;
										}
									}
								});
							}

							// code required
							// if (this.imagesArray && this.imagesArray.length) {
							//     this.imagesArray.forEach(element => {
							//         if (this.getPlatformService.isDesktop && element['image_type'] == "web") {
							//             this.productImagesArray.push(element);
							//         }
							//         if (!this.getPlatformService.isDesktop && element['image_type'] == "mobile") {
							//             this.productImagesArray.push(element);
							//         }
							//     });
							// }
							let main_config: any = [];
							let value_array: any = [];

							if (data['data']['sub_products'] && data['data']['sub_products'].length) {
								data['data']['sub_products'].forEach(element => {
									if (element.product_configurations && element.product_configurations.length) {
										element.product_configurations.forEach(element2 => {
											value_array.push(JSON.parse(JSON.stringify(element2)));
										});
									}
								});
							}

							let unique: any = [...new Map(value_array.map(item => [item['configuration_value_id'], item])).values()];

							unique.forEach(element => {
								var isPresent = main_config.some(function (el) { return el.configuration_id === element.configuration_id });
								if (!isPresent) {

									const lookup = unique.reduce((a, e) => {
										a[e.configuration_id] = ++a[e.configuration_id] || 0;
										return a;
									}, {});
									main_config.push({
										configuration_id: element.configuration_id,
										configuration_name_ar: element.configuration_name_ar,
										configuration_name_en: element.configuration_name_en,
										value_array: this.getConfigurationValue(unique, element.configuration_id),
										selected_value: this.getConfigurationValue(unique, element.configuration_id)[0].configuration_value_en,
										selected_value_ar: this.getConfigurationValue(unique, element.configuration_id)[0].configuration_value_ar,
										selected_id: this.getConfigurationValue(unique, element.configuration_id)[0].configuration_value_id,
										attributeValue: "0",
									});
								}
							});

							this.configData = main_config;
							this.subProduct = [...data['data']['sub_products']];

							let preselceted_config: any;
							if (this.subProduct && this.subProduct.length && this.pre_selected_subproduct_id) {
								this.subProduct.forEach(element => {
									if (element.product_id == this.pre_selected_subproduct_id) {
										preselceted_config = element.product_configurations;
									}
								});
							}


							if (this.pre_selected_subproduct_id) {
								if (preselceted_config && preselceted_config.length) {
									preselceted_config.forEach(preselect => {
										main_config.forEach((data) => {
											if (data.configuration_id == preselect.configuration_id) {
												data.value_array.forEach((element, index) => {
													if (element.configuration_value_id == preselect.configuration_value_id) {
														element.select = true;
														data.selected_id = element.configuration_value_id;
														data.selected_value = element.configuration_value_en;
														data.selected_value_ar = element.configuration_value_ar;
														data.attributeValue = index;
													} else {
														element.select = false;
													}
												});
											}

										});
									})
								}

								this.configData = [...main_config];
								this.selectedConfigurationId = [];
								this.productImagesArray = [];
								this.configData.forEach(element => {
									element.value_array.forEach(valueElement => {
										if (valueElement.select === true) {
											this.selectedConfigurationId.push(valueElement.configuration_value_id)
										}
									});
								});
								this.subProductSelection();
							}
							else {
								if (this.getPlatformService.isDesktop) {
									main_config.forEach((configElement) => {
										this.selectedConfigurationId.push(configElement.value_array[0].configuration_value_id)
										configElement.value_array.forEach((valueElement, index) => {
											if (index === 0) {
												valueElement['select'] = true;
											} else {
												valueElement['select'] = false;
											}
										});
									});

								} else {
									main_config.forEach((configElement) => {
										this.selectedConfigurationId.push(configElement.selected_id);
									});
								}

								this.subProductSelection();
							}
							resolve(data);
						} else {
							if (data.code == 500) {
								this.location.back()
							}
							this.toastService.displayToast(data['message'], null, 'danger');
							reject();
						}
					}, error => {

						if (error.code == 500) {
							this.location.back()
						}

						this.toastService.displayToast(error['message'], null, 'danger');
						reject();
					})
				)
			});
		}
	}

	getCommonRelatedProducts() {
		if (+this.product_id) {
			let params = {
				product_id: +this.product_id,
				page: this.recommendParams.page,
				size: this.recommendParams.size,
			}
			return new Promise((resolve, reject) => {
				this.subscription.add(
					this.dataService.get(URLS.relatedProductList, params).subscribe(data => {
						if (data['code'] == 200) {
							let relatedProductData: any = data['data']['result'];
							relatedProductData.forEach((element, index) => {
								let obj = {
									product_description: element.product_name_en ? element.product_name_en : "",
									product_description_ar: element.product_name_ar ? element.product_name_ar : "",
									product_name: element.product_name_en ? element.product_name_en : "",
									product_name_ar: element.product_name_ar ? element.product_name_ar : "",
									brand_name: element.brand_name_en ? element.brand_name_en : "",
									brand_name_ar: element.brand_name_ar ? element.brand_name_ar : "",
									product_currency: "SR",
									product_price: element.price_per_unit ? element.price_per_unit : "",
									price_per_unit: element.price_per_unit ? element.price_per_unit : "",
									final_price: element.final_price ? element.final_price : "",
									product_unit: element.unit_name_en,
									product_unit_arb: element.unit_name_ar,
									product_id: element.product_id ? element.product_id : "Metal Ceiling CT1782",
									wishlist_id: element.wishlist_id ? element.wishlist_id : null,
									sold_quantity: element.sold_quantity ? element.sold_quantity : null,
									is_discount_applied: element.is_discount_applied ? element.is_discount_applied : null,
									is_tax_free_product: element.is_tax_free_product ? element.is_tax_free_product : null,
									available_quantity: element.available_quantity ? element.available_quantity : null,
									// is_sold_out: element.is_sold_out ? element.is_sold_out : null,
									is_wishlist_product: element.is_wishlist_product ? element.is_wishlist_product : null,
									product_sku: element.product_sku ? element.product_sku : null,
									total_quantity: element.total_quantity ? element.total_quantity : null,
									discount_type: element.discount_type ? element.discount_type : null,
									discount_value: element.discount_value ? element.discount_value : null,
									category_name_en: element.category_name_en ? element.category_name_en : "",
									category_name_ar: element.category_name_ar ? element.category_name_ar : "",
									category_id: element.category_id ? element.category_id : null,
									product_container_img: "assets/icon/paint-container.svg"
								}
								if (this.getPlatformService.isDesktop) {
									obj['product_img'] = element.web_image_url ? element.web_image_url : "assets/images/no-img1.png";
								} else {
									obj['product_img'] = element.mobile_image_url ? element.mobile_image_url : "assets/images/no-img1.png";
								}
								this.recommendedSliderData.push(obj);
							});
							this.recommendedSliderData = this.getUniqueRelated(this.recommendedSliderData);
							resolve(data)
						} else {
							reject();
						}
					}, error => {
						reject();
					})
				)
			})
		}

	}

	getUniqueRelated(data) {
		const uniqueData = new Map();
		for (const item of data) {
			uniqueData.set(item.product_id, item);
		}
		return Array.from(uniqueData.values());
	}


	async designChecked(data, array, index) {
		this.zoomButtonClick = false
		const slider = await this.prodSlideEl.swiper.getSwiper;
		const zoom = slider.zoom;
		zoom.out();

		array.forEach((element) => {
			if (element.configuration_value_id == data.configuration_value_id) {
				element.select = true;
			} else {
				element.select = false;
			}
		});

		this.selectedConfigurationId = [];
		this.productImagesArray = [];
		this.configData.forEach(element => {
			element.value_array.forEach(valueElement => {
				if (valueElement.select === true) {
					this.selectedConfigurationId.push(valueElement.configuration_value_id)
				}
			});
		});

		this.subProductSelection();

	}

	subProductSelection() {
		this.productNotExist = false;
		let selectedSubProduct = this.subProduct.find((el, index) => {
			let mainIdConfig;
			let subConfigExist
			let idExist;
			this.selectedConfigurationId.forEach(element3 => {
				if (el.product_configurations && el.product_configurations.length) {
					idExist = this.configExitst(el.product_configurations, element3);
				}
				else {
					idExist = null;
				}
				if (idExist == false || !idExist) {
					mainIdConfig = false;
				}
			});

			if (mainIdConfig != false) {
				subConfigExist = true;
			}
			if (subConfigExist == true) {
				return el;
			}
		});

		let configExist: any = null;
		if (!selectedSubProduct || !selectedSubProduct.product_configurations) {
			configExist = false;
		}

		if (selectedSubProduct) {
			this.selected_subproduct = selectedSubProduct;
			this.productValue = this.selected_subproduct.min_quantity;
			this.imagesArray = this.selected_subproduct['product_images'];
			this.productImagesArray = [];
			if (this.imagesArray && this.imagesArray.length) {
				this.imagesArray.forEach(element => {
					if (this.getPlatformService.isDesktop && element['image_type'] == "web") {
						if (element.is_cover_images) {
							this.productImagesArray.unshift(element)
						} else {
							this.productImagesArray.push(element);
						}
					}
					if (!this.getPlatformService.isDesktop && element['image_type'] == "mobile") {
						if (element.is_cover_images) {
							this.productImagesArray.unshift(element)
						} else {
							this.productImagesArray.push(element);
						}
					}
				});
			}
			else if (this.mainProductData['product_images'] && this.mainProductData['product_images'].length) {
				this.mainProductData['product_images'].forEach(element => {
					if (this.getPlatformService.isDesktop && element['image_type'] == "web") {
						if (element.is_cover_images) {
							this.productImagesArray.unshift(element)
						} else {
							this.productImagesArray.push(element);
						}
					}
					if (!this.getPlatformService.isDesktop && element['image_type'] == "mobile") {
						if (element.is_cover_images) {
							this.productImagesArray.unshift(element)
						} else {
							this.productImagesArray.push(element);
						}
					}
				});
			}

			this.productImagesArray = [...this.productImagesArray];

			this.product_name = this.selected_subproduct.product_name_en;
			this.product_name_ar = this.selected_subproduct.product_name_ar;
			this.product_price = this.selected_subproduct.price_per_unit;
			this.final_price = this.selected_subproduct.final_price;
			this.price_per_piece = this.selected_subproduct.price_per_piece;
			this.is_discount_applied = this.selected_subproduct.is_discount_applied;
			this.discount_type = this.selected_subproduct.discount_type;
			this.discount_value = this.selected_subproduct.discount_value;
			this.is_discount_applied = this.selected_subproduct.is_discount_applied;

			if (configExist == false) {
				this.selected_subproduct.product_name = this.mainProductData.product_name_en;
				this.selected_subproduct.product_name_ar = this.mainProductData.product_name_ar;
			}
			else {
				this.selected_subproduct.product_name = this.product_name;
				this.selected_subproduct.product_name_ar = this.product_name_ar;
			}

		}
		else {
			this.selected_subproduct = {
				min_quantity: 1,
				available_quantity: 1,
				cart_quantity: 0
			};
			if (this.mainProductData['product_images'] && this.mainProductData['product_images'].length) {
				this.mainProductData['product_images'].forEach(element => {
					if (this.getPlatformService.isDesktop && element['image_type'] == "web") {
						if (element.is_cover_images) {
							this.productImagesArray.unshift(element)
						} else {
							this.productImagesArray.push(element);
						}
					}
					if (!this.getPlatformService.isDesktop && element['image_type'] == "mobile") {
						if (element.is_cover_images) {
							this.productImagesArray.unshift(element)
						} else {
							this.productImagesArray.push(element);
						}
					}
				});
			}
			this.selected_subproduct.product_name = this.mainProductData.product_name_en;
			this.selected_subproduct.product_name_ar = this.mainProductData.product_name_ar;
			this.productNotExist = true;

			if (configExist == false) {
				this.selected_subproduct.product_name = this.mainProductData.product_name_en;
				this.selected_subproduct.product_name_ar = this.mainProductData.product_name_ar;
			}
			else {
				this.selected_subproduct.product_name = this.mainProductData.product_name_en;
				this.selected_subproduct.product_name_ar = this.mainProductData.product_name_ar;
			}
		}
		this.productValue = this.selected_subproduct.min_quantity;

	}

	configExitst(arr, value) {
		return arr.some(function (el) {
			return el.configuration_value_id === value;
		});
	}

	getConfigurationValue(array, id) {
		let values = [];
		array.forEach(element => {
			if (element.configuration_id == id) {
				delete element['configuration_name_en'];
				delete element['configuration_name_ar'];
				values.push(element);
			}
		});
		return values;
	}

	attributeChange(data) {
		// code required
		// if (data.en.price_per_unit) {
		//     this.product_price = data.en.price_per_unit;
		// }
	}

	getProductReviews() {
		if (+this.product_id) {
			let params = {
				product_id: +this.product_id,
				page: this.reviewparams.page,
				size: this.reviewparams.size
			}
			return new Promise((resolve, reject) => {
				this.subscription.add(
					this.dataService.get(URLS.getReviews, params).subscribe(data => {
						if (data['code'] == 200) {
							let reviewData = data['data']['result'];
							this.reviewsArray = data['data'];
							this.reviewsDetails = data['data']['rating_details'];
							this.reviewCount = [];
							this.total_ratings = data['data']['total_count']
							if (this.reviewsDetails && 'five_star' in this.reviewsDetails) {
								let object = {
									name: "5 Star",
									name_ar: "نجو 5",
									count: this.reviewsDetails['five_star'],
									percentage: ((this.reviewsDetails['five_star'] * 100) / this.reviewsArray.total_count)
								}
								this.reviewCount.push(object);
							}
							if (this.reviewsDetails && 'four_star' in this.reviewsDetails) {
								let object = {
									name: "4 Star",
									name_ar: "4 نجو",
									count: this.reviewsDetails['four_star'],
									percentage: ((this.reviewsDetails['four_star'] * 100) / this.reviewsArray.total_count)
								}
								this.reviewCount.push(object);
							}
							if (this.reviewsDetails && 'three_star' in this.reviewsDetails) {
								let object = {
									name: "3 Star",
									name_ar: "3 نجو",
									count: this.reviewsDetails['three_star'],
									percentage: ((this.reviewsDetails['three_star'] * 100) / this.reviewsArray.total_count)
								}
								this.reviewCount.push(object);
							}
							if (this.reviewsDetails && 'two_star' in this.reviewsDetails) {
								let object = {
									name: "2 Star",
									name_ar: "2 نجو",
									count: this.reviewsDetails['two_star'],
									percentage: ((this.reviewsDetails['two_star'] * 100) / this.reviewsArray.total_count)
								}
								this.reviewCount.push(object);
							}
							if (this.reviewsDetails && 'one_star' in this.reviewsDetails) {
								let object = {
									name: "1 Star",
									name_ar: "1 نجو",
									count: this.reviewsDetails['one_star'],
									percentage: ((this.reviewsDetails['one_star'] * 100) / this.reviewsArray.total_count)
								}
								this.reviewCount.push(object);
							}

							if (this.reviewsDetails && this.reviewsDetails['average_rating']) {
								this.average_rating = this.reviewsDetails['average_rating'] ? this.reviewsDetails['average_rating'] : 0;

								reviewData.forEach(element => {
									let obj = {
										name: element.review_title ? element.review_title : "",
										verified: element.is_verified_customer ? element.is_verified_customer : false,
										date: element.created_at ? element.created_at : "7/7/2021",
										user_name: element.customer_name ? element.customer_name : "",
										rating: element.customer_rating ? element.customer_rating : 4,
										comment: element.customer_review ? element.customer_review : ""
									}
									this.reviews.push(obj);
								});
							}

							resolve(data);
						} else {
							reject();
						}
					}, error => {
						reject();
					})
				)
			})
		}
	}

	loadMoreReviews() {
		this.loadMore = this.reviewsArray.enable_next;
		if (this.reviewsArray.enable_next) {
			this.reviewparams.page = this.reviewparams.page + 1;
			this.reviewparams.size = 10;
			this.getProductReviews();
		}
	}

	handleRefresh(event) {
		setTimeout(() => {
			this.loadAllData();
		}, 1000);
	};

	loadAllData() {
		this.isLoading = true;
		Promise.all([
			this.subscription = this.commonService.callConfigData.subscribe((data) => {
				data.config_data.find(element => {
					if (element.checked) {
						this.configData.forEach(configElement => {
							if (configElement.configuration_id === data.config_data.config_id) {
								configElement.value_array.forEach(valueElement => {
									if (valueElement.configuration_value_id === element.value_id) {
										this.selectedConfigValue = element.value;
										this.selectedId = element.value_id;
										configElement.selected_value = element.value;
										configElement.selected_value_ar = element.value;
										configElement.selected_id = element.value_id;
									}
								});
							}
						});
					}
				})

				this.selectedConfigurationId = [];
				this.productImagesArray = [];
				this.selected_subproduct = null;
				this.configData.forEach((configElement) => {
					this.selectedConfigurationId.push(configElement.selected_id);
				});
				this.subProductSelection();

			}),
			this.selected_subproduct = null,
			this.getProductDetails(),
			this.getProductReviews()
		]).then(data => {
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
		})
	}

	goBack() {
		this.commonService.setEvent("emptySerach", null);
		this.location.back();
	}

	showDocumentData(data) {
		data.documentView = !data.documentView;
	}

	async addToCart() {
		if (this.productValue >= this.selected_subproduct.min_quantity) {
		}
		else {
			this.minQuantErr = true;
			return;
		}

		if (this.productValue > this.selected_subproduct.available_quantity - this.selected_subproduct.cart_quantity) {
			this.availableQuantErr = true;
			return;
		}

		const token = this.commonService.localStorageGet('accessToken');

		if (!token) {
			if (this.getPlatformService.isDesktop) {
				let options: any = {
					id: "loginModal",
					backdropDismiss: false,
					componentProps: {
						selected_Product: this.selected_subproduct,
						product_value: this.selected_subproduct.cart_quantity + this.productValue
					}
				};
				options['component'] = LoginPage;
				this.modalService.openModal(options);
			} else {
				const navigationExtras: NavigationExtras = {
					queryParams: {
						selected_Product: JSON.stringify(this.selected_subproduct),
						product_value: this.selected_subproduct.cart_quantity + this.productValue
					}
				}
				this.navCtrl.navigateForward('/' + this.translate.currentLang + '/login', navigationExtras);
			}


		}
		else {
			this.btnDisabled = true;
			let finalQty: any;
			if (this.productValue > this.selected_subproduct.available_quantity - this.selected_subproduct.cart_quantity) {
				return;
			}
			if (this.selected_subproduct.cart_quantity) {
				finalQty = this.selected_subproduct.cart_quantity + this.productValue;
			}
			else {
				finalQty = this.productValue
			}
			await this.cartService.addIntoCart(this.selected_subproduct, finalQty).then((data) => {
				this.commonService.setEvent("addToCart", null);
				this.toastService.displayToast(data['message'], null, 'success');
				setTimeout(() => {
					this.btnDisabled = false;
				}, 5000)
				this.imagesArray = [];
				this.productImagesArray = [];
				this.selectedConfigurationId = [];
				this.availableQuantErr = false;
				this.minQuantErr = false;
				this.getProductDetails();

			});
		}
	}

	notifyMeClick(product_id) {
		event.stopPropagation();
		event.preventDefault();

		const token = this.commonService.localStorageGet('accessToken');

		if (!token) {
			let options: any = {
				cssClass: "notify-me-modal",
				backdropDismiss: false,
				id: 'notifyModal'
			};
			options['component'] = NotifyMeComponent;
			options['componentProps'] = {
				productId: product_id
			};
			let modal: any = this.modalService.openModal(options);
			modal.then((data) => {
				if (data && data.data) {
					this.getProductDetails();
				}
			})

		}
		else {
			let userinfo = this.commonService.localStorageGet('userInfo');
			let userData;
			if (userinfo) {
				userData = JSON.parse(userinfo)
			}

			const params = {
				"product_id": product_id,
				"customer_email": userData.customer_email
			}
			this.subscription.add(
				this.dataService.post(URLS.notifyMe, params).subscribe(data => {
					if (data && data['code'] == 200) {
						this.toastService.displayToast(data['message'], null, 'success');
						this.getProductDetails();
					} else {
						this.toastService.displayToast(data['message'], null, 'danger');
					}
				}, error => {
					this.toastService.displayToast(error['message'], null, 'danger');
				})
			)
		}
	}

	subProductStock(product_id) {
		const params = {
			"product_id": product_id,
		}
		this.subscription.add(
			this.dataService.get(URLS.productAvailability, params).subscribe(data => {
				if (data && data['code'] == 200) {
					let result = data['data'];
					this.selected_subproduct.total_quantity = result.total_quantity;
					this.selected_subproduct.sold_quantity = result.sold_quantity;
					this.selected_subproduct.available_quantity = result.available_quantity;
					this.selected_subproduct.is_sold_out = result.is_sold_out;
					this.is_sold_out = this.selected_subproduct.is_sold_out;
				}
			}, error => {
			})
		)

	}

	async downloadDocument(docUrl) {
		// window.open(docUrl, '_self', 'download')

		if (this.getPlatformService.isDesktop && !isPlatformServer(this.platformId)) {
			window.location.href = docUrl;
		}
		else {
			let invoiceFileName = docUrl.substring(docUrl.lastIndexOf('/') + 1)

			const options = {
				url: docUrl,
				filePath: invoiceFileName,
				fileDirectory: this.platform.is("android") ? Directory.Documents : Directory.Data,
				progress: true,
				// Optional
				method: 'GET',
			};

			// Writes to local filesystem
			const response: HttpDownloadFileResult = await Http.downloadFile(options);
			// Then read the file
			if (response.path) {
				await Filesystem.readFile({
					path: invoiceFileName,
					directory: Directory.Documents,
				}).then(data => {
					const msg = this.commonService.domDirectionLTR ? 'File download successfully, Please check your directory.'
						: 'تم تنزيل الملف بنجاح ، يرجى التحقق من الدليل الخاص بك.'
					this.toastService.displayToast(msg, null, 'success')
				},
					(error) => {
						this.toastService.displayToast(error, null, 'error')
					});


			}
		}
	}

	numericOnly(event): boolean {
		this.availableQuantErr = false;
		this.minQuantErr = false;
		let pattern = /^([0-9])$/;
		let result = pattern.test(event.key);
		return result;
	}

	requiredMethodCall() {
		let params = {
			product_id: +this.product_id ? +this.product_id : 0
		}
		this.subscription.add(
			this.dataService.get(URLS.getProductDetail, params).subscribe(data => {
				if (data['code'] == 200) {
					if (Object.keys(data['data']).length === 0) {
						this.toastService.displayToast("No data found", null, 'danger');
						this.location.back();
					}
					this.mainProductData = data['data'];

					let main_config: any = [];
					let value_array: any = [];

					let category_id = data['data']['category_id'] ? +data['data']['category_id'] : null;
					this.commonService.localStorageSet("category_id", category_id)

					if (data['data']['sub_products'] && data['data']['sub_products'].length) {
						data['data']['sub_products'].forEach(element => {
							if (element.product_configurations && element.product_configurations.length) {
								element.product_configurations.forEach(element2 => {
									value_array.push(JSON.parse(JSON.stringify(element2)));
								});
							}
						});
					}

					let unique: any = [...new Map(value_array.map(item => [item['configuration_value_id'], item])).values()];

					unique.forEach(element => {
						var isPresent = main_config.some(function (el) { return el.configuration_id === element.configuration_id });
						if (!isPresent) {

							const lookup = unique.reduce((a, e) => {
								a[e.configuration_id] = ++a[e.configuration_id] || 0;
								return a;
							}, {});
							main_config.push({
								configuration_id: element.configuration_id,
								configuration_name_ar: element.configuration_name_ar,
								configuration_name_en: element.configuration_name_en,
								value_array: this.getConfigurationValue(unique, element.configuration_id),
								selected_value: this.getConfigurationValue(unique, element.configuration_id)[0].configuration_value_en,
								selected_value_ar: this.getConfigurationValue(unique, element.configuration_id)[0].configuration_value_ar,
								selected_id: this.getConfigurationValue(unique, element.configuration_id)[0].configuration_value_id,
								attributeValue: "0",
							});
						}
					});

					this.configData = main_config;
					this.subProduct = [...data['data']['sub_products']];

					let preselceted_config: any;
					if (this.subProduct && this.subProduct.length && this.pre_selected_subproduct_id) {
						this.subProduct.forEach(element => {
							if (element.product_id == this.pre_selected_subproduct_id) {
								preselceted_config = element.product_configurations;
							}
						});
					}


					if (this.pre_selected_subproduct_id) {
						if (preselceted_config && preselceted_config.length) {
							preselceted_config.forEach(preselect => {
								main_config.forEach((data) => {
									if (data.configuration_id == preselect.configuration_id) {
										data.value_array.forEach((element, index) => {
											if (element.configuration_value_id == preselect.configuration_value_id) {
												element.select = true;
												data.selected_id = element.configuration_value_id;
												data.selected_value = element.configuration_value_en;
												data.selected_value_ar = element.configuration_value_ar;
												data.attributeValue = index;
											} else {
												element.select = false;
											}
										});
									}

								});
							})
						}

						this.configData = [...main_config];
						this.selectedConfigurationId = [];
						this.productImagesArray = [];
						this.configData.forEach(element => {
							element.value_array.forEach(valueElement => {
								if (valueElement.select === true) {
									this.selectedConfigurationId.push(valueElement.configuration_value_id)
								}
							});
						});
						this.subProductSelection();
					}
					else {
						if (this.getPlatformService.isDesktop) {
							main_config.forEach((configElement) => {
								this.selectedConfigurationId.push(configElement.value_array[0].configuration_value_id)
								configElement.value_array.forEach((valueElement, index) => {
									if (index === 0) {
										valueElement['select'] = true;
									} else {
										valueElement['select'] = false;
									}
								});
							});

						} else {
							main_config.forEach((configElement) => {
								this.selectedConfigurationId.push(configElement.selected_id);
							});
						}

						this.subProductSelection();
					}
				} else {
					if (data.code == 500) {
						this.location.back()
					}
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {

				if (error.code == 500) {
					this.location.back()
				}

				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

	calculateSUOM(ratio, quantity) {
		if (ratio && quantity) {
			let ratiodata = ratio * (+quantity)
			return ratiodata.toFixed(2);
		} else {
			return ratio.toFixed(2);
		}
	}
	openRfqConfirmation() {
		const token = this.commonService.localStorageGet('accessToken');

		if (!token) {
			if (this.getPlatformService.isDesktop) {
				let options: any = {
					id: "loginModal",
					backdropDismiss: false,
					componentProps: {
						selected_Product: this.selected_subproduct,
						product_value: this.selected_subproduct.cart_quantity + this.productValue
					}
				};
				options['component'] = LoginPage;
				this.modalService.openModal(options);
			} else {
				const navigationExtras: NavigationExtras = {
					queryParams: {
						selected_Product: JSON.stringify(this.selected_subproduct),
						product_value: this.selected_subproduct.cart_quantity + this.productValue
					}
				}
				this.navCtrl.navigateForward('/' + this.translate.currentLang + '/login', navigationExtras);
			}


		}
		else {
			let finalQty: any;

			if (this.selected_subproduct.cart_quantity) {
				finalQty = this.selected_subproduct.cart_quantity + this.productValue;
			}
			else {
				finalQty = this.productValue
			}

			let options: any = {
				id: "addReviewModal",
				cssClass: 'rfq-popover',
				componentProps: {
					product_id: this.product_id,
					selected_product_id: this.selected_subproduct.product_id,
					quantity: finalQty
				},
			};
			options['component'] = RFQConfirmationComponent;
			let modal: any = this.modalService.openModal(options);
			modal.then((data) => {
				if (data && data.data) {
					// value can be mail or pdf
					if (data.data.value) {
						this.openRFQCartModal(data.data.value, data.data.quote_id);
					}
				}
			})
		}

		// this.openRFQCartModal('pdf', quote_id);
	}

	openRFQCartModal(value, quote_id) {
		if (!this.getPlatformService.isDesktop) {
			const navigationExtras: NavigationExtras = {
				queryParams: {
					value: value,
					quoteId: quote_id
				},
			};
			this.router.navigate(['/home/rfq-mobile'], navigationExtras)
		}
		else {
			let options: any = {
				id: "addReviewModal",
				cssClass: 'rfq-popover',
				componentProps: {
					value: value,
					quoteId: quote_id
				},
			};
			options['component'] = RFQCartComponent;
			let modal: any = this.modalService.openModal(options);
			modal.then((data) => {
				if (data && data.data) {
				}
			})
		}
	}

}